import ReconciliationCard from "../features/reconciliation/ReconciliationCard";

const ReconciliationPage = () => {
  return (
    <>
      <div className="row page-body">
        <div className="col-md-12">
          <ReconciliationCard />
        </div>
      </div>
    </>
  );
};

export default ReconciliationPage; 