import React from "react";
import ImportJournalEntries from "../features/bookingImport/importJournalEntries";

const BookingImportPage = () => {
  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <ImportJournalEntries/>
        </div>
      </div>
    </>
  );
};

export default BookingImportPage;
