interface FilterDiariesInterface {
  term: string;
  operator?: string;
  field?: string;
}
export interface DiariesQueryParamsInterface {
  filter: FilterDiariesInterface;
  sort: string;
  pageNumber: number;
  perPage: number;
}

export const initialFilter: DiariesQueryParamsInterface = {
  filter: {
    term: "",
  },
  sort: "?sort=id",
  pageNumber: 1,
  perPage: 10,
};
