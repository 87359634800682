export const formatNumber = (value: number | string): string => {
  const number = Number(value)
    .toFixed(2)
    .replace(/\.?0+$/, "");

  const formattedNumber = Number(number)?.toLocaleString("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return formattedNumber ?? 0;
};
export const getFieldClassName = (touched: boolean, error: boolean) => {
  if (touched && error) {
    return "is-invalid";
  }
  return touched ? "is-valid" : "";
};

export const moveCursorToEnd = (el: HTMLInputElement): void => {
  el.selectionStart = el.selectionEnd = el.value.length;
};
export const extractNumber = (number: number | string | undefined) => {
  try {
    if (!number) return 0;
    return parseFloat(number.toString().replace(",", ".")) || 0;
  } catch (_) {
    return 0;
  }
};
export const isValidNumber = (value: any) => {
  const num = parseFloat(value);
  return !isNaN(num) && isFinite(num);
};
export const parseNumber = (value: number | string | undefined): number => {
  if (!value) return 0;
  return parseFloat(value.toString());
};
export const getEarlyDay = (date: Date) => {
  const earlyDate = new Date(date);
  earlyDate.setDate(earlyDate.getDate() - 1);
  return earlyDate;
};
export const getNavigatorLanguage = (): string => navigator.language || "en-US";
function getLocaleSeparators(locale: string) {
  const testNumber = 123456.789;
  const localeFormattedNumber = Intl.NumberFormat(locale).format(testNumber);

  // Get the thousands separator of the locale
  const thousandsSeparator = localeFormattedNumber.split("123")[1][0];

  // Get the decimal separator of the locale
  const decimalSeparator = localeFormattedNumber.split("123")[1][4];
  return { thousandsSeparator, decimalSeparator };
}
export const getFormattedTimeUnit = (timeUnit: number): string =>
  timeUnit.toString().padStart(2, "0");
export function parseLocaleNumber(
  stringNumber: string,
  locale = getNavigatorLanguage()
): number {
  const { thousandsSeparator, decimalSeparator } = getLocaleSeparators(locale);
  const normalizedStringNumber = stringNumber.replace(/\u00A0/g, " "); // Replace non-breaking space with normal space
  const numberString = normalizedStringNumber
    .replace(new RegExp(`[${thousandsSeparator}\\s]`, "g"), "") // Replace thousands separator and white-space
    .replace(new RegExp(`\\${decimalSeparator}`, "g"), "."); // Replace decimal separator

  const trimmedNumberString = numberString.replace(/^(?!-)\D+|\D+$/g, ""); // Remove characters before first and after last number, but keep negative sign
  return Number(trimmedNumberString);
}
