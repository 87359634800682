import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDiariesUIContext } from "../DiariesUiContext";
import { DiariesInterface } from "../DiariesModel";
import XIcon from "../../../components/Icons/XIcon";
import CheckIcon from "../../../components/Icons/CheckIcon";
import { useGetDiariesQuery } from "../../../app/apiService";
import { toast } from "react-toastify";
import { ErrorType } from "../../../common/TypeHelper";

const DiariesTable = () => {
  const intl = useIntl();
  const coaUiParams = useDiariesUIContext();

  const diariesUIProps = useMemo(() => {
    return {
      queryParams: coaUiParams?.queryParams,
      setQueryParams: coaUiParams?.setQueryParams,
      diariesDeletedClicked: coaUiParams?.diariesDeletedClicked,
      editDiariesClicked: coaUiParams?.editDiariesClicked,
    };
  }, [coaUiParams]);
  const {
    data: { records: diaries = [], totalCount = 0 } = {},
    isError,
    error,
  } = useGetDiariesQuery({
    page: diariesUIProps.queryParams?.pageNumber,
    perPage: diariesUIProps.queryParams?.perPage,
    sort: diariesUIProps.queryParams?.sort,
    filter: diariesUIProps.queryParams?.filter,
  }) || {};
  if (isError && error) {
    const data = (error as ErrorType)?.data;
    if (data?.code === 10008) {
      toast.warn(intl.formatMessage({ id: "BOOKING.SELECT_ADMINSTRATION" }), {
        toastId: 10008,
      });
    }
  }
  return (
    <div className="col-12 page-body">
      <div className="card">
        <div className="table-responsive">
          <table className="table table-vcenter card-table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>{intl.formatMessage({ id: "DIARIES.NUMBER" })}</th>
                <th>{intl.formatMessage({ id: "DIARIES.DESCRIPTION" })}</th>
                <th>{intl.formatMessage({ id: "DIARIES.OFFSET_ACCOUNT" })}</th>
                <th className="text-center">
                  {intl.formatMessage({ id: "DIARIES.IS_MEMORIAL" })}
                </th>
                <th className="w-25">
                  {intl.formatMessage({ id: "ACTIONS" })}
                </th>
              </tr>
            </thead>
            <tbody>
              {diaries?.map((diary: DiariesInterface, index: number) => {
                return (
                  <tr key={diary.number}>
                    <td className="text-break text-wrap py-1">{index}</td>
                    <td className="text-break text-wrap py-1">
                      {diary.number}
                    </td>
                    <td className="text-break text-wrap py-1">
                      {diary.description}
                    </td>
                    <td className="text-break text-wrap py-1">
                      {diary.offsetAccount}
                    </td>
                    <td className="text-center py-1">
                      {" "}
                      {diary.isMemorial ? <CheckIcon /> : <XIcon />}
                    </td>
                    <td className="py-1">
                      <div className="d-flex justify-content-between w-25">
                        <button
                          onClick={() =>
                            diariesUIProps?.diariesDeletedClicked &&
                            diariesUIProps?.diariesDeletedClicked(diary?.number)
                          }
                          className="btn btn-sm btn-outline-danger"
                        >
                          {intl.formatMessage({ id: "DELETE" })}
                        </button>
                        <button
                          className="btn btn-sm btn-outline-primary ms-2"
                          onClick={() =>
                            diariesUIProps?.editDiariesClicked &&
                            diariesUIProps?.editDiariesClicked(diary?.number)
                          }
                        >
                          {intl.formatMessage({ id: "EDIT" })}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DiariesTable;
