import { useIntl } from 'react-intl'

export function FallbackMainView() {
  const intl = useIntl()
  return (
    <div className='splash-screen'>
      <span>
        <svg
          className='h-100px w-100px'
          version='1.1'
          id='L4'
          xmlns='http://www.w3.org/2000/svg'
          xmlns-xlink='http://www.w3.org/1999/xlink'
          x='0px'
          y='0px'
          viewBox='-8 20 70 70'
          enableBackground='new 0 0 0 0'
          xml-space='preserve'
        >
          <circle fill='currentColor' stroke='none' cx='6' cy='50' r='6'>
            <animate
              attributeName='opacity'
              dur='1s'
              values='0;1;0'
              repeatCount='indefinite'
              begin='0.1'
            />
          </circle>
          <circle fill='currentColor' stroke='none' cx='26' cy='50' r='6'>
            <animate
              attributeName='opacity'
              dur='1s'
              values='0;1;0'
              repeatCount='indefinite'
              begin='0.2'
            />
          </circle>
          <circle fill='currentColor' stroke='none' cx='46' cy='50' r='6'>
            <animate
              attributeName='opacity'
              dur='1s'
              values='0;1;0'
              repeatCount='indefinite'
              begin='0.3'
            />
          </circle>
        </svg>
      </span>
      <span>{intl.formatMessage({ id: 'LOCAL.LOADING', defaultMessage: 'Loading...' })}</span>
    </div>
  )
}
