import React, { useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { checkIsActive } from "../../helpers/RouterHelpers";

interface MenuItem {
  to: string;
  title: string;
  subItems?: MenuItem[];
}

interface MenuItemWithSubmenuProps {
  to: string;
  title: string;
  subItems?: MenuItem[];
  level?: number;
}

const MenuItemWithSubmenu: React.FC<MenuItemWithSubmenuProps> = ({ to, title, subItems, level = 0 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);

  const handleToggle = (e: React.MouseEvent) => {
    if (subItems) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  const className = `nav-item dropdown ${isOpen ? 'show' : ''} ${level > 0 ? 'dropdown-submenu' : ''}`;

  return (
    <div className={className}>
      <Link
        to={to}
        className={`nav-link ${subItems ? 'dropdown-toggle' : ''} ${isActive ? 'active' : ''}`}
        onClick={handleToggle}
      >
        {title}
      </Link>
      {subItems && (
        <div className={`dropdown-menu ${isOpen ? 'show' : ''}`} style={{ marginLeft: `${level * 10}px` }}>
          {subItems.map((subItem, index) => 
            subItem.subItems ? (
              <MenuItemWithSubmenu key={index} {...subItem} level={level + 1} />
            ) : (
              <Link key={index} to={subItem.to} className="dropdown-item">
                {subItem.title}
              </Link>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default MenuItemWithSubmenu;
