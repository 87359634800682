import { Field, Form, Formik } from "formik";
import React, { FC, useEffect, useRef } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useDynamicValidationSchema } from "../../../_panel/helpers/useDynamicValidationSchema";
import {
  intlMaxMessage,
  intlRequiredMessage,
} from "../../../_panel/helpers/validation";
import FormikCheckboxComponent from "../../../_panel/partials/formik/FormikCheckboxComponent";
import FormikInputComponent from "../../../_panel/partials/formik/FormikInputComponent";
import FormikSelectComponent from "../../../_panel/partials/formik/FormikSelectComponent";
import { useGetAccountsQuery } from "../../../app/apiService";
import { CoaInterface } from "../CoaModel";
const coaValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    number: yup
      .string()
      .label(intl.formatMessage({ id: "COA.ACCOUNT" }))
      .max(7, intlMaxMessage(intl))
      .required(intlRequiredMessage(intl)),
    description: yup
      .string()
      .label(intl.formatMessage({ id: "COA.DESCRIPTION" }))
      .nullable()
      .max(60, intlMaxMessage(intl)),
    parentAccount: yup
      .string()
      .label(intl.formatMessage({ id: "COA.ACCOUNT" }))
      .nullable()
      .max(7, intlMaxMessage(intl)),
    isBalance: yup.boolean().label(intl.formatMessage({ id: "COA.BALANCE" })),
    isSubAdm: yup
      .boolean()
      .label(intl.formatMessage({ id: "COA.IS_SUB_ADMIN" })),
  });

type Props = {
  data: CoaInterface;
  isEditMode?: boolean;
  onCancel: () => void;
  formSubmitted: (
    values: CoaInterface,
    setSubmitting: (status: boolean) => void
  ) => void;
};
const CoaForm: FC<Props> = (props) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { data: { records: accountTypes = [] } = {} } =
    useGetAccountsQuery({
      page: 0,
      perPage: 1000,
    }) || {};
  const options = [
    {
      value: null,
      label: intl.formatMessage({ id: "ROOT" }),
    },
    ...accountTypes
      .map((item: CoaInterface) => {
        return {
          value: item.number,
          label: item.number + " | " + (item.description ?? ""),
        };
      })
      .filter(
        (item: any) => item.value !== props.data.number && item.value !== "0"
      ),
  ];
  const { data, onCancel, formSubmitted, isEditMode } = props;
  const formRef: React.RefObject<any> = useRef();

  const validationSchema = useDynamicValidationSchema(coaValidationSchema);
  useEffect(() => {
    formRef?.current?.validateForm();
  }, [validationSchema]);
  useEffect(() => {
    if (isEditMode && !formRef?.current?.isSubmitting)
      formRef?.current?.setValues(data);
  }, [data]);
  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      validateOnBlur={true}
      innerRef={formRef}
      onSubmit={(values, { setSubmitting }) => {
        formSubmitted(
          {
            number: values.number,
            description: values.description,
            parentAccount: values.parentAccount,
            isSubAdm: values.isSubAdm,
            isBalance: values.isBalance,
          },
          (status: boolean) => {
            setSubmitting(false);
          }
        );
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (!isSubmitting) {
                submitForm();
                e.preventDefault();
              }
            }
          }}
        >
          <div className="card-body mt-2">
            <Field
              name="number"
              required
              disable={isEditMode}
              component={FormikInputComponent}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "COA.ACCOUNT",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              label={intl.formatMessage({ id: "COA.ACCOUNT" })}
            />
            <Field
              component={FormikInputComponent}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "BOOKING.DESCRIPTION",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              name="description"
              label={intl.formatMessage({ id: "BOOKING.DESCRIPTION" })}
            />
            <Field
              component={FormikSelectComponent}
              name="parentAccount"
              options={options}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "COA.TYPE",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              label={intl.formatMessage({ id: "COA.TYPE" })}
            />
            <Field
              component={FormikCheckboxComponent}
              name="isBalance"
              label={intl.formatMessage({ id: "COA.BALANCE" })}
              type="checkbox"
            />
            <Field
              component={FormikCheckboxComponent}
              name="isSubAdm"
              label={intl.formatMessage({ id: "COA.IS_SUB_ADMIN" })}
              type="checkbox"
            />
          </div>

          <div className="card-footer text-end">
            <div className="d-flex">
              <button className="btn btn-link" onClick={onCancel}>
                {intl.formatMessage({ id: "CANCEL" })}
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary ms-auto"
              >
                {isEditMode
                  ? intl.formatMessage({ id: "EDIT" })
                  : intl.formatMessage({ id: "CREATE" })}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CoaForm;
