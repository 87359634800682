import { Route, Routes } from "react-router";
import EOYMasterTablesCard from "../features/masterTables/EOYMasterTablesCard";

const EOYMasterTablesPage = () => {
  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <EOYMasterTablesCard/>
        </div>
      </div>
    </>
  );
};

export default EOYMasterTablesPage;
