import React from "react";
import { useIntl } from "react-intl";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useGetAccountsQuery, useGetCurrenciesQuery, useUpdateMasterEOYTableMutation } from "../../../app/apiService";
import FormikSelectComponent from "../../../_panel/partials/formik/FormikSelectComponent";
import { CoaInterface } from "../../chartOfAccounts/CoaModel";

const ForeignCurrencies: React.FC = () => {
  const intl = useIntl();
  const { data: { records: currencies = [] } = {}, isLoading: currenciesLoading } = useGetCurrenciesQuery({});
  const { data: { records: accounts = [] } = {}, isLoading: accountsLoading = true } =
    useGetAccountsQuery({
      page: 1,
      perPage: 99999999
    }) || {};

  const validationSchema = Yup.object().shape({
    currencyId: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRED" })),
    accountNo: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRED" })),
  });

  const [updateMasterEOYTable] = useUpdateMasterEOYTableMutation();

  const handleSubmit = async (values: { currencyId: string; accountNo: string }) => {
    try {
      console.log("values", values);
      // await updateMasterEOYTable(values);
      // Show success message
    } catch (error) {
      // Handle error
    }
  };

  if (currenciesLoading || accountsLoading) return <div>Loading...</div>;

  return (
    <Formik
      initialValues={{ currencyId: "", accountNo: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Field
          name="currencyId"
          component={FormikSelectComponent}
          label={intl.formatMessage({ id: "CURRENCIES.CURRENCY" })}
          options={currencies.map((c: { id: string; name: string }) => ({ value: c.id, label: c.name }))}
        />
        <Field
          component={FormikSelectComponent}
          label={intl.formatMessage({ id: "CURRENCIES.ACCOUNT" })}
          options={accounts
            .map((item: CoaInterface) => {
              return {
                value: item.number,
                label:
                  item.number + (item.description ? " | " + item.description : ""),
              };
            })
            .filter((item: any) => item.value !== "0") ?? []}
        />
        <button type="submit">
          {intl.formatMessage({ id: "COMMON.SUBMIT" })}
        </button>
      </Form>
    </Formik>
  );
};

export default ForeignCurrencies;
