import React, { FC, useEffect, useRef } from "react";
import { DiariesInterface } from "../DiariesModel";
import { IntlShape, useIntl } from "react-intl";
import { useDynamicValidationSchema } from "../../../_panel/helpers/useDynamicValidationSchema";
import { Field, Form, Formik } from "formik";
import {
  intlMaxMessage,
  intlRequiredMessage,
} from "../../../_panel/helpers/validation";
import * as yup from "yup";
import FormikInputComponent from "../../../_panel/partials/formik/FormikInputComponent";
import FormikCheckboxComponent from "../../../_panel/partials/formik/FormikCheckboxComponent";

const coaValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    number: yup
      .string()
      .label(intl.formatMessage({ id: "DIARIES.NUMBER" }))
      .required(intlRequiredMessage(intl))
      .max(70, intlMaxMessage(intl)),
    description: yup
      .string()
      .label(intl.formatMessage({ id: "DIARIES.DESCRIPTION" }))
      .required(intlRequiredMessage(intl))
      .max(60, intlMaxMessage(intl)),
    isMemorial: yup
      .boolean()
      .label(intl.formatMessage({ id: "DIARIES.IS_MEMORIAL" })),
    offsetAccount: yup.string().when("isMemorial", {
      is: true,
      then: (schema) => schema.nullable().max(7, intlMaxMessage(intl)),
      otherwise: (schema) =>
        schema.required(intlRequiredMessage(intl)).max(7, intlMaxMessage(intl)),
    }),
  });

type Props = {
  data: DiariesInterface;
  isEditMode?: boolean;
  onCancel: () => void;
  formSubmitted: (
    values: DiariesInterface,
    setSubmitting: (status: boolean) => void
  ) => void;
};
const DiariesForm: FC<Props> = (props) => {
  const { data, onCancel, formSubmitted, isEditMode } = props;
  const formRef: React.RefObject<any> = useRef();
  const intl = useIntl();
  const validationSchema = useDynamicValidationSchema(coaValidationSchema);
  useEffect(() => {
    formRef?.current?.validateForm();
  }, [validationSchema]);
  useEffect(() => {
    if (isEditMode && !formRef?.current?.isSubmitting)
      formRef?.current?.setValues(data);
  }, [data]);
  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      validateOnBlur={true}
      innerRef={formRef}
      onSubmit={(values, { setSubmitting }) => {
        formSubmitted(
          {
            ...values,
            offsetAccount: values.isMemorial ? undefined : values.offsetAccount,
          },
          (status: boolean) => {
            setSubmitting(false);
          }
        );
      }}
    >
      {({ isSubmitting, submitForm, values }) => (
        <Form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (!isSubmitting) {
                submitForm();
                e.preventDefault();
              }
            }
          }}
        >
          <div className="card-body mt-2">
            <Field
              name="number"
              required
              disable={isEditMode}
              component={FormikInputComponent}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "DIARIES.NUMBER",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              label={intl.formatMessage({ id: "DIARIES.NUMBER" })}
            />
            <Field
              name="description"
              required
              component={FormikInputComponent}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "DIARIES.DESCRIPTION",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              label={intl.formatMessage({ id: "DIARIES.DESCRIPTION" })}
            />
            <Field
              name="isMemorial"
              component={FormikCheckboxComponent}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "DIARIES.OFFSET_ACCOUNT",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              label={intl.formatMessage({ id: "DIARIES.IS_MEMORIAL" })}
            />
            <Field
              name="offsetAccount"
              required
              component={FormikInputComponent}
              disable={values?.isMemorial ?? false}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "DIARIES.OFFSET_ACCOUNT",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              label={intl.formatMessage({ id: "DIARIES.OFFSET_ACCOUNT" })}
            />
          </div>

          <div className="card-footer text-end">
            <div className="d-flex">
              <button className="btn btn-link" onClick={onCancel}>
                {intl.formatMessage({ id: "CANCEL" })}
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary ms-auto"
              >
                {isEditMode
                  ? intl.formatMessage({ id: "EDIT" })
                  : intl.formatMessage({ id: "CREATE" })}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DiariesForm;
