import React from "react";
import EOYImportJournalEntries from "../features/bookingImport/eoyimportJournalEntries";

const EOYBookingImportPage = () => {
  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <EOYImportJournalEntries/>
        </div>
      </div>
    </>
  );
};

export default EOYBookingImportPage;
