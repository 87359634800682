import { useState } from "react";
import LegacyForm from "./LegacyForm";
import ImportResult from "./ImportResult";

const DataInteroperability = () => {
  const [step, setStep] = useState(1);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  return (
    <>
      {step === 1 && (
        <LegacyForm setStep={setStep} setRejectedFiles={setRejectedFiles} />
      )}
      {step === 2 && (
        <ImportResult setStep={setStep} rejectedFiles={rejectedFiles} />
      )}
    </>
  );
};

export default DataInteroperability;
