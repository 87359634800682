import type { NodeModel } from "@minoru/react-dnd-treeview";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import CheckIcon from "../../../components/Icons/CheckIcon";
import XIcon from "../../../components/Icons/XIcon";
import { useCoaUIContext } from "../CoaUiContext";

type Props = {
  node: NodeModel;
  depth: number;
  isOpen: boolean;
  hasChild: boolean;
  testIdPrefix?: string;
  onToggle: (id: NodeModel["id"]) => void;
};

export const CustomNode: React.FC<Props> = ({
  testIdPrefix = "",
  ...props
}) => {
  const { id, droppable, data }: any = props.node;
  const intl = useIntl();
  const coaUiParams = useCoaUIContext();

  const accountsUIProps = useMemo(() => {
    return {
      queryParams: coaUiParams?.queryParams,
      setQueryParams: coaUiParams?.setQueryParams,
      deleteCoaClicked: coaUiParams?.deleteCoaClicked,
      editCoaClicked: coaUiParams?.editCoaClicked,
    };
  }, [coaUiParams]);
  if (props.hasChild && !props.isOpen) {
    props.onToggle(props.node.id);
  }
  return (
    <div className="row d-flex ">
      <div className="col-md-2 text-break text-wrap account-node-item">
        {data.number}
      </div>
      <div className="col-md-4 text-break text-wrap account-node-item border-left-none">
        {data.description}
      </div>
      <div className="row col-md-3">
        <div className="col-md-6 account-node-item border-left-none">
          {" "}
          {data.isBalance ? <CheckIcon /> : <XIcon />}
        </div>
        <div className="col-md-6 account-node-item border-left-none">
          {data.isSubAdm ? <CheckIcon /> : <XIcon />}
        </div>
      </div>
      <div className="col-md-2 ">
        <div className="d-flex justify-content-between w-25 ">
          <button
            onClick={() =>
              accountsUIProps?.deleteCoaClicked &&
              accountsUIProps?.deleteCoaClicked(data?.number)
            }
            className="btn btn-sm btn-outline-danger"
          >
            {intl.formatMessage({ id: "DELETE" })}
          </button>
          <button
            className="btn btn-sm btn-outline-primary  ms-2"
            onClick={() =>
              accountsUIProps?.editCoaClicked &&
              accountsUIProps?.editCoaClicked(data?.number)
            }
          >
            {intl.formatMessage({ id: "EDIT" })}
          </button>
        </div>
      </div>
    </div>
  );
};
