import { useIntl } from "react-intl";
import { useGetBookingsQuery } from "../../app/apiService";
import { useGetAccountsQuery } from "../../app/apiService";
import { useGetChildAccountsByIdQuery } from "../../app/apiService";
import { saveAs } from "file-saver";
import ProgressBar from "../../_panel/partials/ProgressBar";
import { BookingObject } from '../../features/booking/BookingModel'
import { filter } from "lodash";
import { BookingUIConsumer } from "../booking/BookingUiContext";
import { BookingYear } from '../../app/administrationSlice/administrationSlice';
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../app/RootReducer";
const downloadBookingsClickedReprtsExcel = async () => {
  try {
    const response = await fetch('/api/reports/generate-excel');

    if (!response.ok) {
      throw new Error('Failed to generate Excel file');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'report.xlsx';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error:', error);
  }
}



/**
 * Account type for bookings.
 * @type Object with properties for booking details:
 * - type: string 
 * - date: Date 
 * - amount: number
*/
type Account =
  {
    type: string,
    date: Date,
    amount: number
  }
/**
 * Array of objects with booking details for companies.
 * @type Array where each object represents a company and contains:
 * - company: string - Company name
 * - domicile: string - Company location 
 * - share: number - Company share percentage
 * - accounts: Account[] - Array of Account objects 
 */
type Data = {
  company: string,
  domicile: string,
  share: number,
  accounts: Account[]
}[];
/**
 * Balance type.
 * Object containing booking balance details:
 * - id: string - Unique ID 
 * - data: Data - Array of booking details by company
 * - total: Account[] - Array of total booking amounts
*/
type Balance = {
  "id": string, data: Data, "total": Account[]
}
type Balances = Balance[];
/**
 * Records type. 
 * Object containing booking balances and raw bookings.
 * @type Object with properties:
 * - balances: Balances - Array of booking balance objects
 * - bookings: BookingObject[] - Array of raw booking objects
*/
type Records = {
  balances: Balances,
  "bookings": BookingObject[]
};

/**
 * Object containing default record values for generating reports.
 * Contains company, address, and accountant details.
*/
const baseRecord = {
  "company": "MyFrem Holding B.V.",
  "domicile": "'s-Hertogenbosch",
  "domicilePostcode": "5211 CS",
  "domicileStreet": "Zuidwillemsvaart 107-b",
  "CoC": 64478475,
  "directors": "mevrouw J. Verhagen",
  "year_before_before": 2018,
  "year_before": 2019,
  "year": 2020,
  "attn": "heer",
  "addresseeFirstName": "de heer S.",
  "addresseeLastName": "Verhagen",
  "addresseeStreet": "Zuidwillemsvaart 107-b",
  "addresseePlace": "5211 SC  's-Hertogenbsoch",
  "signPlaceAndDate": "Goirle, 20 december 2023",
  "accountant": "Early administraties",
  "accountantPerson": "E. van der Geld",
}

/**
 * Retrieves child accounts for the account with the given ID.
 * 
 * @param id - The ID of the parent account to get child accounts for.
 * @returns The child accounts for the account with the given ID.
 */
const GetChilds = (id: any) => {
  const { data: accountsEntry, isError, isLoading } = useGetChildAccountsByIdQuery(id);
  return accountsEntry;
}

/**
 * Renders a card with a download bookings button and handles downloading 
 * a JSON report when clicked.
 * 
 * Retrieves the selected booking year from Redux state.
 * Fetches bookings for that booking year using a React Query hook.
 * Traverses accounts to summarize bookings.
 * Generates a filename with the current date.
 * Converts summarized bookings to JSON and triggers file download.
 * 
 * Shows a loading indicator if bookings are still loading.
 */
export const GenerateYearReport = () => {
  /**
* Retrieves the currently selected booking year from the Redux store.
* Uses the shallowEqual selector for performance.
*/
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration.bookingYears,
    shallowEqual
  ) as BookingYear;
  const { data: { records: bookings = [] } = {} } =
    /**
 * Fetches bookings for the selected booking year from the API.
 * 
 * Uses the `useGetBookingsQuery` React Query hook to fetch the bookings,
 * passing the selected booking year ID as a parameter.
 * 
 * Skips the query if no booking year is selected.
 */
    useGetBookingsQuery({
      page: 0,
      perPage: 0,
      bookingYearsNo: selectedBookingYear?.id,
    },
      {
        skip:
          !selectedBookingYear?.id,
      }
    ) || {};
  const { data: { records: accounts = [] } = {}, isLoading: isLoadingGetAccounts = true } =
    useGetAccountsQuery({
      page: 1,
      perPage: 99999999
    }) || {};

  const intl = useIntl();



  /**
 * Generates a downloadable JSON report for the selected booking year. 
 * 
 * Traverses the account tree depth-first to 
 * summarize bookings by account, filters to selected report type, 
 * generates filename, and triggers file download.
*/
  const downloadBookingsClickedJSON = (): any => {

    // https://medium.com/@dispensableart/implementing-bfs-and-dfs-in-javascript-11efaa4652
    // If the tree is very wide: DFS (depth first search) — 
    // this is because if the tree is wide and a BFS is very memory-intensive then storing all the children 
    // for each node will take up a lot of memory
    // memory consumption or space complexity will be O(height of the tree) when using DFS.
    // DFS recursively traverses the tree in depth-first order
    //we return a function in these and they are all recursive simply because of the nature of DFS. we once again must start at the root node and our empty array, or the list, will contain the values of our nodes
    const traversDFSBookingsInOrder = (root: any[]) => {
      return traverseInOrder(root, []);
    }

    //DFS  Depth First Search
    const traverseInOrder: any = async (node: any, list: any) => {
      const accountsEntry = GetChilds(node.id);
      //  for (all children of parent)
      for (let acct of accountsEntry.childParentAccounts) {
        const accountChild = acct.child;
        if (accountChild) {
          traverseInOrder(accountChild, list); //recursive call


          //Check all  Bookings whether they have a parentAccount which is 
          //   equal to the account of the current child
          //================================================================
          //later make more efficient by summarizing the bookings beforehand
          //================================================================
          for (let booking of bookings) {
            if (booking.parentAccount && booking.parentAccount === accountChild.account) {
              // if (Math.round(100 * (x.amountDebet - x.amountCredit)) / 100 !== 0) 
              bookingsSummary.push({
                reportName: booking.parentAccount.reportName,
                ordr: booking.parentAccount.ordr,
                number: booking.parentAccount.number,
                parentAccount: booking.parentAccount.parentAccount,
                description: booking.parentAccount.description,
                debets: booking.amountDebet,
                credits: booking.amountCredit,
                Net: Math.round(100 * (booking.amountDebet - booking.amountCredit)) / 100
              });
            }
          }
          list.push(accountChild);
        }
      }
      return list;
    }

    // I. Append all accounting figures
    // summary AS (
    //   SELECT * 
    //   FROM dbcr
    //   WHERE Net != 0
    //   UNION ALL
    //   SELECT d.ordr, d.number, d.ParentAccount, d.description, s.Debits, s.Credits, s.Net
    //   FROM dbcr d, summary s 
    //   WHERE d.number = s.ParentAccount AND s.ParentAccount IS NOT NULL
    // )
    // console.log(x)

    var bookingsSummary: any[] = [];
    //================================================================
    //================================================================
    //================================================================

    // Walk through all accounts, if parentAccount===null then it is a root;
    // find all accounts that point to that root
    const rootAccounts = accounts.filter((acc: any) => !acc.parentAccount);
    for (let root of rootAccounts) {
      bookingsSummary = bookingsSummary.concat(traversDFSBookingsInOrder(root));
    }
    //================================================================
    //================================================================
    //================================================================


    console.log(bookingsSummary)
    //   LEFT JOIN Reports r ON r.Account=acc.number WHERE  
    // (r.Name  = "Jaarrekening")
    // -- r.Name = "Balans" OR KvK 
    //   -- AND r.Name = "Resultaten"  etc
    const reportbookings = bookingsSummary.filter((el: any) => el.reportName === "Jaarrekening");

    // SELECT ordr, number, ParentAccount, description, 
    //        SUM(Debits) AS Debits,
    //        SUM(Credits) AS Credits,
    //        SUM(Net) AS Net
    // FROM summary
    // GROUP BY ordr, number, ParentAccount, description
    // ORDER BY ordr

    const companies = ["AudioCoup", "Zoof-It", "Gnoma"]
    // concatenate json:
    var records: Records = {
      // balances: companyShares(companies, [50, 50, 100], bookingsSummary).concat(liquidAssets(bookingsSummary)).concat(receivablesAndTransitory(companies, bookingsSummary)),
      balances: reportbookings,
      bookings: bookings
    }
    records = Object.assign(
      baseRecord,
      records
    )

    const blob = new Blob([JSON.stringify(records, null, "\t")], {
      type: "application/json",
    });

    const currentDate = new Date();
    const niceDateFormat = `${currentDate.getMonth() + 1
      }_${currentDate.getDate()}_${currentDate.getFullYear()}_${currentDate.getHours()}_${currentDate.getMinutes()}`;

    const fileName = `bookings_${niceDateFormat}.json`;
    saveAs(blob, fileName);
  };


  /**
 * Renders a card with a download bookings button. 
 * Shows a loading indicator if bookings are still loading.
 * Calls the downloadBookingsClicked handler when button is clicked.
 */

  return (
    <div className="card">
      {isLoadingGetAccounts && <ProgressBar />}
      <div className="card-header">
        <h3 className="card-title">
          {intl.formatMessage({ id: "OVERVIEWS.TITLE" })}
        </h3>
      </div>
      <div className="card-body">
        <small className="form-hint">
          {intl.formatMessage({ id: "OVERVIEWS.DOWNLOAD_BOOKINGS_DESCRIPTION" })}
        </small>
        <button
          type="button"
          disabled={isLoadingGetAccounts}
          onClick={downloadBookingsClickedJSON}
          className="btn btn-primary mx-2"
        >
          {intl.formatMessage({
            id: "OVERVIEWS.DOWNLOAD_BOOKINGS_JSON",
          })}
        </button>
      </div>
    </div>
  );
};



// https://www.db-fiddle.com/f/rvccjC6HSw7vmDepQyvYBn/100
/**
 * Calculates company share balances for the given companies, shares, 
 * and bookings. 
 * 
 * Returns balances data for company shares with start and end balances.
*/
export const companyShares = (companies: string[], shares: number[], bookings: BookingObject[]): Balances => {
  // 1. company shares:
  var SUM_JANUARI_1_2020 = 0;
  var SUM_VERMOGENSMUTATIE = 0;
  var SUM_DECEMBER_31_2020 = 0;
  const data: Data = [];
  for (var i = 0; i < companies.length; i++) {
    const accounts: Account[] = [];
    accounts.push({ type: "Dummy", date: new Date("1970-01-01"), amount: -1 }); // carbone.io sometimes needs a first random value because carbone ignorres the first value
    const company = companies[i];
    const share = shares[i];

    var bookingsShare = bookings.filter((el: any) => el.type === "Deelneming" && el.diaryNo === 1 && el.account.description.toLowerCase().indexOf(company.toLowerCase()) !== -1);
    bookingsShare = bookingsShare.filter((el: any) => el.date.substring(5, 10) === "01-01");
    const JANUARI_1_2020 = bookingsShare.reduce((sum: any, el: any) => sum + el.amountDebet - el.amountCredit, 0.00);
    accounts.push({ type: "Deelneming", date: new Date(baseRecord.year + "-01-01"), amount: JANUARI_1_2020 });
    SUM_JANUARI_1_2020 += JANUARI_1_2020;
    bookingsShare = bookings.filter((el: any) => el.type === "DeelnemingVermogenMutatie" && el.account.description.toLowerCase().indexOf(company.toLowerCase()) !== -1);
    const VERMOGENSMUTATIE = bookingsShare.reduce((sum: any, el: any) => sum + el.amountDebet - el.amountCredit, 0.00);
    accounts.push({ type: "DeelnemingVermogenMutatie", date: new Date(baseRecord.year + "-12-31"), amount: VERMOGENSMUTATIE });
    SUM_VERMOGENSMUTATIE += VERMOGENSMUTATIE;
    const DIVIDENDUITKERING = 0; //TODO
    accounts.push({ type: "DividendUitkering", date: new Date(baseRecord.year + "-12-31"), amount: DIVIDENDUITKERING });
    bookingsShare = bookings.filter((el: any) => el.type === "DeelnemingResultaat" && el.account.description.toLowerCase().indexOf(company.toLowerCase()) !== -1);
    const RESULTAAT_BOEKJAAR = bookingsShare.reduce((sum: any, el: any) => sum + el.amountDebet - el.amountCredit, 0.00);
    accounts.push({ type: "DeelnemingResultaat", date: new Date(baseRecord.year + "-12-31"), amount: RESULTAAT_BOEKJAAR });
    bookingsShare = bookings.filter((el: any) => el.type === "Deelneming" && el.account.description.toLowerCase().indexOf(company.toLowerCase()) !== -1);
    const DECEMBER_31_2020 = bookingsShare.reduce((sum: any, el: any) => sum + el.amountDebet - el.amountCredit, 0.00) + RESULTAAT_BOEKJAAR;
    accounts.push({ type: "Deelneming", date: new Date(baseRecord.year + "-12-31"), amount: DECEMBER_31_2020 });
    SUM_DECEMBER_31_2020 += DECEMBER_31_2020;
    data.push({
      company: company + " B.V.",
      domicile: "'s-Hertogenbosch",
      share: share,
      accounts: accounts
    })
  }
  // Total shares end and start of year
  const total = [];
  total.push({ type: "Dummy", date: new Date("1970-01-01"), amount: -1 }); // carbone.io sometimes needs a first random value because carbone ignorres the first value
  total.push({ type: "Deelneming", date: new Date(baseRecord.year + "-12-31"), amount: SUM_DECEMBER_31_2020 });//31 DECEMBER 2020
  total.push({ type: "Deelneming", date: new Date(baseRecord.year_before + "-12-31"), amount: SUM_JANUARI_1_2020 - SUM_VERMOGENSMUTATIE });//31 DECEMBER 2019
  const balances =
    [{
      id: "companyShares",
      total: total,
      data: data
    }]
  return balances;
}

/**
 * Calculates liquid assets balances for the year.
 * 
 * Filters bookings for liquid assets accounts. 
 * Sums balances on Jan 1 and Dec 31 to get start and end balances.
 * Returns balances in expected format for report.
 */
export const liquidAssets = (bookings: BookingObject[]): Balances => {
  const data: any[] = [];
  const accounts = [];
  accounts.push({ type: "Dummy", date: new Date("1970-01-01"), amount: -1 }); // carbone.io sometimes needs a first random value because carbone ignorres the first value
  var bookings = bookings.filter((el: any) => el.type === "LiquideMiddelen" && el.diaryNo === 1);
  bookings = bookings.filter((el: any) => el.date.substring(5, 10) === "01-01");
  const JANUARI_1_2020 = bookings.reduce((sum: any, el: any) => sum + el.amountDebet - el.amountCredit, 0.00);
  accounts.push({ type: "LiquideMiddelen", date: new Date(baseRecord.year + "-01-01"), amount: JANUARI_1_2020 });
  bookings = bookings.filter((el: any) => el.type === "LiquideMiddelen");
  const DECEMBER_31_2020 = bookings.reduce((sum: any, el: any) => sum + el.amountDebet - el.amountCredit, 0.00);
  accounts.push({ type: "LiquideMiddelen", date: new Date(baseRecord.year + "-12-31"), amount: DECEMBER_31_2020 });
  data.push({
    company: "dummy",
    domicile: "dummy",
    share: -1,
    accounts: accounts
  })

  return [{
    id: "liquidAssets",
    total: [{ type: "Dummy", date: new Date("1970-01-01"), amount: -1 }],
    data: data
  }];
}

/**
 * Calculates receivables and transitory account balances for the year.
 * 
 * Filters bookings for receivables from group companies and tax accounts.
 * Sums balances on Jan 1 and Dec 31 to get start and end balances.
 * Returns balances for each company and account in expected format for report.
 */
export const receivablesAndTransitory = (companies: string[], bookings: BookingObject[]): Balances => {
  // 1. company shares:
  var SUM_JANUARI_1_2020 = 0;
  var SUM_DECEMBER_31_2020 = 0;
  const data: any[] = [];
  const accounts = [];
  for (var i = 0; i < companies.length; i++) {
    const accounts: Account[] = [];
    const company = companies[i];

    accounts.push({ type: "Dummy", date: new Date("1970-01-01"), amount: -1 }); // carbone.io sometimes needs a first random value because carbone ignorres the first value
    var bookingsRC = bookings.filter((el: any) => el.type === "GroepsmaatschappijenVorderingen" && el.diaryNo === 1 && el.account.description.toLowerCase().indexOf(company.toLowerCase()) !== -1);
    bookingsRC = bookingsRC.filter((el: any) => el.date.substring(5, 10) === "01-01");
    const JANUARI_1_2020 = bookingsRC.reduce((sum: any, el: any) => sum + el.amountDebet - el.amountCredit, 0.00);
    accounts.push({ type: "Rekening Courant " + company, date: new Date(baseRecord.year + "-01-01"), amount: JANUARI_1_2020 });
    SUM_JANUARI_1_2020 += JANUARI_1_2020;
    bookingsRC = bookings.filter((el: any) => el.type === "GroepsmaatschappijenVorderingen" && el.account.description.toLowerCase().indexOf(company.toLowerCase()) !== -1);
    const DECEMBER_31_2020 = bookingsRC.reduce((sum: any, el: any) => sum + el.amountDebet - el.amountCredit, 0.00);
    accounts.push({ type: "Rekening Courant " + company, date: new Date(baseRecord.year + "-12-31"), amount: DECEMBER_31_2020 });
    SUM_DECEMBER_31_2020 += DECEMBER_31_2020;

    data.push({
      company: company,
      domicile: "dummy",
      share: -1,
      accounts: accounts
    })
  }
  var bookings = bookings.filter((el: any) => el.type === "BelastingenEnSocialeLasten" && el.diaryNo === 1);
  bookings = bookings.filter((el: any) => el.date.substring(5, 10) === "01-01");
  const JANUARI_1_2020 = bookings.reduce((sum: any, el: any) => sum + el.amountDebet - el.amountCredit, 0.00);
  accounts.push({ type: "BelastingenEnSocialeLasten", date: new Date(baseRecord.year + "-01-01"), amount: JANUARI_1_2020 });
  SUM_JANUARI_1_2020 += JANUARI_1_2020;
  bookings = bookings.filter((el: any) => el.type === "BelastingenEnSocialeLasten");
  const DECEMBER_31_2020 = bookings.reduce((sum: any, el: any) => sum + el.amountDebet - el.amountCredit, 0.00);
  accounts.push({ type: "BelastingenEnSocialeLasten", date: new Date(baseRecord.year + "-12-31"), amount: DECEMBER_31_2020 });
  SUM_DECEMBER_31_2020 += DECEMBER_31_2020;

  data.push({
    company: "dummy",
    domicile: "dummy",
    share: -1,
    accounts: accounts
  })

  const total = [];
  total.push({ type: "Dummy", date: new Date("1970-01-01"), amount: -1 }); // carbone.io sometimes needs a first random value because carbone ignorres the first value
  total.push({ type: "receivablesAndTransitory", date: new Date(baseRecord.year + "-12-31"), amount: SUM_DECEMBER_31_2020 });//31 DECEMBER 2020
  total.push({ type: "receivablesAndTransitory", date: new Date(baseRecord.year_before + "-12-31"), amount: SUM_JANUARI_1_2020 });//31 DECEMBER 2019
  return [{
    id: "receivablesAndTransitory",
    total: total,
    data: data
  }];
}

export default GenerateYearReport;


