// Helper function to generate max validation message
import { IntlShape } from "react-intl";

export const intlMaxMessage = (intl: IntlShape) => (data: any) =>
  intl.formatMessage({ id: "VALIDATION.MAX" }, { ...data });

// Helper function to generate required validation message
export const intlRequiredMessage = (intl: IntlShape) => (data: any) =>
  intl.formatMessage({ id: "VALIDATION.REQUIRED" }, { ...data });

export const intlMaxNumberMessage = (intl: IntlShape) => (data: any) =>
  intl.formatMessage({ id: "VALIDATION.MAX_NUMBER" }, { ...data });
