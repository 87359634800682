import React, { useMemo } from "react";
import { useLang,setLanguage } from "../../i18n/Paneli18n";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../helpers";

const LanguageSelector = () => {
  const intl = useIntl();
  const languagesUicontext = useLang();
  const languagesUIProps = useMemo(() => {
    if (!languagesUicontext) return null;
    return {
      language: languagesUicontext?.language,
      setLanguage: languagesUicontext?.setLanguage,
    };
  }, [languagesUicontext]);
  const languages = [
    {
      lang: "en",
      name: intl.formatMessage({ id: "LOCAL.ENGLISH" }),
      flag: toAbsoluteUrl("/static/flags/usa.svg"),
    },
    {
      lang: "nl",
      name: intl.formatMessage({ id: "LOCAL.DUTCH" }),
      flag: toAbsoluteUrl("/static/flags/netherlands.svg"),
    },
  ];
  const currentLanguage = languages.find(
    (x) => x.lang === languagesUIProps?.language
  );
  return (
    <div className="nav-item dropdown px-3">
      <a
        href="#"
        className="nav-link d-flex lh-1 text-reset p-0"
        data-bs-toggle="dropdown"
        aria-label="Open user menu"
      >
        <span
          className="avatar avatar-sm"
          style={{
            backgroundImage: `url(${currentLanguage?.flag})`,
            backgroundSize: "contain",
          }}
        />
        <div className="d-none d-xl-block ps-2">
          <div className="fw-bold"> {currentLanguage?.name} </div>
          <div className="mt-1 small text-muted"></div>
        </div>
      </a>

      <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
        {languages.map((language) => {
          return (
            <a
              key={language.lang}
              href="#"
              onClick={() => {
                setLanguage(language.lang)
                languagesUIProps?.setLanguage && languagesUIProps?.setLanguage(language.lang)
                document.body.click()
              }}
              className="dropdown-item justify-content-start px-3"
            >
              <span
                className="avatar avatar-xs me-3"
                style={{
                  backgroundImage: `url(${language?.flag})`,
                  backgroundSize: "contain",
                }}
              />
              <span className="fw-bold">{language.name}</span>
              
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default LanguageSelector;
