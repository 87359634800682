import * as React from "react";

// NOTE: all modules imported below may be imported from '@silevis/reactgrid'
import {
  Cell,
  CellStyle,
  CellTemplate,
  Compatible,
  Span,
  Uncertain,
  getCellProperty,
  keyCodes,
} from "@silevis/reactgrid";
import { useBookingUIContext } from "../../BookingUiContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

const sortItems = [
  "date",
  "account",
  "bookingHeaderId",
  "description",
  "amountDebet",
  "amountCredit",
];
export interface HeaderCell extends Cell, Span {
  type: "sortableHeader";
  text: string;
  column: string;
}

export class SortableHeader implements CellTemplate<HeaderCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<HeaderCell>
  ): Compatible<HeaderCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    const column = getCellProperty(uncertainCell, "column", "string");
    const value = parseFloat(text);
    return { ...uncertainCell, text, column, value };
  }

  render(
    cell: Compatible<HeaderCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<HeaderCell>, commit: boolean) => void
  ): React.ReactNode {
    return <HeaderView cell={cell} />;
  }

  isFocusable = (cell: Compatible<HeaderCell>): boolean => false;

  getClassName(cell: Compatible<HeaderCell>, isInEditMode: boolean): string {
    return cell.className ? cell.className : "";
  }

  getStyle = (cell: Compatible<HeaderCell>): CellStyle => ({
    background: "rgba(128, 128, 128, 0.1)",
  });
}
interface DIProps {
  cell: Record<string, any>;
}
const HeaderView: React.FC<DIProps> = ({ cell }) => {
  const bookingUiParams = useBookingUIContext();
  const bookingUIProps = React.useMemo(() => {
    return {
      canLeaveChanged: bookingUiParams?.canLeaveChanged,
      bookingDeleted: bookingUiParams?.bookingDeleted,
      editBookingClicked: bookingUiParams?.editBookingClicked,
      queryParams: bookingUiParams?.queryParams,
      setQueryParams: bookingUiParams?.setQueryParams,
    };
  }, [bookingUiParams]);
  const sortChanged = (label: React.ReactNode | null) => {
    if (!label) {
      return;
    }
    if (
      bookingUIProps.queryParams?.sortBy === cell.column &&
      bookingUIProps?.setQueryParams &&
      bookingUIProps.queryParams
    ) {
      const sortOrder =
        bookingUIProps.queryParams.sortOrder === "ASC" ? "DESC" : "ASC";
      bookingUIProps.setQueryParams({
        ...bookingUIProps.queryParams,
        sortOrder: sortOrder,
        sort: `?sort=${
          sortOrder === "ASC" ? "-" : ""
        }${bookingUIProps.queryParams?.sortBy?.toLowerCase()}`,
      });
    } else {
      if (bookingUIProps?.setQueryParams && bookingUIProps.queryParams) {
        bookingUIProps.setQueryParams({
          ...bookingUIProps?.queryParams,
          sortOrder: "DESC",
          sortBy: cell.column,
          sort: `?sort=${cell.column}`,
        });
      }
    }
  };

  return (
    <div className="w-100" onClick={() => sortChanged(cell.column)}>
      {cell.text}{" "}
      {bookingUIProps.queryParams?.sortBy === cell.column && (
        <FontAwesomeIcon
          color="#6e6e6e"
          icon={
            bookingUIProps.queryParams?.sortOrder === "ASC"
              ? faSortDown
              : faSortUp
          }
          className={`ms-1 `}
        />
      )}
    </div>
  );
};
