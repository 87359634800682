import React from "react";
import { useIntl } from "react-intl";
import { useCoaUIContext } from "../CoaUiContext";

const CoaHeader = () => {
  const intl = useIntl();
  const coaContext = useCoaUIContext();
  return (
    <div className="page-header d-print-none">
      <div className="container-xl">
        <div className="row g-2 align-items-center">
          <div className="col">
            {/* Page pre-title */}
            <div className="page-pretitle">
              {" "}
              {intl.formatMessage({ id: "ASIDE.GL" })}
            </div>
            <h2 className="page-title">
              {intl.formatMessage({ id: "ASIDE.CHART_OF_ACCOUNTS" })}
            </h2>
          </div>
          {/* Page title actions */}
          <div className="col-auto ms-auto d-print-none">
            <div className="btn-list">
              <div
                onClick={() => coaContext?.newCoaClicked()}
                className="btn btn-primary d-inline-block"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 5l0 14" />
                  <path d="M5 12l14 0" />
                </svg>
                {intl.formatMessage({ id: "COA.CREATE" })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoaHeader;
