import {
  CellChange,
  CellLocation,
  Column,
  Id,
  MenuOption,
  ReactGrid,
  Row,
  SelectionMode,
} from "@silevis/reactgrid";
import * as React from "react";
import { FC } from "react";
import { IntlShape, useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import { RootState } from "../../../app/RootReducer";
import { BookingYear } from "../../../app/administrationSlice/administrationSlice";
import {
  useGetAccountsQuery,
  useGetBookingsQuery,
  useGetUserProfileQuery,
  useSplitBookingMutation,
  useUpdateBookingMutation,
} from "../../../app/apiService";
import { extractNumber } from "../../../common/Utils";
import { CoaInterface } from "../../chartOfAccounts/CoaModel";
import BookingCreateRow from "../BookingCreate/BookingCreateRow";
import { BookingInterface } from "../BookingModel";
import { useBookingUIContext } from "../BookingUiContext";
import BookingFilter from "./BookingFilter";
import { DateCellTemplate } from "./GridCell/DateCellTemplate";
import { NumberCellTemplate } from "./GridCell/NumberCellTemplate";
import { SelectCellTemplate } from "./GridCell/SelectCellTemplate";
import { SortableHeader } from "./GridCell/SortableHeader";
const calculateWidth = (
  gridWidth: number | undefined,
  width: number,
  widthCoefficient: number
): string => {
  if (!gridWidth) {
    return width.toString();
  }
  return Math.ceil(gridWidth * widthCoefficient).toString();
};
const getColumns = (gridWidth: number | undefined): Column[] => [
  {
    columnId: "id",
    width: Math.ceil(
      parseFloat(
        localStorage.getItem(`columnWidth-id`) ||
          calculateWidth(gridWidth, 75, 0.058)
      )
    ),
    resizable: true,
  },
  {
    columnId: "date",
    width: parseFloat(
      localStorage.getItem(`columnWidth-date`) ||
        calculateWidth(gridWidth, 120, 0.093)
    ),
    resizable: true,
  },
  {
    columnId: "number",
    width: parseFloat(
      localStorage.getItem(`columnWidth-accountNo`) ||
        calculateWidth(gridWidth, 290, 0.225)
    ),
    resizable: true,
  },
  {
    columnId: "bookingHeaderId",
    width: parseFloat(
      localStorage.getItem(`columnWidth-bookingHeaderId`) ||
        calculateWidth(gridWidth, 100, 0.0762)
    ),
    resizable: true,
  },
  {
    columnId: "description",
    width: parseFloat(
      localStorage.getItem(`columnWidth-description`) ||
        calculateWidth(gridWidth, 390, 0.299)
    ),
    resizable: true,
  },
  {
    columnId: "amountDebet",
    width: parseFloat(
      localStorage.getItem(`columnWidth-amountDebet`) ||
        calculateWidth(gridWidth, 155, 0.122)
    ),
    resizable: true,
  },
  {
    columnId: "amountCredit",
    width: parseFloat(
      localStorage.getItem(`columnWidth-amountCredit`) ||
        calculateWidth(gridWidth, 155, 0.122)
    ),
    resizable: true,
  },
];

const getRows = (
  booking: any[],
  intl: IntlShape,
  options: any[],
  isMemorial: boolean
): Row[] => {
  const headerRow: Row<any> = {
    rowId: "header",
    cells: [
      { type: "sortableHeader", text: "#", column: "id" },
      {
        type: "sortableHeader",
        text: intl.formatMessage({ id: "BOOKING.DATE" }),
        column: "date",
      },
      {
        type: "sortableHeader",
        text: intl.formatMessage({ id: "BOOKING.ACCOUNT_NUMBER" }),
        column: "account",
      },
      {
        type: "sortableHeader",
        text: intl.formatMessage({ id: "BOOKING.INVOICE" }),
        column: "bookingHeaderId",
      },
      {
        type: "sortableHeader",
        text: intl.formatMessage({ id: "BOOKING.DESCRIPTION" }),
        column: "description",
      },
      {
        type: "sortableHeader",
        text: intl.formatMessage({ id: "BOOKING.AMOUNT_DEBT" }),
        column: "amountDebet",
      },
      {
        type: "sortableHeader",
        text: intl.formatMessage({ id: "BOOKING.AMOUNT_CREDIT" }),
        column: "amountCredit",
      },
    ],
  };

  return [
    headerRow,
    ...booking.map<any>((book, idx) => ({
      rowId: idx,
      cells: [
        {
          type: "header",
          text: book.id?.toString(),
        },
        {
          type: "dateCell",
          date: new Date(book.date),
          className: book.className,
        },
        {
          type: "selectDropdown",
          text: book.number?.toString() ?? "",
          options: options,
          className: book.className,
        },
        {
          type: "numberCell",
          value: book.bookingHeaderId,
          className: book.className,
          textStyle: "text-body",
        },
        { type: "text", text: book.description, className: book.className },
        {
          type: "numberCell",
          hideZero: true,
          value: book.amountDebet !== 0 ? book.amountDebet : 0,
          className: book.className,
        },
        {
          type: "numberCell",
          hideZero: true,
          value: book.amountCredit !== 0 ? book.amountCredit : 0,
          className: book.className,
        },
      ],
    })),
  ];
};

const changedRows = (
  changes: CellChange<any>[],
  prevBookings: any[]
): BookingInterface[] => {
  const updatedBookings: BookingInterface[] = [];
  changes.forEach((change) => {
    const bookingIndex: any = change.rowId;
    const fieldName = change.columnId;
    if (prevBookings[bookingIndex] && change.newCell) {
      prevBookings[bookingIndex] = {
        ...prevBookings[bookingIndex],
        [fieldName]:
          change.newCell.type === "numberCell"
            ? change.newCell.value
            : change.newCell.text,
      };
      updatedBookings.push(prevBookings[bookingIndex]);
    }
  });
  return updatedBookings;
};
const applyChangesToBookings = (
  changes: CellChange<any>[],
  prevBookings: any[]
): any[] => {
  const updatedBookings = [];
  changes.forEach((change) => {
    const bookingIndex: any = change.rowId;
    const fieldName = change.columnId;
    if (prevBookings[bookingIndex] && change.newCell) {
      prevBookings[bookingIndex] = {
        ...prevBookings[bookingIndex],
        [fieldName]:
          change.newCell.type === "numberCell"
            ? change.newCell.value
            : change.newCell.text,
      };
      updatedBookings.push(prevBookings[bookingIndex]);
    }
  });
  return [...prevBookings];
};
interface Props {
  isMemorial: boolean;
}
const BookingGrid: FC<Props> = ({ isMemorial }) => {
  const { data: userData } = useGetUserProfileQuery({});
  const [updateBooking, { isLoading: isLoadingUpdateBooking }] =
    useUpdateBookingMutation();
  const [splitBooking, { isLoading: isLoadingSplitBooking }] =
    useSplitBookingMutation();
  const gridBoxRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = React.useState<any[]>([]);
  const intl = useIntl();
  const bookingUiParams = useBookingUIContext();
  const bookingUIProps = React.useMemo(() => {
    return {
      canLeaveChanged: bookingUiParams?.canLeaveChanged,
      bookingDeleted: bookingUiParams?.bookingDeleted,
      editBookingClicked: bookingUiParams?.editBookingClicked,
      queryParams: bookingUiParams?.queryParams,
      setQueryParams: bookingUiParams?.setQueryParams,
    };
  }, [bookingUiParams]);
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration?.bookingYears,
    shallowEqual
  ) as BookingYear;
  const { data: { records: accounts = [] } = {} } =
    useGetAccountsQuery(
      {
        page: 0,
        perPage: 1000,
      },
      {
        skip: !selectedBookingYear?.id,
      }
    ) || {};
  const options = accounts
    ?.filter((item: CoaInterface) => item.number !== "0")
    .map((item: CoaInterface) => {
      return {
        value: item.number,
        label: item.number + (item.description ? " | " + item.description : ""),
      };
    });
  const { data: { records: bookings = [] } = {}, isLoading } =
    useGetBookingsQuery(
      {
        page: bookingUIProps.queryParams?.pageNumber,
        perPage: 0,
        sort: bookingUIProps.queryParams?.sort,
        filter: bookingUIProps.queryParams?.filter,
        bookingYearsNo: selectedBookingYear?.id,
      },
      {
        skip:
          !bookingUIProps.queryParams?.filter?.diaryNo ||
          !selectedBookingYear?.id,
      }
    ) || {};
  const getRowsCallback = React.useCallback(
    (booking: any[], intl: IntlShape, options: any[], isMemorial: boolean) => {
      return getRows(booking, intl, options, isMemorial);
    },
    [data]
  );
  const rows = getRowsCallback(data, intl, options, isMemorial);
  const [gridWidth, setGridWidth] = React.useState<number | undefined>();
  const [columns, setColumns] = React.useState<Column[]>(getColumns(gridWidth));
  const handleColumnResize = (ci: Id, width: number) => {
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
    localStorage.setItem(`columnWidth-${ci.toString()}`, width.toString());
  };

  React.useEffect(() => {
    const handleResize = () => {
      const gridWidth = gridBoxRef.current?.offsetWidth;
      setGridWidth(gridWidth);
      setColumns(getColumns(gridWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  React.useEffect(() => {
    const gridWidth = gridBoxRef.current?.offsetWidth;
    setGridWidth(gridWidth);
    setColumns(getColumns(gridWidth));
  }, []);
  React.useEffect(() => {
    const items = bookings.flatMap(
      (booking: BookingInterface, index: number) => [
        ...(booking.double_entries || []).map((entry: BookingInterface) => {
          return {
            ...entry,
            className:
              index % 2 === 0
                ? ""
                : isMemorial
                ? "bg-gray-400"
                : "row-background",
          };
        }),
        {
          ...booking,
          className:
            index % 2 === 0
              ? ""
              : isMemorial
              ? "bg-gray-400"
              : "row-background",
        },
      ]
    );
    if (selectedBookingYear?.id) {
      setData([...items]);
    }
  }, [bookings, isMemorial, selectedBookingYear?.id]);
  const updateBookingPromise = (booking: BookingInterface) => {
    return new Promise((resolve, reject) => {
      updateBooking({
        ...{
          accountNo: booking.number ?? "",
          amountCredit: extractNumber(booking?.amountCredit),
          amountDebet: extractNumber(booking?.amountDebet),
          bookingHeaderId:
            booking.bookingHeaderId !== "" ? booking.bookingHeaderId : null,
          date: booking.date,
          bookingYearsNo: selectedBookingYear.id,
          description: booking.description,
          projectNumber: "1",
          reconciledWithIdsId: booking.reconciledWithIdsId,
        },
        id: booking.id,
      })
        .unwrap()
        .then((resData) => {
          resolve(resData);
        })
        .catch((err) => {
          if (err.data?.code === 1101) {
            toast.error(
              intl.formatMessage({ id: "BOOKING.CREATED_FAILED_ACCOUNT" })
            );
          } else {
            toast.error(intl.formatMessage({ id: "BOOKING.EDITED_FAILED" }));
          }
          reject(err);
        })
        .finally(() => {});
    });
  };

  const handleChanges = async (changes: CellChange[]) => {
    const updatedRows = changedRows(changes, data);
    for (const booking of updatedRows) {
      await updateBookingPromise(booking);
    }
    setData((prevBookings) => applyChangesToBookings(changes, prevBookings));
  };
  const handleContextMenu = (
    selectedRowIds: Id[],
    selectedColIds: Id[],
    selectionMode: SelectionMode,
    menuOptions: MenuOption[],
    selectedRanges: Array<CellLocation[]>
  ): MenuOption[] => {
    const menus: MenuOption[] = [
      {
        id: "deleteBooking",
        label: intl.formatMessage({ id: "BOOKING.DELETE_ROW" }),
        handler: () => {
          const deletedId: number[] = [];
          selectedRanges.forEach((range) => {
            deletedId.push(
              ...range.map((cell) => parseInt(cell.rowId.toString()))
            );
          });
          if (
            bookingUIProps.bookingDeleted &&
            data[deletedId[0]]?.id &&
            bookingUIProps.queryParams?.filter?.diaryNo
          ) {
            bookingUIProps.bookingDeleted(
              data[deletedId[0]]?.id,
              bookingUIProps.queryParams?.filter?.diaryNo
            );
          }
        },
      },
    ];
    if (userData?.rightClickAutoPercentageOffset > 0 && !isMemorial) {
      menus.push({
        id: "splitAmount",
        label: intl.formatMessage(
          {
            id: "BOOKING.SPLIT_BOOKING_BY_PERCENTAGE",
          },
          {
            rightClickAutoPercentageOffset:
              userData?.rightClickAutoPercentageOffset,
          }
        ),
        handler: () => {
          const itemIds: number[] = [];
          selectedRanges.forEach((range) => {
            itemIds.push(
              ...range.map((cell) => parseInt(cell.rowId.toString()))
            );
          });
          splitBooking({ id: data[itemIds[0]].id ?? 0 })
            .unwrap()
            .then((resData: any) => {})
            .catch((err: any) => {});
        },
      });
    }
    return menus;
  };

  return (
    <div className="col-12 page-body">
      {(isLoadingUpdateBooking || isLoading || isLoadingSplitBooking) && (
        <ProgressBar />
      )}
      <div className="card" ref={gridBoxRef}>
        <BookingFilter />{" "}
        <div className="mb-2">
          <ReactGrid
            rows={rows}
            columns={columns}
            onCellsChanged={handleChanges}
            enableFullWidthHeader={true}
            onColumnResized={handleColumnResize}
            stickyTopRows={1}
            onContextMenu={handleContextMenu}
            customCellTemplates={{
              selectDropdown: new SelectCellTemplate(),
              dateCell: new DateCellTemplate(),
              numberCell: new NumberCellTemplate(),
              sortableHeader: new SortableHeader(),
            }}
          />
        </div>
        <BookingCreateRow onCancel={() => {}} />
      </div>{" "}
    </div>
  );
};

export default BookingGrid;
