import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import BookingForm from "../BookingForm/BookingForm";
import { BookingInterface, BookingObject } from "../BookingModel";
import { toast } from "react-toastify";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import {
  useAddNewBookingMutation,
  useGetDiariesByIdQuery,
} from "../../../app/apiService";
import { BookingYear } from "../../../app/administrationSlice/administrationSlice";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../app/RootReducer";
import moment from "moment";
import { useEffect, useState } from "react";

const BookingCreate = () => {
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration.bookingYears,
    shallowEqual
  ) as BookingYear;
  const { diaryNo } = useParams();
  const [data, setData] = useState<BookingInterface>({
    date: "",
    description: "",
    projectNumber: "",
    accountNo: "",
    amountCredit: "",
    amountDebet: "",
    bookingHeaderId: "",
  });
  const [addNewBooking, { isLoading }] = useAddNewBookingMutation();
  const navigate = useNavigate();
  const booking = new BookingObject({});
  const intl = useIntl();
  const { data: diary } = useGetDiariesByIdQuery(diaryNo);
  const onCancel = () => {
    navigate(-1);
  };
  const onFormSubmit = (
    values: BookingInterface,
    setSubmitting: (status: boolean) => void
  ) => {
    if (!values.diaryNo) {
      toast.warn(intl.formatMessage({ id: "BOOKING.SELECT_DIARY_WARNING" }));
      setSubmitting(false);
      return;
    }
    if (!values.bookingYearsNo) {
      toast.warn(intl.formatMessage({ id: "BOOKING.SELECT_ADMINSTRATION" }));
      setSubmitting(false);
      return;
    }
    if (
      (values?.date &&
        moment(values?.date).format("yyyy-MM-DD") >=
          moment(selectedBookingYear.toDate).format("yyyy-MM-DD")) ||
      (values?.date &&
        moment(values?.date).format("yyyy-MM-DD") <
          moment(selectedBookingYear.fromDate).format("yyyy-MM-DD"))
    ) {
      toast.warn(intl.formatMessage({ id: "BOOKING.DATE_WARNING" }));
      setSubmitting(false);
      return;
    }
    addNewBooking({ ...values })
      .unwrap()
      .then(() => {
        setSubmitting(true);
        onCancel();
        toast.success(intl.formatMessage({ id: "BOOKING.CREATED" }));
      })
      .catch((err: any) => {
        setSubmitting(false);
        if (err.data?.code === 1101) {
          toast.error(
            intl.formatMessage({ id: "BOOKING.CREATED_FAILED_ACCOUNT" })
          );
        } else {
          toast.error(intl.formatMessage({ id: "BOOKING.CREATED_FAILED" }));
        }
      });
  };
  useEffect(() => {
    setData({
      date: moment(selectedBookingYear?.fromDate).format("yyyy-MM-DD"),
      description: "",
      projectNumber: "",
      accountNo: "",
      amountCredit: "",
      amountDebet: "",
      bookingHeaderId: "",
    });
  }, [selectedBookingYear?.fromDate]);
  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">
                {intl.formatMessage({ id: "ASIDE.GL" })}
              </div>
              <h2 className="page-title">
                {intl.formatMessage({ id: "BOOKING.CREATE" })}{" "}
                {intl.formatMessage({ id: "FOR" })} {diary?.description}{" "}
                {!diary?.isMemorial && (
                  <>
                    {" | "} {diary?.offsetAccount}
                  </>
                )}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 page-body">
        <div className="card">
          {isLoading && <ProgressBar />}
          <BookingForm
            data={data}
            onCancel={onCancel}
            formSubmitted={onFormSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default BookingCreate;
