import apiConfig from "../../common/Config";

interface FilterBookingInterface {
  term: string;
  operator?: string;
  field?: string;
  diaryNo?: string | null | number;
}
export interface BookingQueryParamsInterface {
  filter: FilterBookingInterface;
  canLeave: boolean;
  showConfirmModal: boolean;
  sort: string;
  sortBy: string;
  sortOrder: string;
  pageNumber: number;
  perPage: number;
}

export const initialFilter: BookingQueryParamsInterface = {
  filter: {
    term: "",
    diaryNo: null,
  },
  canLeave: true,
  showConfirmModal: false,
  sort: "?sort=-id",
  sortBy: "id",
  sortOrder: "ASC",
  pageNumber: 1,
  perPage: apiConfig.PER_PAGE,
};
