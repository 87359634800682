import { Field, Form, Formik } from "formik";
import React, { FC, useEffect, useRef } from "react";
import { IntlShape, useIntl } from "react-intl";
import * as yup from "yup";
import {
  intlMaxMessage,
  intlRequiredMessage,
} from "../../_panel/helpers/validation";
import { useDynamicValidationSchema } from "../../_panel/helpers/useDynamicValidationSchema";
import { CoaInterface } from "../../features/chartOfAccounts/CoaModel";
import {
  useGetAccountsQuery,
  useGetDiariesQuery,
  useGetUserProfileQuery,
  useGetlastImportDateMutation,
  useUpdateUserProfileMutation,
} from "../../app/apiService";
import FormikSelectComponent from "../../_panel/partials/formik/FormikSelectComponent";
import { DiariesInterface } from "../../features/diaries/DiariesModel";
import { BookingYear } from "../../app/administrationSlice/administrationSlice";
import { RootState } from "../../app/RootReducer";
import { shallowEqual, useSelector } from "react-redux";
import { CompnayDataInterface } from "./BankImportHelper";

const bankImportSchema = (intl: IntlShape) =>
  yup.object().shape({
    companyId: yup
      .number()
      .label(intl.formatMessage({ id: "HOMEPAGE.COMPANY" }))
      .required(intlRequiredMessage(intl)),
    bankCOAAccountNo: yup
      .string()
      .label(intl.formatMessage({ id: "BANKIMPORT.BANK_ACCOUNT_NO" }))
      .required(intlRequiredMessage(intl))
      .max(7, intlMaxMessage(intl)),
    bankCOAAccountNoPositive: yup
      .string()
      .label(intl.formatMessage({ id: "BANKIMPORT.BANK_ACCOUNT_NO_POSITIVE" }))
      .required(intlRequiredMessage(intl))
      .max(7, intlMaxMessage(intl)),
    diaryNo: yup
      .string()
      .label(intl.formatMessage({ id: "BANKIMPORT.BANK_DIARY_NO" }))
      .required(intlRequiredMessage(intl))
      .max(7, intlMaxMessage(intl)),
  });
type Props = {
  setStep: (step: number) => void;
  setIsLoading: (loading: boolean) => void;
  setLastImportDate: (dateTime: string) => void;
  importCompanyData: CompnayDataInterface;
  setImportCompanyData: (data: CompnayDataInterface) => void;
};
const Step1: FC<Props> = ({
  setStep,
  setIsLoading,
  setLastImportDate,
  importCompanyData,
  setImportCompanyData,
}) => {
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration.bookingYears,
    shallowEqual
  ) as BookingYear;
  const [getlastImportDate, { isLoading }] = useGetlastImportDateMutation();
  const [updateUserProfile, { isLoading: isUpdateLoading }] =
    useUpdateUserProfileMutation();
  const intl = useIntl();
  const formRef: React.RefObject<any> = useRef();
  const { data: userConfig } = useGetUserProfileQuery({});
  const { data: { records: diaries = [] } = {} } =
    useGetDiariesQuery({
      page: 0,
      perPage: 1000,
    }) || {};
  const diaryOptions =
    diaries?.map((item: DiariesInterface) => {
      return {
        value: item.number,
        label: item.number + " " + item.description,
      };
    }) ?? [];
  const { data: { records: accountTypes = [] } = {} } =
    useGetAccountsQuery({
      page: 0,
      perPage: 1000,
    }) || {};
  const accountOptions =
    accountTypes
      .map((item: CoaInterface) => {
        return {
          value: item.number,
          label:
            item.number + (item.description ? " | " + item.description : ""),
        };
      })
      .filter((item: any) => item.value !== "0") ?? [];
  const validationSchema = useDynamicValidationSchema(bankImportSchema);
  useEffect(() => {
    formRef?.current?.validateForm();
  }, [validationSchema]);
  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);
  useEffect(() => {
    if (userConfig?.bankCOAAccountNo) {
      formRef?.current?.setFieldValue(
        "bankCOAAccountNo",
        userConfig?.bankCOAAccountNo
      );
    } else {
      if (
        accountOptions?.find(
          (account: any) => account.value?.toString() === "1600"
        )
      ) {
        formRef?.current?.setFieldValue("bankCOAAccountNo", "1600");
      }
    }
    if (userConfig?.bankCOAAccountNoPositive) {
      formRef?.current?.setFieldValue(
        "bankCOAAccountNoPositive",
        userConfig?.bankCOAAccountNoPositive
      );
    } else if (
      accountOptions?.find(
        (account: any) => account.value?.toString() === "0821"
      )
    ) {
      formRef?.current?.setFieldValue("bankCOAAccountNoPositive", "0821");
    }
  }, [accountOptions, userConfig]);

  useEffect(() => {
    if (selectedBookingYear && selectedBookingYear.companyId) {
      formRef.current?.setFieldValue('companyId', selectedBookingYear.companyId);
    }
  }, [selectedBookingYear]);

  return (
    <Formik
      initialValues={{
        companyId: selectedBookingYear.companyId,
        bankCOAAccountNo: importCompanyData.bankCOAAccountNo,
        bankCOAAccountNoPositive: importCompanyData.bankCOAAccountNoPositive,
        diaryNo: importCompanyData.diaryNo,
      }}
      validationSchema={validationSchema}
      innerRef={formRef}
      validateOnBlur={true}
      onSubmit={(values, { setSubmitting }) => {
        setImportCompanyData({
          companyId: selectedBookingYear.companyId.toString(),
          bankCOAAccountNo: values.bankCOAAccountNo,
          bankCOAAccountNoPositive: values.bankCOAAccountNoPositive,
          diaryNo: values.diaryNo,
          diaryId: 1,
        });
        getlastImportDate({
          companyId: values.companyId,
          bankCOAAccountNo: values.bankCOAAccountNo,
          bankCOAAccountNoPositive: values.bankCOAAccountNoPositive,
          diaryNo: values.diaryNo,
        })
          .then((data: any) => {
            setLastImportDate(data?.data?.data ?? "");
            setStep(2);
          })
          .catch(() => {});
        updateUserProfile({
          bankCOAAccountNoPositive: values.bankCOAAccountNoPositive,
        });
      }}
    >
      {({
        isSubmitting,
        errors,
        touched,
        values,
        setTouched,
        setFieldValue,
      }) => (
        <Form>
          <div className="card-body">
            <Field
              required
              component={FormikSelectComponent}
              className="col-12 mb-3"
              name="bankCOAAccountNo"
              options={accountOptions}
              defaultValue={"1600"}
              placeholder={intl.formatMessage(
                { id: "SELECT_PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "BANKIMPORT.BANK_ACCOUNT_NO",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              row
              label={intl.formatMessage({
                id: "BANKIMPORT.BANK_ACCOUNT_NO",
              })}
            />
            <Field
              required
              component={FormikSelectComponent}
              className="col-12 mb-3"
              name="bankCOAAccountNoPositive"
              options={accountOptions}
              defaultValue={"0821"}
              placeholder={intl.formatMessage(
                { id: "SELECT_PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "BANKIMPORT.BANK_ACCOUNT_NO_POSITIVE",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              row
              label={intl.formatMessage({
                id: "BANKIMPORT.BANK_ACCOUNT_NO_POSITIVE",
              })}
            />

            <Field
              required
              component={FormikSelectComponent}
              className="col-12 mb-3"
              name="diaryNo"
              options={diaryOptions}
              placeholder={intl.formatMessage(
                { id: "SELECT_PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "BANKIMPORT.BANK_DIARY_NO",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              row
              label={intl.formatMessage({
                id: "BANKIMPORT.BANK_DIARY_NO",
              })}
            />
          </div>
          <div className="card-footer text-end">
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary"
            >
              {intl.formatMessage({ id: "CONTINUE" })}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Step1;
