import moment from "moment";
import { FC } from "react";
import { useIntl } from "react-intl";
import { BookingYear } from "../../app/administrationSlice/administrationSlice";
import { getEarlyDay } from "../../common/Utils";
type Props = {
  newBookingYears: BookingYear[];
};
const NewBookingYearsTable: FC<Props> = ({ newBookingYears }) => {
  const intl = useIntl();
  return (
    <div className="table-responsive">
      <table className="table table-vcenter card-table table-striped">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: "BANKIMPORT.FROM" })}</th>
            <th>{intl.formatMessage({ id: "BANKIMPORT.TO" })}</th>
          </tr>
        </thead>
        <tbody>
          {newBookingYears.map((day: BookingYear) => {
            return (
              <tr key={day.id}>
                <td className="text-break text-wrap py-1">
                  {moment(day.fromDate).format("yyyy-MM-DD")}
                </td>
                <td className="text-break text-wrap py-1">
                  {" "}
                  {moment(getEarlyDay(day.toDate)).format("yyyy-MM-DD")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default NewBookingYearsTable;
