import React from "react";
import OpeningBalance from "../features/OpeningBalance/GenerateOpeningBalance";

const GenerateBalanceUltimoPage = () => {
  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <OpeningBalance />
        </div>
      </div>
    </>
  );
};

export default GenerateBalanceUltimoPage;
