import { CoaInterface } from "../chartOfAccounts/CoaModel";

export interface BookingInterface {
  id?: number;
  number?: number;
  account?: CoaInterface;
  accountNo?: number | string;
  projectNumber?: string;
  description?: string;
  bookNo?: number | string;
  diaryNo?: number | string;
  bookingYearsNo?: number | string;
  companyNo?: number | string;
  key?: number;
  date?: string | Date;
  amountCredit?: number | string;
  amountDebet?: number | string;
  currency?: string;
  debitCurrency?: number | string;
  creditCurrency?: number | string;
  bookingHeaderId?: number | string;
  double_entries?: BookingInterface[];
  groupId?: number;
  reconciledWithIdsId?: number;
  updatedAt?: string | Date;
  createdAt?: string | Date;
  orginalRow?: any;
  orginalIndex?: number;
  className?: string;
}
export class BookingObject implements BookingInterface {
  id?: number;
  projectNumber?: string;
  description?: string;
  number?: number;
  bookNo?: number | string;
  diaryNo?: number | string;
  accountNo?: number | string;
  bookingYearsNo?: number | string;
  companyNo?: number | string;
  key?: number;
  date?: string | Date;
  amountCredit?: number | string;
  amountDebet?: number | string;
  bookingHeaderId?: number | string;
  double_entries?: BookingInterface[];
  groupId?: number;
  reconciledWithIdsId?: number;
  updatedAt?: string | Date;
  createdAt?: string | Date;
  account?: CoaInterface;
  orginalRow?: any;
  orginalIndex?: number;
  constructor(data: BookingInterface) {
    this.id = data.id;
    this.number = data.number;
    this.projectNumber = data.projectNumber;
    this.description = data.description;
    this.bookNo = data.bookNo;
    this.accountNo = data.accountNo;
    this.diaryNo = data.diaryNo;
    this.account = data.account;
    this.companyNo = data.companyNo;
    this.bookingYearsNo = data.bookingYearsNo;
    this.key = data.key;
    this.date = data.date;
    this.amountCredit = data.amountCredit;
    this.amountDebet = data.amountDebet;
    this.bookingHeaderId = data.bookingHeaderId;
    this.groupId = data.groupId;
    this.reconciledWithIdsId = data.reconciledWithIdsId;
    this.updatedAt = data.updatedAt;
    this.createdAt = data.createdAt;
  }

  data(): BookingInterface {
    return {
      id: this.id,
      number: this.number,
      projectNumber: this.projectNumber,
      description: this.description,
      bookNo: this.bookNo,
      diaryNo: this.diaryNo,
      accountNo: this.accountNo,
      companyNo: this.companyNo,
      bookingYearsNo: this.bookingYearsNo,
      account: this.account,
      key: this.key,
      date: this.date,
      amountCredit: this.amountCredit,
      amountDebet: this.amountDebet,
      bookingHeaderId: this.bookingHeaderId,
      groupId: this.groupId,
      reconciledWithIdsId: this.reconciledWithIdsId,
      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
    };
  }
}
