import React from "react";
import { FieldProps } from "formik";

interface CustomInputProps extends FieldProps {
  placeholder: string;
  label: string;
  required: boolean;
  type: string;
  className: string;
  labelClassName: string;
}
const FormikCheckboxComponent: React.FC<CustomInputProps> = ({
  field,
  form: { touched, errors },
  placeholder,
  label,
  required,
  className,
  labelClassName,
  type,
  ...props
}) => (
  <div className={`${className ?? "row mb-3"} align-items-center`}>
    <label
      className={` ${labelClassName ?? "col-3 col-form-label "} ${
        required ? " required" : ""
      }`}
    >
      {label}
    </label>
    <div className="col">
      <input
        type={"checkbox"}
        {...field}
        {...props}
        checked={field.value ?? false}
        className={`form-check-input`}
        placeholder={placeholder}
      />
      {touched[field.name] && errors[field.name] && (
        <div className="invalid-feedback">{errors[field.name]?.toString()}</div>
      )}
    </div>
  </div>
);

export default FormikCheckboxComponent;
