import React, { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useBookingUIContext } from "../BookingUiContext";
import { useBlocker, useParams } from "react-router-dom";

interface DialogBoxProps {
  show: boolean;
  onHide: () => void;
  onProgress: () => void;
}

const BookingWarningModal: React.FC<DialogBoxProps> = ({
  show,
  onHide,
  onProgress,
}) => {
  const intl = useIntl();

  return (
    <Modal show={show} onHide={onHide} size="sm">
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon mb-2 text-danger icon-lg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 9v2m0 4v.01" />
          <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
        </svg>
        <h3>{intl.formatMessage({ id: "WARNING" })}</h3>
        <div className="text-secondary">
          {intl.formatMessage({ id: "PROCEED_WARNING" })}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <div className="w-100">
          <div className="row">
            <div className="col">
              <a onClick={onHide} className="btn w-100" data-bs-dismiss="modal">
                {intl.formatMessage({ id: "YES" })}
              </a>
            </div>
            <div className="col">
              <button
                onClick={onProgress}
                className="btn btn-danger w-100"
                data-bs-dismiss="modal"
              >
                {intl.formatMessage({
                  id: "NO",
                  defaultMessage: "No",
                })}
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default BookingWarningModal;
