import React, { createContext, useContext, useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { toAbsoluteUrl } from '../../helpers'
import { ThemeModeComponent } from './ThemeMode'

export type ThemeModeType = 'dark' | 'light'
const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'

type ThemeModeContextType = {
  mode: ThemeModeType
  updateMode: (_mode: ThemeModeType) => void
}

const themeModeSwitchHelper = (_mode: ThemeModeType) => {
  // change background image url
  const mode = _mode
  const imageUrl = '/media/patterns/header-bg' + (mode === 'light' ? '.jpg' : '-dark.png')
  document.body.style.backgroundImage = `url("${toAbsoluteUrl(imageUrl)}")`
}

const themeModeLSKey = 'theme_mode'

const getThemeModeFromLocalStorage = (lsKey: string): ThemeModeType => {
  if (!localStorage) {
    return 'light'
  }

  const data = localStorage.getItem(lsKey)

  if (data === 'dark' || data === 'light') {
    return data
  } else {
    return 'light'
  }
}

const defaultThemeMode: ThemeModeContextType = {
  mode: getThemeModeFromLocalStorage(themeModeLSKey),
  updateMode: (_mode: ThemeModeType) => {},
}

const ThemeModeContext = createContext<ThemeModeContextType>({
  mode: defaultThemeMode.mode,
  updateMode: (_mode: ThemeModeType) => {},
})

const useThemeMode = () => useContext(ThemeModeContext)

const ThemeModeProvider = ({ children }: { children: React.ReactNode }) => {
  const [mode, setMode] = useState<ThemeModeType>(defaultThemeMode.mode)


  const updateMode = (_mode: ThemeModeType) => {
    setMode(_mode)
    if (localStorage) {
      localStorage.setItem(themeModeLSKey, _mode)
    }

    document.documentElement.setAttribute('data-theme', _mode)
    ThemeModeComponent.init()
  }

  useEffect(() => {
    // Update the web page with a focus on the page
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        updateMode(getThemeModeFromLocalStorage(themeModeLSKey))
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return (
    <ThemeModeContext.Provider value={{ mode, updateMode }}>{children}</ThemeModeContext.Provider>
  )
}

export { ThemeModeProvider, useThemeMode, systemMode, themeModeSwitchHelper }
