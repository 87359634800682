import React, { FC } from "react";
import {
  CompnayDataInterface,
  UsedBookingYearClosing,
} from "./BankImportHelper";
import { useIntl } from "react-intl";
import ReportRow from "./ReportRow";
type Props = {
  usedBookingYearClosing: UsedBookingYearClosing[];
  importCompanyData: CompnayDataInterface;
  setIsLoading: (loading: boolean) => void;
};
const ReportClosingReport: FC<Props> = ({
  usedBookingYearClosing,
  importCompanyData,
  setIsLoading,
}) => {
  const intl = useIntl();
  return (
    <div className="table-responsive">
      <table className="table table-vcenter card-table table-striped">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: "BANKIMPORT.FROM" })}</th>
            <th>{intl.formatMessage({ id: "BANKIMPORT.TO" })}</th>
            <th>{intl.formatMessage({ id: "BANKIMPORT.IMPORTED_COUNT" })}</th>
            <th>{intl.formatMessage({ id: "BANKIMPORT.CLOSING_BALANCE" })}</th>
            <th>{intl.formatMessage({ id: "BANKIMPORT.CURRENT_BALANCE" })}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {usedBookingYearClosing.map((bookingYears) => {
            return (
              <ReportRow
                data={bookingYears}
                importCompanyData={importCompanyData}
                setIsLoading={setIsLoading}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ReportClosingReport;
