import { useIntl } from "react-intl";
import Select from "react-select";

import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetBookingsQuery,
  useGetDiariesQuery,
} from "../../../app/apiService";
import { DiariesInterface } from "../../diaries/DiariesModel";
import { useBookingUIContext } from "../BookingUiContext";
import { BookingYear } from "../../../app/administrationSlice/administrationSlice";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../app/RootReducer";
import { formatNumber } from "../../../common/Utils";
interface OptionsInterface {
  value?: number;
  label?: string;
}
const BookingFilter = () => {
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration.bookingYears,
    shallowEqual
  ) as BookingYear;
  const navigate = useNavigate();
  const { diaryNo } = useParams();
  const [selectedOption, setSelectedOption] = useState<any>("");
  const bookingUiParams = useBookingUIContext();
  const bookingUIProps = useMemo(() => {
    return {
      queryParams: bookingUiParams?.queryParams,
      setQueryParams: bookingUiParams?.setQueryParams,
    };
  }, [bookingUiParams]);
  const { data: { records: diaries = [] } = {} } =
    useGetDiariesQuery(
      {
        page: 0,
        perPage: 1000,
      },
      {
        skip: !selectedBookingYear?.id,
      }
    ) || {};
  const diaryOptions =
    diaries?.map((item: DiariesInterface) => {
      return {
        value: item.number,
        label: item.number + " " + item.description,
      };
    }) ?? [];
  const { data: { totalAmount = 0, totalCount = 0 } = {} } =
    useGetBookingsQuery(
      {
        page: bookingUIProps.queryParams?.pageNumber,
        perPage: 0,
        sort: bookingUIProps.queryParams?.sort,
        filter: bookingUIProps.queryParams?.filter,
        bookingYearsNo: selectedBookingYear?.id,
      },
      {
        skip:
          !bookingUIProps.queryParams?.filter?.diaryNo ||
          !selectedBookingYear?.id,
      }
    ) || {};
  useEffect(() => {
    let diaryItem = null;
    if (diaryNo) {
      diaryItem = diaries?.find(
        (item: DiariesInterface) => item.number === diaryNo
      );

      setSelectedOption({
        value: diaryItem?.number,
        label: diaryItem?.description,
      });
      if (bookingUIProps?.setQueryParams && bookingUIProps?.queryParams) {
        bookingUIProps?.setQueryParams({
          ...bookingUIProps?.queryParams,
          pageNumber: 1,
          filter: {
            ...bookingUIProps?.queryParams.filter,
            diaryNo: diaryItem?.number,
          },
        });
      }
    } else {
      if (bookingUIProps?.queryParams?.filter?.diaryNo) {
        diaryItem = diaries?.find(
          (item: DiariesInterface) =>
            bookingUIProps?.queryParams?.filter?.diaryNo &&
            item.number === bookingUIProps?.queryParams?.filter?.diaryNo
        );
      } else {
        diaryItem = diaries[0];
      }
      if (diaryItem?.number)
        navigate(`/general-ledger/booking/${diaryItem?.number}`);
    }
  }, [diaryNo, diaries]);
  const diaryItem = diaries?.find(
    (item: DiariesInterface) => item.number === selectedOption?.value
  );

  const intl = useIntl();
  const diaryNoChanged = (value: any) => {
    navigate(`/general-ledger/booking/${value.value}`);
  };
  return (
    <div className="card-body border-bottom py-3 sticky-header">
      <div className="d-flex">
        <div className="col-md-10">
          <div className="d-flex">
            <div className="text-secondary">
              {intl.formatMessage({ id: "BOOKING.DIARY_NO" })}:
              <div className="mx-2 d-inline-block select-control-sm mw-250">
                <Select
                  className={`select-control`}
                  name="diaryNo"
                  value={diaryOptions?.find(
                    (option: any) => option.value === selectedOption.value
                  )}
                  styles={{
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  onChange={diaryNoChanged}
                  options={diaryOptions}
                  placeholder={intl.formatMessage({
                    id: "ALL",
                  })}
                />
              </div>
            </div>
            <div className="text-dark d-flex ms-2 align-items-center fs-4 fw-bold justify-content-end">
              {intl.formatMessage({ id: "BOOKING.BLANCE_ACCOUNT" })}

              <div
                className={`mx-2 fs-4 d-inline-block badge  ${
                  totalAmount >= 0
                    ? "bg-green text-green-fg"
                    : "bg-red text-red-fg"
                }`}
              >
                {formatNumber(totalAmount)}
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div className="text-secondary mt-2 me-2">
              {intl.formatMessage({ id: "DIARIES.OFFSET_ACCOUNT" })}:
              <div className="mx-2 d-inline-block">
                {diaryItem?.offsetAccount}
              </div>
            </div>
            <div className=" text-secondary mt-2 me-4">
              {intl.formatMessage({ id: "BOOKING.DESCRIPTION" })}:
              <div className="mx-2 d-inline-block">
                {diaryItem?.description}
              </div>
            </div>
            <div className=" text-secondary mt-2">
              {intl.formatMessage({ id: "DIARIES.IS_MEMORIAL" })}:
              <div className="mx-2 d-inline-block">
                <input
                  className="form-check-input"
                  onChange={() => null}
                  checked={diaryItem?.isMemorial ?? false}
                  name="isBalance"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-md-2">
          <div className="ms-auto text-secondary">
            Search:
            <div className="ms-2 d-inline-block">
              <input
                type="text"
                className="form-control form-control-sm"
                aria-label="Search invoice"
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default BookingFilter;
