import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import { toast } from "react-toastify";
import { useDeleteAccountsMutation } from "../../../app/apiService";
interface Props {
  id: string | null;
  show: boolean;
  onHide: () => void;
}
const CoaDelete: FC<Props> = ({ id, show, onHide }) => {
  const [deleteAccount, { isLoading }] = useDeleteAccountsMutation();
  const intl = useIntl();
  const onDeleteClicked = () => {
    deleteAccount(id)
      .unwrap()
      .then(() => {
        toast.success(intl.formatMessage({ id: "COA.DELETED" }));
        onHide();
      })
      .catch((err: any) => {
        if (err.data?.code === 1) {
          toast.error(intl.formatMessage({ id: "COA.NUMBER_IN_USE" }));
        } else {
          toast.error(intl.formatMessage({ id: "COA.DELETED_FAILED" }));
        }
      });
  };
  return (
    <Modal show={show} onHide={onHide} size="sm">
      {isLoading && <ProgressBar />}

      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon mb-2 text-danger icon-lg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 9v2m0 4v.01" />
          <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
        </svg>
        <h3>{intl.formatMessage({ id: "ARE_YOU_SURE" })}</h3>
        <div className="text-secondary">
          {intl.formatMessage({ id: "DELETE_WARNING" })}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <div className="w-100">
          <div className="row">
            <div className="col">
              <a onClick={onHide} className="btn w-100" data-bs-dismiss="modal">
                {intl.formatMessage({ id: "CANCEL" })}
              </a>
            </div>
            <div className="col">
              <a
                onClick={onDeleteClicked}
                className="btn btn-danger w-100"
                data-bs-dismiss="modal"
              >
                {intl.formatMessage({ id: "DELETE" })}
              </a>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CoaDelete;
