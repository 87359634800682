import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  getCharFromKey,
  inNumericKey,
  isAllowedOnNumberTypingKey,
  isCharAllowedOnNumberInput,
  isNavigationKey,
  isNumpadNumericKey,
  keyCodes,
} from "@silevis/reactgrid";
import React from "react";
import { extractNumber, formatNumber } from "../../../../common/Utils";
export interface NumberCell extends Cell {
  type: "numberCell";
  value?: Number;
  format?: Intl.NumberFormat;
  nanToZero?: boolean;
  hideZero?: boolean;
  validator?: (value: number) => boolean;
  errorMessage?: string;
  textStyle?: string;
}
export class NumberCellTemplate implements CellTemplate<NumberCell> {
  private wasEscKeyPressed = false;

  getCompatibleCell(
    uncertainCell: Uncertain<NumberCell>
  ): Compatible<NumberCell> {
    let value: number;
    try {
      value = getCellProperty(uncertainCell, "value", "number");
    } catch (error) {
      value = NaN;
    }
    const numberFormat =
      uncertainCell.format || new Intl.NumberFormat(window.navigator.language);
    const displayValue =
      uncertainCell.nanToZero && Number.isNaN(value) ? 0 : value;
    const text =
      Number.isNaN(displayValue) ||
      (uncertainCell.hideZero && displayValue === 0)
        ? ""
        : numberFormat.format(displayValue);
    return { ...uncertainCell, value: displayValue, text };
  }
  handleKeyDown(
    cell: Compatible<NumberCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
    key: string
  ): { cell: Compatible<NumberCell>; enableEditMode: boolean } {
    if (isNumpadNumericKey(keyCode)) keyCode -= 48;
    const char = getCharFromKey(key);
    if (!ctrl && isCharAllowedOnNumberInput(char)) {
      const value = Number(char);

      if (Number.isNaN(value) && isCharAllowedOnNumberInput(char))
        return {
          cell: { ...this.getCompatibleCell({ ...cell, value }), text: char },
          enableEditMode: true,
        };
      return {
        cell: this.getCompatibleCell({ ...cell, value }),
        enableEditMode: true,
      };
    }

    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }
  update(
    cell: Compatible<NumberCell>,
    cellToMerge: UncertainCompatible<NumberCell>
  ): Compatible<NumberCell> {
    return this.getCompatibleCell({
      ...cell,
      value: cellToMerge.value,
    });
  }
  handleCompositionEnd(
    cell: Compatible<NumberCell>,
    eventData: any
  ): { cell: Compatible<NumberCell>; enableEditMode: boolean } {
    return { cell: { ...cell, text: eventData }, enableEditMode: true };
  }

  getClassName(cell: Compatible<NumberCell>, isInEditMode: boolean): string {
    return cell.className ? cell.className : "";
  }
  getAmountClass = (cell: Compatible<NumberCell>, amount: number) => {
    const classs = "mx-1 fs-4 d-inline-block";
    if (cell.textStyle) {
      return `${classs} ${cell.textStyle}`;
    }
    if (amount === 0) {
      return "mx-2 fs-4 d-inline-block";
    }

    return `mx-2 fs-4 d-inline-block ${
      amount >= 0 ? " text-green" : " text-red"
    }`;
  };
  extractFormatedNumber = (number: number | string | undefined) => {
    try {
      if (!number) return 0;
      const value = parseFloat(number.toString().replace(",", ".")) || 0;
      const exctarctedNumber = Number(value)
        .toFixed(2)
        .replace(/\.?0+$/, "");
      return Number(exctarctedNumber);
    } catch (_) {
      return 0;
    }
  };
  render(
    cell: Compatible<NumberCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<NumberCell>, commit: boolean) => void
  ): React.ReactNode {
    if (!isInEditMode) {
      const textToDisplay =
        Number.isNaN(cell.value) || cell.value === 0
          ? ""
          : formatNumber(cell.value);
      return (
        <div className={this.getAmountClass(cell, cell?.value ?? 0)}>
          {cell.textStyle ? (
            <>{textToDisplay ? parseFloat(textToDisplay).toFixed(0) : ""}</>
          ) : (
            <strong>{textToDisplay}</strong>
          )}
        </div>
      );
    }

    return (
      <input
        inputMode="decimal"
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(
              input.value.length,
              input.value.length,
              "none"
            );
          }
        }}
        defaultValue={
          Number.isNaN(cell.value) || cell.value === 0
            ? ""
            : this.extractFormatedNumber(cell.value)
        }
        onChange={(e) =>
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              value: extractNumber(e.currentTarget.value),
            }),
            false
          )
        }
        onBlur={(e) => {
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              value: this.extractFormatedNumber(e.currentTarget.value),
            }),
            !this.wasEscKeyPressed
          );
          this.wasEscKeyPressed = false;
        }}
        onKeyDown={(e) => {
          if (
            inNumericKey(e.keyCode) ||
            isNavigationKey(e.keyCode) ||
            isAllowedOnNumberTypingKey(e.keyCode) ||
            ((e.ctrlKey || e.metaKey) && e.keyCode === keyCodes.KEY_A)
          )
            e.stopPropagation();
          if (
            !inNumericKey(e.keyCode) &&
            !isNavigationKey(e.keyCode) &&
            !isCharAllowedOnNumberInput(getCharFromKey(e.key))
          )
            e.preventDefault();
          if (e.keyCode === keyCodes.ESCAPE) this.wasEscKeyPressed = true;
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
      />
    );
  }
}
