import React, { FC } from "react";
import { useIntl } from "react-intl";
import { MissingDay } from "./BankImportHelper";
import moment from "moment";
import { getEarlyDay } from "../../common/Utils";
type Props = {
  missingDays: MissingDay[];
};
const MissingDaysTable: FC<Props> = ({ missingDays }) => {
  const intl = useIntl();
  return (
    <div className="table-responsive">
      <table className="table table-vcenter card-table table-striped">
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: "BANKIMPORT.FROM" })}</th>
            <th>{intl.formatMessage({ id: "BANKIMPORT.TO" })}</th>
            <th>{intl.formatMessage({ id: "BANKIMPORT.OPENING_BALANCE" })}</th>
          </tr>
        </thead>
        <tbody>
          {missingDays.map((day) => {
            return (
              <tr key={day.id}>
                <td className="text-break text-wrap py-1">
                  {day.from ? moment(day.from).format("yyyy-MM-DD") : "-"}
                </td>
                <td className="text-break text-wrap py-1">
                  {" "}
                  {moment(getEarlyDay(day.to)).format("yyyy-MM-DD")}
                </td>
                <td className="text-break text-wrap py-1">
                  {" "}
                  {day.amount?.toFixed(2)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MissingDaysTable;
