import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  SetStateAction,
} from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter, BookingQueryParamsInterface } from "./BookingUiHelper";
import apiConfig from "../../common/Config";
export interface BookingContextInterface {
  canLeaveChanged: (canLeave: boolean) => void;
  bookingDeleted: (id: string | number, diaryNo: string | number) => void;
  newBookingClicked: (diaryNo: string | number) => void;
  editBookingClicked: (id: string | number, diaryNo: string | number) => void;
  queryParams?: BookingQueryParamsInterface;
  setQueryParams?: (
    nextQueryParams: SetStateAction<BookingQueryParamsInterface>
  ) => void;
  setQueryParamsBase?: (nextQueryParams: BookingQueryParamsInterface) => void;
  fetchBooking: boolean;
}

interface BookingUiContextInterface {
  BookingUIEvents: BookingContextInterface;
  children: React.ReactNode;
}
const BookingUIContext = createContext<BookingContextInterface | null>(null);
const BookingUIConsumer = BookingUIContext.Consumer;

function useBookingUIContext() {
  return useContext<BookingContextInterface | null>(BookingUIContext);
}

const BookingUIProvider: React.FC<BookingUiContextInterface> = ({
  BookingUIEvents,
  children,
}) => {
  const [queryParams, setQueryParamsBase] =
    useState<BookingQueryParamsInterface>({
      ...initialFilter,
      perPage: parseInt(
        localStorage.getItem("BOOKING_PERPAGE") || apiConfig.PER_PAGE.toString()
      ),
    });
  const setQueryParams = useCallback((nextQueryParams: any) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value: BookingContextInterface = {
    canLeaveChanged: BookingUIEvents.canLeaveChanged,
    bookingDeleted: BookingUIEvents.bookingDeleted,
    fetchBooking: BookingUIEvents.fetchBooking,
    newBookingClicked: BookingUIEvents.newBookingClicked,
    editBookingClicked: BookingUIEvents.editBookingClicked,
    queryParams: queryParams,
    setQueryParams: setQueryParams,
    setQueryParamsBase: setQueryParamsBase,
  };

  return (
    <BookingUIContext.Provider value={value}>
      {children}
    </BookingUIContext.Provider>
  );
};
export { BookingUIProvider, BookingUIConsumer, useBookingUIContext };
