import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

export function useDynamicValidationSchema(
  initialValidationSchema: any | (() => any)
) {
  const intl = useIntl();
  const [validationSchema, setValidationSchema] = useState(
    initialValidationSchema(intl)
  );

  useEffect(() => {
    const newValidationSchema = initialValidationSchema(intl);
    setValidationSchema(newValidationSchema);
  }, [intl.locale, initialValidationSchema]);

  return validationSchema;
}
