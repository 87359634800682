import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import UserService from "../app/oauth/UserService";

type props = {
  children: React.ReactNode;
};

export const ProtectedRoute: FC<props> = ({ children }) => {
  if (!UserService.isLoggedIn()) {
    UserService.doLogin();
  }
  return UserService.isLoggedIn() ? <>{children}</> : <Navigate to="/" />;
};
