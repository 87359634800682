import React from "react";

const SplashScreen = () => {
  return (
    <div className="d-flex justify-content-center align-items-center spinner">
      <div
        className="spinner-border text-success fs-1"
        style={{ width: "4rem", height: "4rem", borderWidth: "5px" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default SplashScreen;
