import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  SetStateAction,
} from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter, DiariesQueryParamsInterface } from "./DiariesUiHelper";
export interface DiariesContextInterface {
  diariesDeletedClicked: (id: number | string | undefined) => void;
  newDiariesClicked: () => void;
  editDiariesClicked: (id: number | string | undefined) => void;
  queryParams?: DiariesQueryParamsInterface;
  setQueryParams?: (
    nextQueryParams: SetStateAction<DiariesQueryParamsInterface>
  ) => void;
  setQueryParamsBase?: (nextQueryParams: DiariesQueryParamsInterface) => void;
  fetchDiaries: boolean;
}

interface DiariesUiContextInterface {
  DiariesUIEvents: DiariesContextInterface;
  children: React.ReactNode;
}
const DiariesUIContext = createContext<DiariesContextInterface | null>(null);
const DiariesUIConsumer = DiariesUIContext.Consumer;

function useDiariesUIContext() {
  return useContext<DiariesContextInterface | null>(DiariesUIContext);
}

const DiariesUIProvider: React.FC<DiariesUiContextInterface> = ({
  DiariesUIEvents,
  children,
}) => {
  const [queryParams, setQueryParamsBase] =
    useState<DiariesQueryParamsInterface>(initialFilter);
  const setQueryParams = useCallback((nextQueryParams: any) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value: DiariesContextInterface = {
    diariesDeletedClicked: DiariesUIEvents.diariesDeletedClicked,
    fetchDiaries: DiariesUIEvents.fetchDiaries,
    newDiariesClicked: DiariesUIEvents.newDiariesClicked,
    editDiariesClicked: DiariesUIEvents.editDiariesClicked,
    queryParams: queryParams,
    setQueryParams: setQueryParams,
    setQueryParamsBase: setQueryParamsBase,
  };

  return (
    <DiariesUIContext.Provider value={value}>
      {children}
    </DiariesUIContext.Provider>
  );
};
export { DiariesUIProvider, DiariesUIConsumer, useDiariesUIContext };
