import { Field, Form, Formik, useFormik } from "formik";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import * as yup from "yup";
import { useDynamicValidationSchema } from "../../../_panel/helpers/useDynamicValidationSchema";
import { intlRequiredMessage } from "../../../_panel/helpers/validation";
import FormikSelectComponent from "../../../_panel/partials/formik/FormikSelectComponent";
import { AvailableBookingYears, BookingYears } from "./type";
interface optionType {
  value: number;
  label: string;
}
interface Props {
  show: boolean;
  onHide: () => void;
  availableBookingYears: AvailableBookingYears[];
  selectedCompany: number;
  companiesOptions: optionType[];
  onComapnySelected: (id: number) => void;
}
const reportingYearSchema = (intl: IntlShape) =>
  yup.object().shape({
    companyId: yup
      .number()
      .label(intl.formatMessage({ id: "HOMEPAGE.COMPANY" }))
      .required(intlRequiredMessage(intl)),
    bookingYearsId: yup
      .number()
      .label(intl.formatMessage({ id: "HOMEPAGE.COMPANY" }))
      .nullable(),
  });
const getInitialCompany = (
  availableBookingYears: AvailableBookingYears[],
  selectedCompany: number
) => {
  if (availableBookingYears.length === 0) {
    return -1;
  }
  if (availableBookingYears.find((c) => c.id === selectedCompany)) {
    return selectedCompany;
  }
  return availableBookingYears[0].id;
};
const CompnayChooser: FC<Props> = ({
  show,
  onHide,
  onComapnySelected,
  availableBookingYears,
  selectedCompany,
  companiesOptions,
}) => {
  const formRef: React.RefObject<any> = useRef();
  const intl = useIntl();
  const validationSchema = useDynamicValidationSchema(reportingYearSchema);
  const availableCompaniesOptions = availableBookingYears.map(
    (company: any) => {
      return {
        value: company.id,
        label: company.name,
      };
    }
  );
  useEffect(() => {
    formRef?.current?.validateForm();
  }, [validationSchema]);

  const initialCompany = getInitialCompany(
    availableBookingYears,
    selectedCompany
  );
  const selectedBookingYears = [
    ...(availableBookingYears
      ?.find((c) => c.id === initialCompany)
      ?.bookingYears.map((year: BookingYears) => {
        return {
          value: year.id,
          label: year.fromDate + " - " + year.toDate,
        };
      }) ?? []),
  ];
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({
            id: "HOMEPAGE.SELECT_COMPANY_TO_COPY_ACCOUNTS_AND_DIARIES",
          })}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          companyId: initialCompany,
          bookingYearsId:
            selectedBookingYears[selectedBookingYears.length - 1]?.value ??
            null,
        }}
        validationSchema={validationSchema}
        innerRef={formRef}
        validateOnBlur={true}
        onSubmit={(values, { setSubmitting }) => {
          onComapnySelected(values.bookingYearsId);
          onHide();
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          errors,
          touched,
          values,
          setTouched,
          setFieldValue,
        }) => {
          const bookingYears = [
            ...(availableBookingYears
              ?.find((c) => c.id === values?.companyId)
              ?.bookingYears.map((year: BookingYears) => {
                return {
                  value: year.id,
                  label: year.fromDate + " - " + year.toDate,
                };
              }) ?? []),
          ];

          return (
            <Form>
              <Modal.Body>
                <p>
                  <FormattedMessage
                    id="HOMEPAGE.NO_ACCOUNTS_AND_DIARIY_FOR_COMPANY"
                    values={{
                      company: (
                        <strong>
                          {companiesOptions?.find(
                            (company: any) => company.value === selectedCompany
                          )?.label ?? ""}
                        </strong>
                      ),
                    }}
                  />
                </p>
                <Field
                  name="companyId"
                  className="col-12 mb-3"
                  labelClassName="form-label"
                  component={FormikSelectComponent}
                  dataChanged={(companyId: number) => {
                    const bookingYears = [
                      ...(availableBookingYears
                        ?.find((c) => c.id === companyId)
                        ?.bookingYears.map((year: BookingYears) => {
                          return {
                            value: year.id,
                            label: year.fromDate + " - " + year.toDate,
                          };
                        }) ?? []),
                    ];
                    setFieldValue(
                      "bookingYearsId",
                      bookingYears[bookingYears.length - 1]?.value ?? null
                    );
                  }}
                  row
                  placeholder={intl.formatMessage({ id: "HOMEPAGE.COMPANY" })}
                  label={intl.formatMessage({ id: "HOMEPAGE.COMPANY" })}
                  required
                  options={[
                    {
                      value: -1,
                      label: intl.formatMessage({ id: "NONE" }),
                    },
                    ...availableCompaniesOptions,
                  ]}
                />
                {values.companyId !== -1 && (
                  <Field
                    name="bookingYearsId"
                    className="col-12 mb-3"
                    labelClassName="form-label"
                    component={FormikSelectComponent}
                    row
                    placeholder={intl.formatMessage({ id: "HOMEPAGE.YEAR" })}
                    label={intl.formatMessage({ id: "HOMEPAGE.YEAR" })}
                    required
                    options={bookingYears}
                  />
                )}
              </Modal.Body>
              <Modal.Footer className="justify-content-center">
                <div className="w-100">
                  <div className="row">
                    <div className="col">
                      <a
                        onClick={onHide}
                        className="btn w-100"
                        data-bs-dismiss="modal"
                      >
                        {intl.formatMessage({ id: "CANCEL" })}
                      </a>
                    </div>
                    <div className="col">
                      <button
                        disabled={isSubmitting}
                        className="btn btn-primary w-100"
                        data-bs-dismiss="modal"
                        type="submit"
                      >
                        {intl.formatMessage({ id: "SELECT" })}
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CompnayChooser;
