import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  SetStateAction,
} from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter, CoaQueryParamsInterface } from "./coaUiHelper";
export interface CoaContextInterface {
  deleteCoaClicked: (id: number | undefined) => void;
  newCoaClicked: () => void;
  editCoaClicked: (id: number | undefined) => void;
  queryParams?: CoaQueryParamsInterface;
  setQueryParams?: (
    nextQueryParams: SetStateAction<CoaQueryParamsInterface>
  ) => void;
  setQueryParamsBase?: (nextQueryParams: CoaQueryParamsInterface) => void;
  fetchCoa: boolean;
}

interface CoaUiContextInterface {
  CoaUIEvents: CoaContextInterface;
  children: React.ReactNode;
}
const CoaUIContext = createContext<CoaContextInterface | null>(null);
const CoaUIConsumer = CoaUIContext.Consumer;

function useCoaUIContext() {
  return useContext<CoaContextInterface | null>(CoaUIContext);
}

const CoaUIProvider: React.FC<CoaUiContextInterface> = ({
  CoaUIEvents,
  children,
}) => {
  const [queryParams, setQueryParamsBase] =
    useState<CoaQueryParamsInterface>(initialFilter);
  const setQueryParams = useCallback((nextQueryParams: any) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value: CoaContextInterface = {
    deleteCoaClicked: CoaUIEvents.deleteCoaClicked,
    fetchCoa: CoaUIEvents.fetchCoa,
    newCoaClicked: CoaUIEvents.newCoaClicked,
    editCoaClicked: CoaUIEvents.editCoaClicked,
    queryParams: queryParams,
    setQueryParams: setQueryParams,
    setQueryParamsBase: setQueryParamsBase,
  };

  return (
    <CoaUIContext.Provider value={value}>{children}</CoaUIContext.Provider>
  );
};
export { CoaUIProvider, CoaUIConsumer, useCoaUIContext };
