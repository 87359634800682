import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useIntl } from "react-intl";
import * as yup from "yup";
import { useAddNewMultiEntryBookingMutation } from "../../app/apiService";
import { toast } from "react-toastify";
import * as XLSX from 'xlsx';
import { Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/RootReducer";
import { sortBy } from 'lodash';
import moment from 'moment';
import { setCurrencyRate } from '../../app/RootReducer';

const ImportJournalEntries: React.FC = () => {
  const intl = useIntl();
  const [addNewMultiEntryBooking] = useAddNewMultiEntryBookingMutation();
  const [isImporting, setIsImporting] = useState(false);
  // Remove state declaration and make it a regular variable
  let importErrors: Array<{ entry: any, error: string }> = [];

  const { bookingYears } = useSelector((state: RootState) => state.root.administration);
  const companyNo = bookingYears?.companyId ?? 0;

  // Add this before the handleXLSXFileUpload function
  const currencyRateCache = useSelector((state: RootState) => state.root.currencyRateCache);
  const dispatch = useDispatch();

  const isExcelFile = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const allowedExtensions = ['.xlsx', '.xls'];
      const fileName = file.name.toLowerCase();
      resolve(allowedExtensions.some(ext => fileName.endsWith(ext)));
      // const reader = new FileReader();
      // reader.onloadend = (e) => {
      //   if (e.target?.result) {
      //     const arr = new Uint8Array(e.target.result as ArrayBuffer).subarray(0, 4);
      //     const header = arr.reduce((acc, byte) => acc + byte.toString(16), '');
      //     console.log('File header:', header); // Add this line for debugging
      //     // Expanded list of Excel file signatures
      //     const excelSignatures = [
      //       'd0cf11e0', // Legacy Excel formats
      //       '504b0304', // Modern Excel formats (XLSX)
      //       '504b0506', 
      //       '504b0708',
      //       '0908100000060500', // Another possible XLSX signature
      //     ];
      //     const isExcel = excelSignatures.some(sig => header.startsWith(sig));
      //     console.log('Is Excel file:', isExcel); // Add this line for debugging
      //     resolve(isExcel);
      //   } else {
      //     console.log('No file data found'); // Add this line for debugging
      //     resolve(false);
      //   }
      // };
      // reader.readAsArrayBuffer(file);
    });
  };

  const validationSchema = yup.object().shape({
    file: yup
      .mixed()
      .required(intl.formatMessage({ id: "IMPORT.FILE_REQUIRED" }))
      .test("fileFormat", intl.formatMessage({ id: "IMPORT.INVALID_FILE_TYPE" }), async (value) => {
        if (!value) return false;
        return await isExcelFile(value as File);
      }),
  });

  interface JournalEntry {
    diaryNo: string;
    companyNo: string;
    bookingYearsNo: string;
    date: string;
    bookingHeaderId: string;
    description: string;
    projectNumber: string;
    accountNo: string;
    debitAmount: number;
    creditAmount: number;
    currency: string;
    EuroPerForeignCurrency: number;
    debitCurrency: number;
    creditCurrency: number;
  }

  const requiredHeaders = [
    'diaryNo', 'date', 'bookingHeaderId', 'description',
    'accountNo', 'debitAmount', 'creditAmount', "currency", 
    'debitCurrency', 'creditCurrency'
  ];

  const convertExcelDate = (excelDate: string | number): string => {
    if (typeof excelDate === 'string' && excelDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
      // If it's already in YYYY-MM-DD format, return as is
      return excelDate;
    }

    // Convert Excel number date to JavaScript Date
    const date = new Date(Math.round((Number(excelDate) - 25569) * 86400 * 1000));
    return moment(date).format('YYYY-MM-DD');
  };

  // Update the lookupCurrencyRate function
  const lookupCurrencyRate = async (currency: string, date: string): Promise<number> => {
    date = convertExcelDate(date);
    console.log('lookupCurrencyRate', currency, date);
    const cacheKey = `${currency}_${date}`;
    const API_KEY = process.env.REACT_APP_EXCHANGE_RATE_API_KEY; // Make sure to use your actual API key
    
    if (currencyRateCache?.[cacheKey] !== undefined) {
      console.log('cache hit', currencyRateCache[cacheKey]);
      return currencyRateCache[cacheKey];
    }
    
    if (currency === 'EUR') {
      dispatch(setCurrencyRate({ key: cacheKey, rate: 1 }));
      return 1;
    }

    try {
      const response = await fetch(
        `https://api.exchangeratesapi.io/v1/${date}?access_key=${API_KEY}&base=${currency}&symbols=EUR`
      );
      const data = await response.json();
      
      if (!data.rates?.EUR) {
        throw new Error(`No exchange rate found for ${currency} on ${date}`);
      }
      
      dispatch(setCurrencyRate({ key: cacheKey, rate: data.rates.EUR }));
      
      return data.rates.EUR;
    } catch (error) {
      console.error('Error fetching currency rate:', error);
      throw error;
    }
  };

  const handleXLSXFileUpload = async (file: File) => {
    setIsImporting(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      let jsonData = XLSX.utils.sheet_to_json(worksheet, {
        defval: 0,  // This handles completely empty cells
        raw: true
      }) as JournalEntry[];

      jsonData = await Promise.all(jsonData.map(async (row) => {
        const processedRow = { ...row };
        let value = processedRow['debitAmount'].toString();
        if (value === null || value === undefined || value.trim() === '') {
          processedRow['debitAmount'] = 0;
        }
        value = processedRow['creditAmount'].toString();
        if (value === null || value === undefined || value.trim() === '') {
          processedRow['creditAmount'] = 0;
        }
        value = processedRow['debitCurrency'].toString();
        if (value === null || value === undefined || value.trim() === '') {
          processedRow['debitCurrency'] = 0;
        }
        value = processedRow['creditCurrency'].toString();
        if (value === null || value === undefined || value.trim() === '') {
          processedRow['creditCurrency'] = 0;
        }
        value = processedRow['currency'].toString().trim();
        if (value === null || value === undefined || value.trim() === '') {
          processedRow['currency'] = 'EUR';
        }
        if (value.length > 3) {
          processedRow['currency'] = value.trim().substring(0, 3);
        }
        value = processedRow['date'].toString().trim();
        if (value.length > 10) {
          processedRow['date'] = convertExcelDate(value.substring(0, 10));
        }
        processedRow['EuroPerForeignCurrency'] = await lookupCurrencyRate(processedRow['currency'], processedRow['date']);
        return processedRow;
      }));

      if (jsonData.length === 0 || !requiredHeaders.every(header => header in jsonData[0])) {
        toast.error(intl.formatMessage({ id: "IMPORT.INVALID_HEADERS" }));
        setIsImporting(false);
        return;
      }

      // Group entries by bookingHeaderId
      const groupedEntries = jsonData.reduce((acc, entry) => {
        const key = entry.bookingHeaderId;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(entry);
        return acc;
      }, {} as Record<string, JournalEntry[]>);

      // Create bookingData for each group
      for (const [bookingHeaderId, entries] of Object.entries(groupedEntries)) {
        const firstEntry = entries[0];
        const bookingData = {
          diaryNo: firstEntry.diaryNo.toString(),
          companyNo: companyNo,
          bookingYearsNo: bookingYears?.id,
          date: convertExcelDate(firstEntry.date),
          bookingHeaderId: bookingHeaderId,
          projectNumber: 0,
          description: firstEntry.description.substring(0, 512),
          currency: firstEntry.currency || 'EUR',
          EuroPerForeignCurrency: firstEntry.EuroPerForeignCurrency || 1,
          bookings: entries.map(entry => ({
            accountNo: entry.accountNo.toString(),
            amountDebet: entry.debitAmount || entry.debitCurrency * firstEntry.EuroPerForeignCurrency || 0,
            amountCredit: entry.creditAmount || entry.creditCurrency * firstEntry.EuroPerForeignCurrency || 0,
            debitCurrency: entry.debitCurrency || 0,
            creditCurrency: entry.creditCurrency || 0,
          })),
        };
        try {
          console.log('bookingData', bookingData);
          await addNewMultiEntryBooking(bookingData).unwrap();
        } catch (error) {
          console.error("Error adding booking:", error);
          importErrors.push({
            entry: bookingData,
            error: error instanceof Error ? error.message : String(error)
          });
          toast.error(intl.formatMessage({ id: "IMPORT.ERROR_ADDING_BOOKING" }));
        }
      }

      setIsImporting(false);
      if (importErrors.length > 0) {
        downloadErrorLog(importErrors);
      } else {
        toast.success(intl.formatMessage({ id: "IMPORT.SUCCESS" }));
      }
    };
    reader.readAsArrayBuffer(file);
  };

  // Add this new function to generate and download the example file
  const downloadExampleFile = () => {
    const exampleData = [
      [
        'Note: These 2 lines are for explanation only, do not include in the actual file. ' +
        'Entries with the same bookingHeaderId form a multi-entry journal. ' +
        'bookingHeaderId: positive = A/P Invoice, negative = general multi-entry journal'
      ],
      [
        'Note: This line is for explanation only, do not include in the actual file. ' +
        'A debit and credit will be calculated based upon a currency rate which is the ' +
        'historic exchange rate on the date of the journal entry and will be ' +
        'looked up.'
      ],
      [...requiredHeaders],
      ['1', '2023-05-01', 211, 'Sample Transaction', '1000', 100, , ,],
      ['1', '2023-05-01', 211, 'Sample Transaction', '2000', , 100, ,],
      ['1', '2023-05-01', -201, 'Sample Transaction', '1000', 100, , ,],
      ['1', '2023-05-01', -201, 'Sample Transaction', '2000', , 100, ,],
      ['1', '2023-05-02', 2721, 'Another Transaction', '3000', , , 'USD',  230,],
      ['', '', 2721, '', '4000', , , 'USD', , 162.5],
      ['', '', 2721, '', '5000', , , 'USD', , 62.5]
    ];
    const ws = XLSX.utils.aoa_to_sheet(exampleData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "example_journal_entries.xlsx");
  };

  // Add this new function
  const downloadErrorLog = (errors: Array<{ entry: any, error: string }>) => {
    const errorData = [
      ['BookingHeaderId', 'Description', 'Date', 'Error Message'],
      ...errors.map(err => [
        err.entry.bookingHeaderId,
        err.entry.description,
        err.entry.date,
        JSON.stringify(err.error)
      ])
    ];

    const ws = XLSX.utils.aoa_to_sheet(errorData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Errors");

    // Create blob and trigger download
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'journalimport_exceptionlist.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Formik
      initialValues={{
        file: null as File | null,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (values.file) {
          handleXLSXFileUpload(values.file);
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, setFieldValue, errors, touched }) => (
        <Form className="card">
          <div className="card-header">
            <h3>{intl.formatMessage({ id: "IMPORT.TITLE_XLSX" })}</h3>
          </div>
          <div className="card-body">
            <div className="mb-3">
              <input
                type="file"
                onChange={async (event) => {
                  const file = event.currentTarget.files?.[0];
                  if (file) {
                    if (await isExcelFile(file)) {
                      setFieldValue("file", file);
                      handleXLSXFileUpload(file);
                    } else {
                      setFieldValue("file", null);
                      toast.error(intl.formatMessage({ id: "IMPORT.INVALID_FILE_TYPE" }));
                    }
                  }
                }}
                accept=".xlsx,.xls"
                className="form-control"
              />
              {errors.file && touched.file && <div className="text-danger">{errors.file}</div>}
            </div>
            <div className="mb-3">
              <button
                type="button"
                onClick={downloadExampleFile}
                className="btn btn-secondary me-2"
              >
                {intl.formatMessage({ id: "IMPORT.DOWNLOAD_EXAMPLE" })}
              </button>
              <button
                type="submit"
                disabled={isSubmitting || isImporting}
                className="btn btn-primary"
              >
                {isImporting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  intl.formatMessage({ id: "IMPORT.SUBMIT" })
                )}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ImportJournalEntries;
