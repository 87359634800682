import React, { FC } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { checkIsActive } from "../../helpers/RouterHelpers";

interface MenuItemProps {
  to: string;
  title: string;
  className?: string;
  [key: string]: any; // This allows for additional props
}

const MenuItem: React.FC<MenuItemProps> = ({ to, title, className, ...rest }) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  return (
    <Link to={to} className={`dropdown-item ${className || ''}`} {...rest}>
      {title}
    </Link>
  );
};

export default MenuItem;
