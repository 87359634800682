import React from "react";

const ServerError = () => {
  return (
    <div className="d-flex justify-content-center align-items-center spinner">
      <h1>
        <b>500</b> <span>Internal server error</span>
      </h1>
    </div>
  );
};

export default ServerError;
