import React from "react";
import { FieldProps } from "formik";

interface CustomInputProps extends FieldProps {
  placeholder: string;
  label: string;
  required: boolean;
  type: string;
  rows: number;
  className: string;
}
const getClassName = (touched: any, error: any): string => {
  if (touched && error) {
    return "form-control is-invalid";
  } else if (touched) {
    return "form-control is-valid";
  } else {
    return "form-control";
  }
};
const FormikTextAreaComponent: React.FC<CustomInputProps> = ({
  field,
  form: { touched, errors },
  placeholder,
  label,
  required,
  rows,
  className,
  type,
  ...props
}) => (
  <div className={className ?? "row mb-3"}>
    <label className={`col-3 col-form-label ${required ? "required" : ""}`}>
      {label}
    </label>
    <div className="col">
      <textarea
        {...field}
        {...props}
        value={field.value ?? ""}
        className={`${getClassName(touched[field.name], errors[field.name])}`}
        placeholder={placeholder}
        rows={rows}
      />
      {touched[field.name] && errors[field.name] && (
        <div className="invalid-feedback">{errors[field.name]?.toString()}</div>
      )}
    </div>
  </div>
);

export default FormikTextAreaComponent;
