import { Field, Form, Formik } from "formik";
import React, { FC, useEffect, useRef } from "react";
import { IntlShape, useIntl } from "react-intl";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useDynamicValidationSchema } from "../../../_panel/helpers/useDynamicValidationSchema";
import { intlRequiredMessage } from "../../../_panel/helpers/validation";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import {
  useGetCompaniesQuery,
  useImportMsAccessMutation,
} from "../../../app/apiService";
import { getFieldClassName } from "../../../common/Utils";

import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import FormikDatepickerComponent from "../../../_panel/partials/formik/FormikDatepickerComponent";
import FormikSelectComponent from "../../../_panel/partials/formik/FormikSelectComponent";
import { RootState } from "../../../app/RootReducer";
import {
  BookingYear,
  administrationSlice,
} from "../../../app/administrationSlice/administrationSlice";
const { actions } = administrationSlice;

const uploadAccessSchema = (intl: IntlShape) =>
  yup.object().shape({
    file: yup
      .mixed()
      .label(intl.formatMessage({ id: "HOMEPAGE.EXPORT.MSACCESS_FILE" }))
      .required(intlRequiredMessage(intl)),
    companyId: yup
      .number()
      .label(intl.formatMessage({ id: "HOMEPAGE.COMPANY" }))
      .required(intlRequiredMessage(intl)),
    fromDate: yup
      .date()
      .label(intl.formatMessage({ id: "HOMEPAGE.FROM_DATE" }))
      .required(),
    toDate: yup
      .date()
      .label(intl.formatMessage({ id: "HOMEPAGE.TO_DATE" }))
      .required()
      .test(
        "is-greater-than-fromDate",
        intl.formatMessage({ id: "TO_DATE_WARNING" }),
        function (toDate) {
          const { fromDate } = this.parent;
          return toDate >= fromDate;
        }
      ),
  });
const getNextYear = (date: Date) => {
  const nextYear = new Date(date);
  nextYear.setFullYear(date.getFullYear() + 1);
  return nextYear;
};
interface Props {
  setStep: (step: number) => void;
  setRejectedFiles: (rejectedFiles: any) => void;
}
const LegacyForm: FC<Props> = ({ setStep, setRejectedFiles }) => {
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration.bookingYears,
    shallowEqual
  ) as BookingYear;
  const { data: { records: companies = [], totalCount = 0 } = {} } =
    useGetCompaniesQuery({}) || {};
  const companiesOptions = companies.map((company: any) => {
    return {
      value: company.id,
      label: company.name,
    };
  });
  const [importMsAccess, { isLoading }] = useImportMsAccessMutation();
  const intl = useIntl();
  const formRef: React.RefObject<any> = useRef();
  const fileInputRef: React.RefObject<HTMLInputElement | undefined> = useRef();
  const validationSchema = useDynamicValidationSchema(uploadAccessSchema);
  useEffect(() => {
    formRef?.current?.validateForm();
  }, [validationSchema]);
  return (
    <Formik
      initialValues={{
        file: "",
        companyId: selectedBookingYear?.companyId ?? null,
        fromDate: selectedBookingYear?.fromDate,
        toDate: selectedBookingYear?.toDate,
      }}
      validationSchema={validationSchema}
      innerRef={formRef}
      validateOnBlur={true}
      onSubmit={(values, { setSubmitting }) => {
        const form_data = new FormData();
        if (fileInputRef.current?.files) {
          form_data.append("file", fileInputRef.current?.files[0]);
        }
        form_data.append(
          "company_year",
          JSON.stringify({
            companyId: values.companyId,
            fromDate: moment(values.fromDate).format("yyyy-MM-DD"),
            toDate: moment(values.toDate).format("yyyy-MM-DD"),
          })
        );
        importMsAccess(form_data)
          .unwrap()
          .then((data: any) => {
            toast.success(
              intl.formatMessage({
                id: "DATAINTEROPERABILITY.SUCCESSFULLY_TO_IMPORT",
              })
            );
          })
          .catch((err: any) => {
            if (parseInt(err?.data?.code) === 1000) {
              toast.error(
                intl.formatMessage({
                  id: "DATAINTEROPERABILITY.THE_FILE_IS_NOT_SUPPORTED",
                })
              );
            } else if (parseInt(err?.data?.code) === 1001) {
              toast.error(
                intl.formatMessage({
                  id: "DATAINTEROPERABILITY.FAILED_TO_UPLOAD",
                })
              );
            } else if (parseInt(err?.data?.code) === 1003) {
              if (err.data?.rejectedFiles?.length > 0) {
                setStep(2);
                setRejectedFiles(err.data?.rejectedFiles);
              }
            } else {
              toast.error(
                intl.formatMessage({
                  id: "DATAINTEROPERABILITY.FAILED_TO_IMPORT",
                })
              );
            }
          });
      }}
    >
      {({
        isSubmitting,
        errors,
        touched,
        setTouched,
        setFieldValue,
        values,
      }) => (
        <Form className="card mt-3">
          {isLoading && <ProgressBar />}
          <div className="card-header">
            <h3 className="card-title">
              {" "}
              {intl.formatMessage({ id: "HOMEPAGE.EXPORT_TITLE" })}
            </h3>
          </div>
          <div className="card-body ">
            <Field
              name="companyId"
              className="col-12 mb-3"
              labelClassName="form-label"
              component={FormikSelectComponent}
              row
              placeholder={intl.formatMessage({ id: "HOMEPAGE.COMPANY" })}
              label={intl.formatMessage({ id: "HOMEPAGE.COMPANY_TO_IMPORT" })}
              required
              options={companiesOptions}
            />

            <div className="row">
              <Field
                name="fromDate"
                className="col-6 mb-3"
                labelClassName="form-label"
                onChange={(date: any) => {
                  setFieldValue(
                    "toDate",
                    moment(getNextYear(date)).format("yyyy-MM-DD")
                  );
                  setTimeout(() => {
                    setTouched({ ...touched, fromDate: true });
                    setTouched({ ...touched, toDate: true });
                  }, 150);
                }}
                component={FormikDatepickerComponent}
                placeholder={intl.formatMessage(
                  { id: "PLACEHOLDER" },
                  {
                    label: intl
                      .formatMessage({
                        id: "HOMEPAGE.FROM_DATE",
                      })
                      .toLocaleLowerCase(),
                  }
                )}
                label={intl.formatMessage({
                  id: "HOMEPAGE.FROM_DATE_TO_IMPORT",
                })}
                required
              />
              <Field
                name="toDate"
                className="col-6 mb-3"
                labelClassName="form-label"
                component={FormikDatepickerComponent}
                delta={-1}
                placeholder={intl.formatMessage(
                  { id: "PLACEHOLDER" },
                  {
                    label: intl
                      .formatMessage({
                        id: "HOMEPAGE.TO_DATE",
                      })
                      .toLocaleLowerCase(),
                  }
                )}
                label={intl.formatMessage({ id: "HOMEPAGE.TO_DATE_TO_IMPORT" })}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {intl.formatMessage({ id: "HOMEPAGE.IMPORT" })}
              </label>
              <div className="row">
                <div className=" col-md-10">
                  <Field
                    className={`form-control ${getFieldClassName(
                      !!touched.file,
                      !!errors.file
                    )}`}
                    innerRef={fileInputRef}
                    type="file"
                    name="file"
                  />
                  <big className="form-hint mb-2">
                    {intl.formatMessage({ id: "HOMEPAGE.IMPORT_DESCRIPTION" })}
                  </big>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer text-end">
            <button type="submit" className="btn btn-primary   mx-auto">
              {intl.formatMessage({
                id: "HOMEPAGE.EXPORT.UPLOAD_FILE",
              })}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LegacyForm;
