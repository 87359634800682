import type { DragLayerMonitorProps } from "@minoru/react-dnd-treeview";
import React from "react";

type Props = {
  monitorProps: DragLayerMonitorProps<any>;
};

export const CustomDragPreview: React.FC<Props> = (props) => {
  const item = props.monitorProps.item;

  return (
    <div className="custom-drag-preview-root" data-testid="custom-drag-preview">
      {item.text}
    </div>
  );
};
