import { useMemo } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import {
  useGetAccountsQuery,
  useUpdateAccountsMutation,
} from "../../../app/apiService";
import { CoaInterface } from "../CoaModel";
import { useCoaUIContext } from "../CoaUiContext";
import TreeViewItems from "./TreeViewItems";
import { CustomData } from "./types";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { ErrorType } from "../../../common/TypeHelper";

const CoaTable = () => {
  const intl = useIntl();
  const coaUiParams = useCoaUIContext();
  const [updateAccount, { isLoading: isLoadingUpdateAccount }] =
    useUpdateAccountsMutation();
  const accountsUIProps = useMemo(() => {
    return {
      queryParams: coaUiParams?.queryParams,
      setQueryParams: coaUiParams?.setQueryParams,
      deleteCoaClicked: coaUiParams?.deleteCoaClicked,
      editCoaClicked: coaUiParams?.editCoaClicked,
    };
  }, [coaUiParams]);
  const {
    data: { records: accounts = [], totalCount = 0 } = {},
    isLoading,
    isError,
    error,
  } = useGetAccountsQuery({
    page: accountsUIProps.queryParams?.pageNumber,
    perPage: accountsUIProps.queryParams?.perPage,
    sort: accountsUIProps.queryParams?.sort,
    filter: accountsUIProps.queryParams?.filter,
  }) || {};
  if (isError && error) {
    const data = (error as ErrorType)?.data;
    if (data?.code === 10008) {
      toast.warn(intl.formatMessage({ id: "BOOKING.SELECT_ADMINSTRATION" }), {
        toastId: 10008,
      });
    }
  }
  const treeData = accounts
    .map((account: CoaInterface) => {
      return {
        id: account.number,
        text: account.number,
        data: { ...account },
        droppable: true,
        parent: account.parentAccount ?? 0,
      };
    })
    .filter(
      (account: NodeModel<CustomData>) =>
        account.data?.number?.toString() !== "0"
    );
  // .sort((account1: NodeModel<CustomData>, account2: NodeModel<CustomData>) => {
  //   if (account1.data?.number && account2.data?.number) {
  //     return parseInt(account1.data.number) - parseInt(account2.data.number);
  //   }
  //   return 0;
  // })
  const updateAccountParent = (account: any) => {
    updateAccount({ ...account, id: account.number })
      .unwrap()
      .then(() => {
        toast.success(intl.formatMessage({ id: "COA.EDITED" }));
      })
      .catch((err) => {
        toast.error(intl.formatMessage({ id: "COA.EDITED_FAILED" }));
      });
  };

  return (
    <div className="col-12 page-body">
      <div className="card">
        {(isLoading || isLoadingUpdateAccount) && <ProgressBar />}
        <div className="table-responsive">
          <table className="table table-vcenter card-table table-striped">
            <thead>
              <tr>
                {/* <th className="col-md-1"></th> */}
                <th className="col-md-3 ps-5">
                  {intl.formatMessage({ id: "COA.ACCOUNT" })}
                </th>
                <th className="col-md-3">
                  {intl.formatMessage({ id: "COA.DESCRIPTION" })}
                </th>

                <th className="text-center">
                  {intl.formatMessage({ id: "COA.BALANCE" })}
                </th>
                <th className="text-center">
                  {intl.formatMessage({ id: "COA.IS_SUB_ADMIN" })}
                </th>

                <th className="w-25"></th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="">
          {treeData.length > 0 && (
            <TreeViewItems
              data={[...treeData]}
              updateAccountParent={updateAccountParent}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CoaTable;
