import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import MenuItem from "./MenuItem";
import MenuItemWithSubmenu from "./MenuItemWithSubmenu";
import { checkIsActive } from "../../helpers/RouterHelpers";

const Aside = () => {
  const { pathname } = useLocation();
  const intl = useIntl();

  const generalLedgerSubItems = [
    {
      to: "/general-ledger/booking",
      title: intl.formatMessage({ id: "ASIDE.BOOKINGS" })
    },
    {
      to: "/create-opening-balance",
      title: intl.formatMessage({ id: "ASIDE.CREATE_OPENING_BALANCE" })
    },
    {
      to: "/import-bank",
      title: intl.formatMessage({ id: "ASIDE.IMPORT_BANK" })
    },
    {
      to: "/import-journal-entries",
      title: intl.formatMessage({ id: "ASIDE.IMPORT_JOURNAL_ENTRIES" })
    },
    {
      to: "/reconciliation",
      title: intl.formatMessage({ id: "ASIDE.RECONCILIATION" }),
    },
    {
      to: "/import-eoy-journal-entries",
      title: intl.formatMessage({ id: "ASIDE.IMPORT_EOY_JOURNAL_ENTRIES" }),
    },
    {
      subItems: [
        {
          to: "/mastertables/foreign-currencies",
          title: intl.formatMessage({ id: "ASIDE.FOREIGN_CURRENCIES" }),
        },
        // You can add more levels here if needed
        // subItems: [
        //   {
        //     to: "/mastertables/subtable",
        //     title: "Sub Table"
        //   }
        // ]
      ]
    }
  ];

  return (
    <aside
      className="navbar navbar-vertical navbar-expand-lg"
      data-bs-theme="dark"
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebar-menu"
          aria-controls="sidebar-menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <h1 className="navbar-brand navbar-brand-autodark">
          <a href="#">
            <img
              src="/static/sun-fog-svgrepo-com.svg"
              width={110}
              height={32}
              alt="Early Booking"
              className="navbar-brand-image"
            />
          </a>
        </h1>

        <div className="collapse navbar-collapse" id="sidebar-menu">
          <ul className="navbar-nav pt-lg-3">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#navbar-home"
                data-bs-toggle="dropdown"
                data-bs-auto-close="false"
                role="button"
                aria-expanded="false"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* Home icon SVG */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                  </svg>
                </span>
                <span className="nav-link-title">
                  {intl.formatMessage({ id: "ASIDE.HOME" })}
                </span>
              </a>
              <div className={`dropdown-menu ${checkIsActive(pathname, "/") || checkIsActive(pathname, "/homepage") || checkIsActive(pathname, "/create-opening-balance") ? "show" : ""
                }`}>
                <div className="dropdown-menu-columns">
                  <div className="dropdown-menu-column">
                    <MenuItem
                      to="/homepage"
                      title={intl.formatMessage({ id: "ASIDE.HOMEPAGE" })}
                    />
                  </div>
                </div>
              </div>
            </li>

            <Link
              className={`dropdown-item ${checkIsActive(pathname, "/general-ledger/") ? "active" : ""
                }`}
              to="/general-ledger/"
            >
            </Link>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#navbar-base"
                data-bs-toggle="dropdown"
                data-bs-auto-close="false"
                role="button"
                aria-expanded="false"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* Download SVG icon from http://tabler-icons.io/i/package */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 3l8 4.5l0 9l-8 4.5l-8 -4.5l0 -9l8 -4.5" />
                    <path d="M12 12l8 -4.5" />
                    <path d="M12 12l0 9" />
                    <path d="M12 12l-8 -4.5" />
                    <path d="M16 5.25l-8 4.5" />
                  </svg>
                </span>
                <span className="nav-link-title">
                  {intl.formatMessage({ id: "ASIDE.GL" })}
                </span>
              </a>
              <div
                className={`dropdown-menu ${checkIsActive(pathname, "/general-ledger/") ? "show" : ""
                  }`}
              >
                <div className="dropdown-menu-columns">
                  <div className="dropdown-menu-column">
                    {generalLedgerSubItems.map((item, index) =>
                      item.subItems ? (
                        <MenuItemWithSubmenu
                          key={index}
                          to={item.to ?? '#'}
                          title={item.title ?? ''}
                          subItems={item.subItems}
                        />
                      ) : (
                        <MenuItem
                          key={index}
                          to={item.to || '#'}
                          title={item.title || ''}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            </li>
            <Link
              className={`dropdown-item ${checkIsActive(pathname, "/mastertables/") ? "active" : ""
                }`}
              to="/mastertables/"
            >
            </Link>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#navbar-base"
                data-bs-toggle="dropdown"
                data-bs-auto-close="false"
                role="button"
                aria-expanded="false"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* Download SVG icon from http://tabler-icons.io/i/package */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-device-tablet-star"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M11 21h-5a1 1 0 0 0 -1 -1v-16a1 1 0 0 0 1 -1h12a1 1 0 0 0 1 1v6" /><path d="M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" /></svg>                </span>
                <span className="nav-link-title">
                  {intl.formatMessage({ id: "ASIDE.MASTERTABLES" })}
                </span>
              </a>
              <div
                className={`dropdown-menu ${checkIsActive(pathname, "/mastertables/") ? "show" : ""
                  }`}
              >
                <div className="dropdown-menu-columns">
                  <div className="dropdown-menu-column">
                    <MenuItem
                      to="/mastertables/diaries"
                      title={intl.formatMessage({ id: "ASIDE.DIARIES" })}
                    />
                    <MenuItem
                      to="/mastertables/chart-of-accounts"
                      title={intl.formatMessage({
                        id: "ASIDE.CHART_OF_ACCOUNTS",
                      })}
                    />
                    <MenuItem
                      to="/mastertables/mastertables"
                      title={intl.formatMessage({
                        id: "ASIDE.OTHER_MASTERTABLES",
                      })}
                    />
                  </div>
                </div>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#navbar-base"
                data-bs-toggle="dropdown"
                data-bs-auto-close="false"
                role="button"
                aria-expanded="false"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* Download SVG icon from http://tabler-icons.io/i/checkbox */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-report"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" /><path d="M18 14v4h4" /><path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" /><path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /><path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M8 11h4" /><path d="M8 15h3" /></svg>
                </span>
                <span className="nav-link-title">
                  {intl.formatMessage({ id: "ASIDE.OVERVIEWS" })}
                </span>
              </a>
              <div
                className={`dropdown-menu ${checkIsActive(pathname, "/overviews/") ? "show" : ""
                  }`}
              >
                <div className="dropdown-menu-columns">
                  <div className="dropdown-menu-column">
                    <MenuItem
                      to="/overviews/master-tables"
                      title={intl.formatMessage({
                        id: "ASIDE.REPORT_STRUCTURE",
                      })}
                    />
                  </div>
                </div>
                {/* <div className="dropdown-menu-columns">
                  <div className="dropdown-menu-column">
                    <MenuItem
                      to="/overviews/reports"
                      title={intl.formatMessage({
                        id: "OVERVIEWS.DOWNLOAD_BOOKINGS_JSON",
                      })}
                    />
                  </div>
                </div> */}
                <div className="dropdown-menu-columns">
                  <div className="dropdown-menu-column">
                    <MenuItem
                      to="/overviews/generate-balance-ultimo"
                      title={intl.formatMessage({
                        id: "OVERVIEWS.GENERATE_BALANCE_ULTIMO",
                      })}
                    />
                  </div>
                </div>
              </div>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="#">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* Download SVG icon from http://tabler-icons.io/i/checkbox */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M15 15l3.35 3.35" />
                    <path d="M9 15l-3.35 3.35" />
                    <path d="M5.65 5.65l3.35 3.35" />
                    <path d="M18.35 5.65l-3.35 3.35" />
                  </svg>
                </span>
                <span className="nav-link-title">
                  {intl.formatMessage({ id: "ASIDE.HELP" })}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Aside;
