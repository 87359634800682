import React, { Suspense } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import "../src/_panel/assets/sass/style.scss";
import {
  LanguageContextInterface,
  PanelI18nProvider,
} from "./_panel/i18n/Paneli18n";
import { I18nProvider } from "./_panel/i18n/i18nProvider";
import { MasterLayout } from "./_panel/layout/MasterLayout";
import SplashScreen from "./_panel/layout/core/SplashScreen";
import UserService from "./app/oauth/UserService";
import { persistor, store } from "./app/store";
import PrivateRoutes from "./routing/PrivateRoutes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const languagesUiEvents: LanguageContextInterface = {};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="*"
      element={
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      }
    />
  )
);
const renderApp = () =>
  root.render(
    <React.StrictMode>
      <PanelI18nProvider LanguagesUIEvents={languagesUiEvents}>
        <I18nProvider>
          <Provider store={store}>
            <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
              {/* <App /> */}
              <Suspense fallback={<SplashScreen />}>
                <RouterProvider router={router} />
              </Suspense>
            </PersistGate>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </Provider>
        </I18nProvider>
      </PanelI18nProvider>
    </React.StrictMode>
  );
process.env.REACT_APP_SKIP_KEYCLOAK
  ? renderApp()
  : UserService.initKeycloak(renderApp); // If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
