import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  isAlphaNumericKey,
  keyCodes,
} from "@silevis/reactgrid";
import React, { FC } from "react";
import Select from "react-select";

export type OptionType = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

export interface DropdownCell extends Cell {
  type: "selectDropdown";
  text?: string;
  options: OptionType[];
}
export class SelectCellTemplate implements CellTemplate<DropdownCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<DropdownCell>
  ): Compatible<DropdownCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    let options = [];
    try {
      options = getCellProperty(uncertainCell, "options", "object");
    } catch (e) {
      console.log(e);
    }
    const value = parseFloat(text);
    return { ...uncertainCell, text, value, options };
  }
  getClassName(cell: Compatible<DropdownCell>, isInEditMode: boolean): string {
    return cell.className ? cell.className : "";
  }
  handleKeyDown(
    cell: Compatible<DropdownCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<DropdownCell>; enableEditMode: boolean } {
    if (
      (!ctrl && !alt && isAlphaNumericKey(keyCode)) ||
      keyCode === keyCodes.DELETE
    )
      return { cell, enableEditMode: true };
    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  update(
    cell: Compatible<DropdownCell>,
    cellToMerge: UncertainCompatible<DropdownCell>
  ): Compatible<DropdownCell> {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
      options: cellToMerge.options,
    });
  }
  render(
    cell: Compatible<DropdownCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<DropdownCell>, commit: boolean) => void
  ): React.ReactNode {
    return (
      <div className="w-100">
        {isInEditMode ? (
          <DropdownInput
            cell={cell}
            onCellChanged={(cell) =>
              onCellChanged(this.getCompatibleCell({ ...cell }), true)
            }
          />
        ) : (
          <>
            {
              cell.options?.find(
                (option: any) =>
                  option.value?.toString() === cell?.text?.toString()
              )?.label
            }
            {cell?.text?.toString()}
          </>
        )}{" "}
      </div>
    );
  }
}

interface DIProps {
  onCellChanged: (...args: any[]) => void;
  cell: Record<string, any>;
}
const DropdownInput: FC<DIProps> = ({ onCellChanged, cell }) => {
  const selectRef = React.useRef<any>(null);
  React.useEffect(() => {
    if (selectRef.current) {
      selectRef.current.focus();
    }
  }, [cell.isOpen, cell.text]);
  return (
    <div
      onPointerDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {" "}
      <Select
        onKeyDown={(e) => {
          if (e.key !== "Escape") {
            e.stopPropagation();
          }
        }}
        menuIsOpen={true}
        ref={selectRef}
        styles={{
          menu: (baseStyles, state) => {
            return {
              ...baseStyles,
              zIndex: 9999,
              marginTop: 16,
              width: "100%",
            };
          },
        }}
        onChange={(e) => {
          onCellChanged({
            ...cell,
            text: e?.value?.toString(),
          });
        }}
        isSearchable={true}
        openMenuOnFocus={true}
        className={`select-control select-control-sm`}
        options={cell.options}
        value={cell.options.find(
          (option: any) => option.value.toString() === cell?.text.toString()
        )}
      />
    </div>
  );
};
