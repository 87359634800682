import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import { DiariesInterface } from "../DiariesModel";
import DiariesForm from "../DiariesForm/DiariesForm";
import { toast } from "react-toastify";
import { useAddNewDiariesMutation } from "../../../app/apiService";

const DiariesCreate = () => {
  const [addNewDiary, { isLoading }] = useAddNewDiariesMutation();
  const data: DiariesInterface = {
    number: "",
    description: "",
    offsetAccount: "",
    isMemorial: false,
  };
  const navigate = useNavigate();
  const intl = useIntl();
  const onCancel = () => {
    navigate(-1);
  };
  const onFormSubmit = (
    data: DiariesInterface,
    setSubmitting: (status: boolean) => void
  ) => {
    addNewDiary(data)
      .unwrap()
      .then(() => {
        setSubmitting(true);
        onCancel();
        toast.success(intl.formatMessage({ id: "DIARIES.CREATED" }));
      })
      .catch((err: any) => {
        setSubmitting(false);
        if (err?.data?.code === 10008) {
          toast.warn(
            intl.formatMessage({ id: "BOOKING.SELECT_ADMINSTRATION" })
          );
        } else if (err.data?.error === "Diary number is in use") {
          toast.error(
            intl.formatMessage({ id: "DIARIES.DIARY_NUMBER_IN_USE" })
          );
        } else {
          toast.error(intl.formatMessage({ id: "DIARIES.CREATED_FAILED" }));
        }
      });
  };
  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">
                {intl.formatMessage({ id: "ASIDE.GL" })}
              </div>
              <h2 className="page-title">
                {intl.formatMessage({ id: "DIARIES.CREATE" })}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 page-body">
        <div className="card">
          {isLoading && <ProgressBar />}
          <DiariesForm
            data={data}
            onCancel={onCancel}
            formSubmitted={onFormSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default DiariesCreate;
