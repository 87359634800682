import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { CompnayDataInterface, ImportResults } from "./BankImportHelper";
import MissingDaysTable from "./MissingDaysTable";
import { useGetBookingsQuery } from "../../app/apiService";
import { shallowEqual, useSelector } from "react-redux";
import { BookingYear } from "../../app/administrationSlice/administrationSlice";
import { RootState } from "../../app/RootReducer";
interface Props {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  importCompanyData: CompnayDataInterface;
  importResults: ImportResults;
}
const ConfirmConsecutiveModel: FC<Props> = ({
  show,
  onHide,
  onConfirm,
  importResults,
  importCompanyData,
}) => {
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration.bookingYears,
    shallowEqual
  ) as BookingYear;
  const { data: { totalAmount = 0 } = {}, isLoading: bookingLoading } =
    useGetBookingsQuery(
      {
        filter: {
          diaryNo: importCompanyData.diaryId,
        },
        page: 0,
        perPage: 0,
        bookingYearsNo: selectedBookingYear?.id,
      },
      {
        skip: !selectedBookingYear?.id,
      }
    ) || {};
  const intl = useIntl();
  const canUserConfirm = () => {
    if (!importResults?.missingDays) {
      return true;
    }

    return (
      parseFloat(totalAmount) ===
      parseFloat(
        importResults?.missingDays?.length > 0
          ? importResults?.missingDays[0]?.amount?.toString()
          : "0"
      )
    );
  };
  return (
    <Modal show={show} onHide={onHide} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {intl.formatMessage({ id: "BANKIMPORT.CONSECUTIVE_ERROR" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon mb-2 text-danger icon-lg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 9v2m0 4v.01" />
          <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
        </svg>
        {canUserConfirm() && (
          <h3>{intl.formatMessage({ id: "ARE_YOU_SURE" })}</h3>
        )}
        <div>
          {importResults?.code === 1003 && (
            <>
              <p className="text-danger mb-2 text-start h4">
                {intl.formatMessage({
                  id: "BANKIMPORT.CONSECUTIVE_ERROR_DESCRIPTION",
                })}
              </p>
              <p className="h5 text-secondary mb-1">
                {intl.formatMessage({
                  id: "BANKIMPORT.CONSECUTIVE_ERROR_RECOMANDATION",
                })}
              </p>
            </>
          )}
          {importResults?.code === 1005 && (
            <>
              {canUserConfirm() ? (
                <p className="text-danger mb-2 text-start h4">
                  {intl.formatMessage({
                    id: "BANKIMPORT.MISSING_DAYS",
                  })}
                </p>
              ) : (
                <>
                  <p className="text-danger mb-2 text-start h4">
                    {intl.formatMessage({
                      id: "BANKIMPORT.BALANCE_IS_NOT_AT_BEGINNING",
                    })}
                  </p>
                  <p className="mb-2 text-start h4">
                    {intl.formatMessage({
                      id: "BANKIMPORT.CANT_IMPORT_MEANS",
                    })}
                  </p>
                  <div className="text-start">
                    <ul className="mx-1">
                      <li>
                        {intl.formatMessage({
                          id: "BANKIMPORT.CANT_IMPORT_WARNNING_1",
                        })}
                      </li>
                      <li className="mt-1">
                        {intl.formatMessage({
                          id: "BANKIMPORT.CANT_IMPORT_WARNNING_2",
                        })}
                      </li>
                    </ul>
                  </div>
                </>
              )}
              <MissingDaysTable missingDays={importResults.missingDays} />
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <div className="w-100">
          <div className="row">
            <div className="col">
              <button
                onClick={onHide}
                className="btn w-100"
                data-bs-dismiss="modal"
              >
                {canUserConfirm()
                  ? intl.formatMessage({ id: "NO" })
                  : intl.formatMessage({ id: "CANCEL" })}
              </button>
            </div>
            {canUserConfirm() && (
              <div className="col">
                <button
                  onClick={onConfirm}
                  className="btn btn-primary w-100"
                  data-bs-dismiss="modal"
                >
                  {intl.formatMessage({ id: "YES" })}
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmConsecutiveModel;
