import { combineReducers } from "redux";
import { administrationSlice } from "./administrationSlice/administrationSlice";

// Add the currency rate slice
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CurrencyRatePayload {
  key: string;
  rate: number;
}

export const currencyRateSlice = createSlice({
  name: "currencyRate",
  initialState: {} as Record<string, number>,
  reducers: {
    setCurrencyRate: (state, action: PayloadAction<CurrencyRatePayload>) => {
      state[action.payload.key] = action.payload.rate;
    },
  },
});

export const { setCurrencyRate } = currencyRateSlice.actions;

export const rootReducer = combineReducers({
  administration: administrationSlice.reducer,
  currencyRateCache: currencyRateSlice.reducer,
});

type Root = ReturnType<typeof rootReducer>;
export type RootState = {
  root: Root;
};
