import moment from "moment";
import { FC, useEffect } from "react";
import { useGetBookingsQuery } from "../../app/apiService";
import CheckIcon from "../../components/Icons/CheckIcon";
import XIcon from "../../components/Icons/XIcon";
import {
  CompnayDataInterface,
  UsedBookingYearClosing,
} from "./BankImportHelper";
import { formatNumber, getEarlyDay } from "../../common/Utils";
type Props = {
  data: UsedBookingYearClosing;
  importCompanyData: CompnayDataInterface;
  setIsLoading: (loading: boolean) => void;
};

const ReportRow: FC<Props> = ({ data, importCompanyData, setIsLoading }) => {
  const {
    data: { totalAmount = 0, totalCount = 0 } = {},
    isLoading: bookingLoading,
  } =
    useGetBookingsQuery(
      {
        filter: {
          diaryNo: importCompanyData.diaryId,
        },
        page: 0,
        perPage: 0,
        bookingYearsNo: data?.id,
      },
      {
        skip: !data?.id,
      }
    ) || {};
  setIsLoading(bookingLoading);
  return (
    <>
      {" "}
      {!bookingLoading && (
        <tr key={data.id}>
          <td className="text-break text-wrap py-1">
            {moment(data.fromDate).format("yyyy-MM-DD")}
          </td>
          <td className="text-break text-wrap py-1">
            {" "}
            {moment(getEarlyDay(data.toDate)).format("yyyy-MM-DD")}
          </td>
          <td className="text-break text-wrap py-1"> {totalCount}</td>
          <td className="text-break text-wrap py-1"> {data.closingBalance}</td>
          <td className="text-break text-wrap py-1">
            {" "}
            {formatNumber(totalAmount)}
          </td>
          <td className="text-break text-wrap py-1">
            {" "}
            {parseFloat(totalAmount) !== data.closingBalance ? (
              <XIcon />
            ) : (
              <CheckIcon />
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export default ReportRow;
