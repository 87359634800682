import {
  DndProvider,
  MultiBackend,
  NodeModel,
  Tree,
  getBackendOptions,
} from "@minoru/react-dnd-treeview";
import { FC, useEffect, useState } from "react";
import { CustomDragPreview } from "./CustomDragPreview";
import { CustomNode } from "./CustomNode";
import { CustomData } from "./types";
interface Props {
  data: any;
  updateAccountParent: (account: any) => void;
}
const TreeViewItems: FC<Props> = ({ data, updateAccountParent }) => {
  const [treeData, setTreeData] = useState<NodeModel<CustomData>[]>([]);
  useEffect(() => {
    setTreeData(data);
  }, [data]);
  const handleDrop = (newTree: NodeModel<CustomData>[]) => {
    const changedItem = newTree.find(
      (item, index) => item.parent !== data[index].parent
    );
    if (changedItem) {
      const parentAccount =
        newTree.find((item, index) => item.id === changedItem.parent)?.data
          ?.number ?? null;
      updateAccountParent({
        id: changedItem.data?.id,
        number: changedItem.data?.number,
        parentAccount: parentAccount,
      });
    }

    setTreeData(newTree);
  };
  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <Tree
        tree={treeData}
        rootId={0}
        initialOpen={true}
        sort={(item: NodeModel<CustomData>, item2: NodeModel<CustomData>) => {
          return (
            parseInt(item.data?.number ?? "") -
            parseInt(item2.data?.number ?? "")
          );
        }}
        render={(node, options) => <CustomNode node={node} {...options} />}
        dragPreviewRender={(monitorProps) => (
          <CustomDragPreview monitorProps={monitorProps} />
        )}
        onDrop={handleDrop}
        classes={{
          root: "treeview-root",
          container: "wtree",
          draggingSource: "treeview-dragging-source ",
          dropTarget: "treeview-drop-target",
        }}
      />
    </DndProvider>
  );
};

export default TreeViewItems;
