import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState, useCallback } from "react";
import { IntlShape, useIntl } from "react-intl";
import * as yup from "yup";
import { useDynamicValidationSchema } from "../../_panel/helpers/useDynamicValidationSchema";
import { intlRequiredMessage } from "../../_panel/helpers/validation";
import ProgressBar from "../../_panel/partials/ProgressBar";
import { BookingYear } from "../../app/administrationSlice/administrationSlice";
import { RootState } from "../../app/RootReducer";
import { shallowEqual, useSelector } from "react-redux";
import FormikDatepickerComponent from "../../_panel/partials/formik/FormikDatepickerComponent";
import moment from "moment";
import { useAddNewBookingMutation, useBalanceUltimoReportMutation, useDataUltimoReportMutation, useEarningsUltimoReportMutation } from "../../app/apiService";
import Iframe from "react-iframe";
import { Spinner } from "react-bootstrap";
import saveAs from "file-saver";
import FormikSwitchComponent from "../../_panel/partials/formik/FormikSwitchComponent";
import { toast } from "react-toastify";

enum ReportType {
  Balance = 'BALANCE',
  Earnings = 'EARNINGS',
  All = 'ALL'
}

type BBData = {
  isBalance: number;
  companyName: string;
  companyNo: number;
  bookingYearsNo: number;
  bookYear: number;
  bookingYearFrom: string;
  bookingYearTo: string;
  dateUltimo: string;
  accounts: Array<{
    account: string;
    description: string;
    debet: string;
    credit: number;
  }>;
  balanceDebet: number;
  balanceCredit: string;
  sumAllBalances: string;
};

const generateOpeningBalanceSchema = (intl: IntlShape) =>
  yup.object().shape({
    dateUltimo: yup
      .date()
      .label(intl.formatMessage({ id: "GENERATE_BALANCE_ULTIMO.END_DATE" }))
      .required(intlRequiredMessage(intl)),
    isBalanceReport: yup.boolean().required(),
  });

const GenerateOpeningBalance = () => {
  let isBookingLoading = false;
  const [fileObj, setFileObj] = useState<string | null>(null);
  const [data, setData] = useState<BBData | null>(null);
  const [dataUltimoReport, { isLoading: isLoading2 }] = useDataUltimoReportMutation();
  const [balanceUltimoReport, { isLoading: isLoading }] = useBalanceUltimoReportMutation();
  const [earningsUltimoReport] = useEarningsUltimoReportMutation();
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration?.bookingYears,
    shallowEqual
  ) as BookingYear;
  const formRef: React.RefObject<any> = useRef();
  const validationSchema = useDynamicValidationSchema(generateOpeningBalanceSchema);
  const intl = useIntl();
  const [reportType, setReportType] = useState<ReportType>(ReportType.All);
  const [addNewBooking] = useAddNewBookingMutation();
  const [error, setError] = useState<string | null>(null);

  const generateReport = useCallback((date: Date, type: ReportType) => {
    setFileObj(null);
    setData(null);
    setError(null);
    dataUltimoReport({

//-----------------------------------------
//TODO: check with broken booking year
//----------------------------------------- 

      dateUltimo: moment.utc(date).subtract(1, 'year').format("YYYY-MM-DD"),
      allowDatesOutsideCurrentBookingYear: true,
      reportType: ReportType.Balance,
      companyId: selectedBookingYear.companyId
    })
      .unwrap()
      .then((data) => {
        setData(data);
        balanceUltimoReport({ data })
          .unwrap()
          .then((response) => {
            setFileObj(response);
          });
      })
      .catch((err) => {
        if (err.status === 404) {
          setError(intl.formatMessage({ id: "NO_PRECEDING_YEAR_WITH_JOURNALS" }));
        } else {
          setError(intl.formatMessage({ id: "GENERAL_ERROR" }));
        }
      });
  }, [dataUltimoReport, balanceUltimoReport, intl]);

  useEffect(() => {
    generateReport(
      moment.utc(selectedBookingYear.toDate).subtract(1, "days").toDate(),
      reportType
    );
  }, [selectedBookingYear.toDate, reportType, generateReport]);

  useEffect(() => {
    formRef?.current?.validateForm();
  }, [validationSchema]);

  const CreateOpeningBalanceBtnClicked = async () => {
    isBookingLoading = true;
    if (data && data.accounts && data.accounts.length > 0) {
      for (const booking of data.accounts) {
        const values = {
          diaryNo: "1",
          companyNo: data.companyNo,
          bookingYearsNo: selectedBookingYear.id,
          date: moment(selectedBookingYear?.fromDate).format("yyyy-MM-DD"),
          description: "BB"+booking.description,
          projectNumber: "0",
          accountNo: booking.account,
          amountCredit: booking.credit,
          amountDebet: booking.debet,
          currency: "EUR",
          debitCurrency: 0,
          creditCurrency: 0,
          bookingHeaderId: null
        };
        if (!values.diaryNo) {
          toast.warn(intl.formatMessage({ id: "BOOKING.SELECT_DIARY_WARNING" }));
          isBookingLoading = false;
          return;
        }

        addNewBooking({ ...values })
          .unwrap()
          .then(() => {
            isBookingLoading = true;
            toast.success(intl.formatMessage({ id: "BOOKING.CREATED" }));
          })
          .catch((err: any) => {
            isBookingLoading = false;
            if (err.data?.code === 1101) {
              toast.error(
                intl.formatMessage({ id: "BOOKING.CREATED_FAILED_ACCOUNT" })
              );
            } else {
              toast.error(intl.formatMessage({ id: "BOOKING.CREATED_FAILED" }));
            }
          });
      };
    } else {
      console.error("data or accounts is empty");
      isBookingLoading = false;
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          dateUltimo: moment
            .utc(selectedBookingYear.toDate)
            .subtract(1, "days")
            .toDate(),
        }}
        validationSchema={validationSchema}
        innerRef={formRef}
        validateOnBlur={true}
        onSubmit={(values) => {
          generateReport(values.dateUltimo, reportType);
        }}
      >
        {({ isSubmitting, submitForm, values }) => (
          <Form className="card">
            {(isLoading || isLoading2) && <ProgressBar />}
            <div className="card-header align-items-center justify-content-between">
              <div className="d-flex align-items-baseline">
                <Field
                  name="dateUltimo"
                  className="ms-3"
                  showMonthYearPicker={false}
                  labelClassName="form-label"
                  component={FormikDatepickerComponent}
                  maxDate={moment
                    .utc(selectedBookingYear.toDate)
                    .subtract(1, "days")
                    .toDate()}
                  onChange={(date: Date) => {
                    if (!(isLoading || isLoading2) && !isSubmitting) {
                      generateReport(date, reportType);
                    }
                  }}
                  minDate={moment.utc(selectedBookingYear.fromDate).toDate()}
                  placeholder={intl.formatMessage(
                    { id: "PLACEHOLDER" },
                    {
                      label: intl
                        .formatMessage({
                          id: "GENERATE_BALANCE_ULTIMO.END_DATE",
                        })
                        .toLocaleLowerCase(),
                    }
                  )}
                />
                <select
                  className="form-select ms-3"
                  value={reportType}
                  onChange={(e) => setReportType(e.target.value as ReportType)}
                >
                  <option value={ReportType.All}>{intl.formatMessage({ id: "ALL_REPORT" })}</option>
                  <option value={ReportType.Balance}>{intl.formatMessage({ id: "BALANCE_REPORT" })}</option>
                  <option value={ReportType.Earnings}>{intl.formatMessage({ id: "EARNINGS_REPORT" })}</option>
                </select>
              </div>
            </div>
            <div>
                <button
                  type="button"
                  onClick={() => CreateOpeningBalanceBtnClicked()}
                  disabled={isLoading || isLoading2}
                  className="btn btn-primary btn-md"
                >
                  {intl.formatMessage({ id: "GENERATE_OPENING_BALANCE.TITLE" })}
                </button>
              </div>
            <div className="card-body pdfViewer">
              {error && (
                <div className="alert alert-warning" role="alert">
                  {error}
                </div>
              )}
              {fileObj ? (
                <Iframe
                  key={"IframeAccounts"}
                  url={fileObj + "#navpanes=0&statusbar=0&toolbar=0&view=FitH"}
                  width="100%"
                  id="myId"
                  className="iframe pdfIframe"
                  display="initial"
                  position="relative"
                />
              ) : isLoading || isLoading2 ? (
                <Spinner
                  className="mx-auto spinner-border"
                  animation="border"
                  variant="primary"
                />
              ) : null}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default GenerateOpeningBalance;
