import { Outlet } from "react-router-dom";
import CoaHeader from "./CoaHeader/CoaHeader";
import CoaTable from "./CoaTable/CoaTable";

const CoaCard = () => {
  return (
    <>
      <CoaHeader />
      <CoaTable />
      <Outlet />
    </>
  );
};

export default CoaCard;
