
import { FC } from 'react'
import { LanguageContextInterface, getLanguage, initialStateLanguage, useLang } from './Paneli18n'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/nl'

import enMessages from './messages/en.json'
import nlMessages from './messages/nl.json'
import { WithChildren } from '../helpers'

const allMessages = {
  en: enMessages,
  nl: nlMessages,
}

const I18nProvider: FC<WithChildren> = ({ children }) => {
  const locale = useLang()

  const languagesUiEvents: LanguageContextInterface = {}

  const messages = allMessages[locale ? locale?.language ?? getLanguage() : initialStateLanguage]
  return (
    <IntlProvider locale={getLanguage()} defaultLocale="nl" messages={messages}>
      {children}
    </IntlProvider>
  )
}

export { I18nProvider }
