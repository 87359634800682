import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState, useCallback } from "react";
import { IntlShape, useIntl } from "react-intl";
import * as yup from "yup";
import { useDynamicValidationSchema } from "../../../_panel/helpers/useDynamicValidationSchema";
import { intlRequiredMessage } from "../../../_panel/helpers/validation";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import { BookingYear } from "../../../app/administrationSlice/administrationSlice";
import { RootState } from "../../../app/RootReducer";
import { shallowEqual, useSelector } from "react-redux";
import FormikDatepickerComponent from "../../../_panel/partials/formik/FormikDatepickerComponent";
import moment from 'moment-timezone';
import { useBalanceUltimoReportMutation, useDataUltimoReportMutation, useEarningsUltimoReportMutation, useExcelUltimoReportMutation } from "../../../app/apiService";
import Iframe from "react-iframe";
import { Spinner } from "react-bootstrap";
import saveAs from "file-saver";
import ReportsList from '../ReportsList';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from 'axios';
import { isConditionalExpression } from "typescript";
import UserService from '../../../app/oauth/UserService';
import { Modal, Button } from 'react-bootstrap';

enum ReportType {
  Balance = 'BALANCE',
  Earnings = 'EARNINGS',
  All = 'ALL'
}
type BBData = {
  isBalance: number;
  companyName: string;
  companyNo: number;
  bookingYearsNo: number;
  bookYear: number;
  bookingYearFrom: string;
  bookingYearTo: string;
  dateUltimo: string;
  accounts: Array<{
    account: string;
    description: string;
    debet: string;
    credit: number;
  }>;
  balanceDebet: number;
  balanceCredit: string;
  sumAllBalances: string;
  journals?: any[];
};

const GenerateBalanceSchema = (intl: IntlShape) =>
  yup.object().shape({
    dateUltimo: yup
      .date()
      .label(intl.formatMessage({ id: "GENERATE_BALANCE_ULTIMO.END_DATE" }))
      .required(intlRequiredMessage(intl)),
    isBalanceReport: yup.boolean().required(),
  });

const GenerateBalanceUltimoCard = () => {
  const iframeRef = useRef();
  const [fileObj, setFileObj] = useState<string | null>(null);
  const [data, setData] = useState<BBData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [dataUltimoReport, { isLoading: isLoading2 }] = useDataUltimoReportMutation();
  const [excelUltimoReport] = useExcelUltimoReportMutation({});
  const [balanceUltimoReport, { isLoading }] = useBalanceUltimoReportMutation();
  const [earningsUltimoReport] = useEarningsUltimoReportMutation();
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration?.bookingYears,
    shallowEqual
  ) as BookingYear;
  const formRef: React.RefObject<any> = useRef();
  const validationSchema = useDynamicValidationSchema(GenerateBalanceSchema);
  const intl = useIntl();
  const [reportType, setReportType] = useState<ReportType>(ReportType.All);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadError, setDownloadError] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [showMetadataModal, setShowMetadataModal] = useState(false);
  const [fileMetadata, setFileMetadata] = useState<any>(null);
  const [currentStep, setCurrentStep] = useState(1);

  const fetchData = async (date: Date, type: ReportType) => {
    try {
      const token = UserService.getToken();
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/api/reports/ultimo`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        data: {
          dateUltimo: moment.utc(date).format("YYYY-MM-DD"),
          allowDatesOutsideCurrentBookingYear: false,
          reportType: type,
          companyId: selectedBookingYear.companyId
        }
      });

      return response.data;
    } catch (error: any) {
      console.error('Error fetching data:', error);
      if (error.response?.status === 401) {
        UserService.doLogin();
      }
      throw error;
    }
  };

  const generateReport = useCallback(async (date: Date, type: ReportType) => {
    try {
      setIsGenerating(true);
      setFileObj(null);
      setData(null);
      setDownloadError('');

      // First API call - Get initial data
      const data = await dataUltimoReport({
        dateUltimo: moment.utc(date).format("YYYY-MM-DD"),
        allowDatesOutsideCurrentBookingYear: false,
        reportType: type,
        companyId: selectedBookingYear.companyId
      }).unwrap();
      
      setData(data);

      // Second API call - Based on report type
      let response;
      switch (type) {
        case ReportType.All:
          const dataPreviousYear = await dataUltimoReport({
            dateUltimo: moment.utc(date).subtract(1, 'year').format("YYYY-MM-DD"),
            allowDatesOutsideCurrentBookingYear: true,
            reportType: type,
            companyId: selectedBookingYear.companyId
          }).unwrap();

          response = await excelUltimoReport({ 
            data, 
            dataPreviousYear 
          }).unwrap();
          break;

        case ReportType.Balance:
          response = await balanceUltimoReport({ data }).unwrap();
          break;

        case ReportType.Earnings:
          response = await earningsUltimoReport({ data }).unwrap();
          break;
      }

      setFileObj(response);
    } catch (error) {
      console.error('Error generating report:', error);
      setDownloadError('Failed to generate report');
    } finally {
      setIsGenerating(false);
    }
  }, [
    dataUltimoReport, 
    excelUltimoReport, 
    balanceUltimoReport, 
    earningsUltimoReport, 
    selectedBookingYear.companyId
  ]);

  useEffect(() => {
    generateReport(
      moment.utc(selectedBookingYear.toDate).subtract(1, "days").toDate(),
      reportType
    );
  }, [selectedBookingYear.toDate, reportType, generateReport]);

  useEffect(() => {
    formRef?.current?.validateForm();
  }, [validationSchema]);

  const printBtnClicked = () => {
    if (fileObj) {
      window.open(fileObj, "PRINT");
    }
  };

  const downloadExcelBtnClicked = async () => {
    if (data) {
      setDownloadProgress(0);
      setDownloadError('');
      setCurrentStep(1);

      try {
        // Step 1: Get file metadata
        const token = UserService.getToken();
        const metadataResponse = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}/api/reports/file-metadata`,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
          data: {
            companyName: data.companyName,
            dateUltimo: moment(data.dateUltimo).format('YYYY-MM-DD'),
            reportType: reportType,
            bookYear: data.bookYear,
            journals: data.journals,
            companyId: selectedBookingYear.companyId
          }
        });

        setFileMetadata(metadataResponse.data);
        setShowMetadataModal(true);
      } catch (error) {
        setDownloadError('Error fetching file metadata');
        console.error('Error:', error);
      }
    }
  };

  // Add a new function to handle secure PDF viewing
  const handlePdfDisplay = (pdfUrl: string) => {
    // Add necessary parameters to bypass security restrictions
    const secureUrl = `${pdfUrl}#navpanes=0&statusbar=0&toolbar=0&view=FitH`;
    
    return (
      <Iframe
        key={"IframeAccounts"}
        url={secureUrl}
        width="100%"
        id="myId"
        className="iframe pdfIframe"
        display="initial"
        position="relative"
        sandbox={["allow-same-origin", "allow-scripts", "allow-popups", "allow-forms"]}
        loading="lazy"
        allowFullScreen
      />
    );
  };

  // Update the modal to display all metadata
  const MetadataModal = () => (
    <Modal show={showMetadataModal} onHide={() => {
      setShowMetadataModal(false);
      setCurrentStep(1);
    }}>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: "FILE_METADATA" })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {fileMetadata && (
          <div className="metadata-container">
            <div className="metadata-section">
              <h5>{intl.formatMessage({ id: "GENERAL_INFO" })}</h5>
              <p><strong>{intl.formatMessage({ id: "COMPANY" })}:</strong> {fileMetadata.companyName}</p>
              <p><strong>{intl.formatMessage({ id: "BOOK_YEAR" })}:</strong> {fileMetadata.bookYear}</p>
              <p><strong>{intl.formatMessage({ id: "DATE_ULTIMO" })}:</strong> {fileMetadata.dateUltimo}</p>
              <p><strong>{intl.formatMessage({ id: "REPORT_TYPE" })}:</strong> {fileMetadata.reportType}</p>
            </div>

            <div className="metadata-section">
              <h5>{intl.formatMessage({ id: "FILE_INFO" })}</h5>
              <p><strong>{intl.formatMessage({ id: "FILE_NAME" })}:</strong> {fileMetadata.file.name}</p>
              <p>
                <strong>{intl.formatMessage({ id: "FILE_SIZE" })}:</strong>{' '}
                {fileMetadata.estimatedSize ? 
                  `${(fileMetadata.estimatedSize / (1024 * 1024)).toFixed(2)} MB` : 
                  'Size unknown'}
              </p>
            </div>

            <div className="metadata-section">
              <h5>{intl.formatMessage({ id: "INCLUDED_SHEETS" })}</h5>
              <ul>
                {fileMetadata.sheets?.map((sheet: string) => (
                  <li key={sheet}>{sheet}</li>
                ))}
              </ul>
            </div>

            <div className="metadata-section">
              <p>
                <strong>{intl.formatMessage({ id: "PRODUCTION_TIME" })}:</strong>{' '}
                <span className={!isTimeValid(parseFileDateTime(fileMetadata.file.name)) ? 'blink' : ''}>
                  {parseFileDateTime(fileMetadata.file.name)}
                </span>
              </p>
              {!isTimeValid(parseFileDateTime(fileMetadata.file.name)) && (
                <p className="text-danger fw-bold mt-2">
                  ⚠️ WARNING: THIS MIGHT BE AN OLD FILE
                </p>
              )}
            </div>

            {downloadProgress > 0 && downloadProgress < 100 && (
              <div className="progress mt-3">
                <div 
                  className="progress-bar" 
                  role="progressbar" 
                  style={{ width: `${downloadProgress}%` }} 
                  aria-valuenow={downloadProgress} 
                  aria-valuemin={0} 
                  aria-valuemax={100}
                >
                  {downloadProgress}%
                </div>
              </div>
            )}
            
            {downloadError && (
              <div className="alert alert-danger mt-3">
                {downloadError}
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
          setShowMetadataModal(false);
          setCurrentStep(1);
        }}>
          {intl.formatMessage({ id: "CANCEL" })}
        </Button>
        <Button 
          variant="primary" 
          onClick={proceedWithDownload}
          disabled={downloadProgress > 0 && downloadProgress < 100}
        >
          {downloadProgress > 0 && downloadProgress < 100 
            ? `${intl.formatMessage({ id: "DOWNLOADING" })}... ${downloadProgress}%`
            : intl.formatMessage({ id: "DOWNLOAD" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const proceedWithDownload = async () => {
    try {
      if (!data || !fileMetadata) {
        throw new Error('No data available');
      }

      const token = UserService.getToken();
      const encodedCompanyName = encodeURIComponent(data.companyName);
      const encodedFileName = encodeURIComponent(fileMetadata.file.name);
      
      console.log('Downloading file:', encodedFileName); // Debug log
      
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/reports/download/${encodedCompanyName}/${encodedFileName}`,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true,
      });

      const fileName = fileMetadata.file.name;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
      setShowMetadataModal(false);
    } catch (error) {
      setDownloadError('Error downloading file');
      console.error('Download error:', error);
    } finally {
      setDownloadProgress(0);
    }
  };

  const parseFileDateTime = (fileName: string): string => {
    const match = fileName.match(/(\d{8})T(\d{6})/);
    if (!match) return '';
    
    const dateTimeStr = `${match[1]}T${match[2]}`;
    const utcDateTime = moment.utc(dateTimeStr, 'YYYYMMDDTHHmmss');
    return utcDateTime.local().format('DD-MM-YYYY HH:mm:ss [CEST]');
  };

  const isTimeValid = (dateTimeStr: string): boolean => {
    if (!dateTimeStr) return true; // Return true if no date to prevent warning
    const fileTime = moment(dateTimeStr, 'DD-MM-YYYY HH:mm:ss [CEST]');
    const now = moment();
    const diffSeconds = Math.abs(now.diff(fileTime, 'seconds'));
    console.log('Time difference in seconds:', diffSeconds); // Debug log
    return diffSeconds <= 15;
  };

  return (
    <>
      <Formik
        initialValues={{
          dateUltimo: moment
            .utc(selectedBookingYear.toDate)
            .subtract(1, "days")
            .toDate(),
        }}
        validationSchema={validationSchema}
        innerRef={formRef}
        validateOnBlur={true}
        onSubmit={(values) => {
          generateReport(values.dateUltimo, reportType);
        }}
      >
        {({ isSubmitting, submitForm, values }) => (
          <Form className="card">
            {(isLoading || isLoading2) && <ProgressBar />}
            <div className="card-header align-items-center justify-content-between">
              <div className="d-flex align-items-baseline">
                <h3 className="card-title">
                  {intl.formatMessage({
                    id: "GENERATE_REPORT.TITLE",
                  })}
                </h3>
                <Field
                  name="dateUltimo"
                  className="ms-3"
                  showMonthYearPicker={false}
                  labelClassName="form-label"
                  component={FormikDatepickerComponent}
                  maxDate={moment
                    .utc(selectedBookingYear.toDate)
                    .subtract(1, "days")
                    .toDate()}
                  onChange={(date: Date) => {
                    if (!(isLoading || isLoading2) && !isSubmitting) {
                      generateReport(date, reportType);
                    }
                  }}
                  minDate={moment.utc(selectedBookingYear.fromDate).toDate()}
                  placeholder={intl.formatMessage(
                    { id: "PLACEHOLDER" },
                    {
                      label: intl
                        .formatMessage({
                          id: "GENERATE_BALANCE_ULTIMO.END_DATE",
                        })
                        .toLocaleLowerCase(),
                    }
                  )}
                />
                <select
                  className="form-select ms-3"
                  value={reportType}
                  onChange={(e) => setReportType(e.target.value as ReportType)}
                >
                  <option value={ReportType.All}>{intl.formatMessage({ id: "ALL_REPORT" })}</option>
                  <option value={ReportType.Balance}>{intl.formatMessage({ id: "BALANCE_REPORT" })}</option>
                  <option value={ReportType.Earnings}>{intl.formatMessage({ id: "EARNINGS_REPORT" })}</option>
                </select>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => printBtnClicked()}
                  disabled={isLoading || isLoading2}
                  className="btn btn-primary btn-md"
                >
                  {intl.formatMessage({ id: "PRINT" })}
                </button>
                <button
                  type="button"
                  onClick={downloadExcelBtnClicked}
                  disabled={isLoading || isLoading2}
                  className="btn btn-primary btn-md ms-2"
                >
                  {downloadProgress > 0 && downloadProgress < 100 
                    ? `${intl.formatMessage({ id: "DOWNLOADING" })}... ${downloadProgress}%` 
                    : intl.formatMessage({ id: "DOWNLOAD_EXCEL" })}
                </button>
                {downloadError && (
                  <div className="text-danger mt-2">
                    {downloadError}
                  </div>
                )}
              </div>
            </div>
            {error && (
              <div className="alert alert-danger mx-3 mt-3" role="alert">
                {error}
              </div>
            )}
            <div className="card-body pdfViewer">
              {fileObj && reportType !== ReportType.All ? (
                <div className="pdf-container">
                  {handlePdfDisplay(fileObj)}
                  <div className="fallback-message">
                    <a 
                      href={fileObj}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-link"
                    >
                      {intl.formatMessage({ id: "OPEN_IN_NEW_WINDOW" })}
                    </a>
                  </div>
                </div>
              ) : isGenerating ? (
                <Spinner
                  className="mx-auto spinner-border"
                  animation="border"
                  variant="primary"
                />
              ) : reportType === ReportType.All ? (
                <div className="text-center">
                  {intl.formatMessage({ id: "NO_PREVIEW_AVAILABLE" })}
                </div>
              ) : null}
            </div>
          </Form>
        )}
      </Formik>
      <ReportsList />
      <MetadataModal />
    </>
  );
};

// Add some CSS to style the container and fallback message
const styles = `
.pdf-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fallback-message {
  text-align: center;
  margin-top: 10px;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.blink {
  animation: blink 1s linear infinite;
}

@keyframes blinkColor {
  0% { color: black; }
  50% { color: red; }
  100% { color: black; }
}

.blink {
  animation: blinkColor 1s linear infinite;
}
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default GenerateBalanceUltimoCard;
