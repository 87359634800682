import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useIntl } from "react-intl";
import * as yup from "yup";
import { useAddNewMultiEntryBookingMutation } from "../../app/apiService";
import { toast } from "react-toastify";
import * as XLSX from 'xlsx';
import { Spinner } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { RootState } from "../../app/RootReducer";
import moment from 'moment';

const EOYImportJournalEntries: React.FC = () => {
  const intl = useIntl();
  const [addNewMultiEntryBooking] = useAddNewMultiEntryBookingMutation();
  const [isImporting, setIsImporting] = useState(false);

  const { bookingYears } = useSelector((state: RootState) => state.root.administration);
  const companyNo = bookingYears?.companyId ?? 0;

  const isExcelOrCsvFile = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const allowedExtensions = ['.xlsx', '.xls', '.csv'];
      const fileName = file.name.toLowerCase();
      resolve(allowedExtensions.some(ext => fileName.endsWith(ext)));
      // const reader = new FileReader();
      // reader.onloadend = (e) => {
      //   if (e.target?.result) {
      //     const arr = new Uint8Array(e.target.result as ArrayBuffer).subarray(0, 4);
      //     const header = arr.reduce((acc, byte) => acc + byte.toString(16), '');
      //     console.log('File header:', header); // Add this line for debugging
      //     // Expanded list of Excel file signatures
      //     const excelSignatures = [
      //       'd0cf11e0', // Legacy Excel formats
      //       '504b0304', // Modern Excel formats (XLSX)
      //       '504b0506', 
      //       '504b0708',
      //       '0908100000060500', // Another possible XLSX signature
      //     ];
      //     const isExcel = excelSignatures.some(sig => header.startsWith(sig));
      //     console.log('Is Excel file:', isExcel); // Add this line for debugging
      //     resolve(isExcel);
      //   } else {
      //     console.log('No file data found'); // Add this line for debugging
      //     resolve(false);
      //   }
      // };
      // reader.readAsArrayBuffer(file);
    });
  };

  const validationSchema = yup.object().shape({
    file: yup
      .mixed()
      .required(intl.formatMessage({ id: "IMPORT.FILE_REQUIRED" }))
      .test("fileFormat", intl.formatMessage({ id: "IMPORT.INVALID_FILE_TYPE" }), async (value) => {
        if (!value) return false;
        return await isExcelOrCsvFile(value as File);
      }),
  });

  interface JournalEntry {
    diaryNo: string;
    companyNo: string;
    bookingYearsNo: string;
    Date: string;
    bookingHeaderId: string;
    Description: string;
    projectNumber: string;
    accountNo: string;
    DebitAmount: number;
    CreditAmount: number;
  }

  const requiredHeaders = [
    'diaryNo', 'Date', 'bookingHeaderId', 'Description',
    'accountNo', 'DebitAmount', 'CreditAmount'
  ];

  const convertExcelDate = (excelDate: string | number): string => {
    // If it's already a properly formatted string, return as is
    if (typeof excelDate === 'string' && excelDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return excelDate;
    }

    try {
      // Use XLSX's built-in date parser
      const parsed = XLSX.SSF.parse_date_code(Number(excelDate));
      return `${parsed.y.toString().padStart(4, '0')}-${(parsed.m).toString().padStart(2, '0')}-${parsed.d.toString().padStart(2, '0')}`;
    } catch (error) {
      // If XLSX parsing fails, try moment as fallback
      const momentDate = moment(excelDate);
      if (momentDate.isValid()) {
        return momentDate.format('YYYY-MM-DD');
      }
      throw new Error(`Invalid date format: ${excelDate}`);
    }
  };

  const handleXLSXFileUpload = async (file: File) => {
    setIsImporting(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      let jsonData = XLSX.utils.sheet_to_json(worksheet) as JournalEntry[];

      if (jsonData.length === 0 || !requiredHeaders.every(header => header in jsonData[0])) {
        toast.error(intl.formatMessage({ id: "IMPORT.INVALID_HEADERS" }));
        setIsImporting(false);
        return;
      }

      // Group entries by bookingHeaderId
      const groupedEntries = jsonData.reduce((acc, entry) => {
        const key = entry.bookingHeaderId;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(entry);
        return acc;
      }, {} as Record<string, JournalEntry[]>);

      // Create bookingData for each group
      for (const [bookingHeaderId, entries] of Object.entries(groupedEntries)) {
        const firstEntry = entries[0];
        const bookingData = {
          diaryNo: firstEntry.diaryNo.toString(),
          companyNo: companyNo,
          bookingYearsNo: bookingYears?.id,
          date: convertExcelDate(firstEntry.Date),
          bookingHeaderId: bookingHeaderId,
          projectNumber: 0,
          description: firstEntry.Description,
          bookings: entries.map(entry => ({
            accountNo: entry.accountNo.toString(),
            amountDebet: entry.DebitAmount,
            amountCredit: entry.CreditAmount,
          })),
        };

        try {
          await addNewMultiEntryBooking(bookingData).unwrap();
        } catch (error) {
          console.error("Error adding booking:", error);
          toast.error(intl.formatMessage({ id: "IMPORT.ERROR_ADDING_BOOKING" }));
        }
      }

      setIsImporting(false);
      toast.success(intl.formatMessage({ id: "IMPORT.SUCCESS" }));
    };
    reader.readAsArrayBuffer(file);
  };

  const handleCSVFileUpload = async (file: File) => {
    setIsImporting(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target?.result as string;
      const rows = text.split('\n');
      const jsonData = rows.slice(1).map(row => {
        const values = row.split(',');
        return {
          diaryNo: values[0],
          Date: values[1],
          bookingHeaderId: values[2],
          Description: values[3],
          accountNo: values[4],
          DebitAmount: parseFloat(values[5]),
          CreditAmount: parseFloat(values[6]),
        } as JournalEntry;
      });

      if (jsonData.length === 0 || !requiredHeaders.every(header => header in jsonData[0])) {
        toast.error(intl.formatMessage({ id: "IMPORT.INVALID_HEADERS" }));
        setIsImporting(false);
        return;
      }

      // Group entries by bookingHeaderId
      const groupedEntries = jsonData.reduce((acc, entry) => {
        const key = entry.bookingHeaderId;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(entry);
        return acc;
      }, {} as Record<string, JournalEntry[]>);

      // Create bookingData for each group
      for (const [bookingHeaderId, entries] of Object.entries(groupedEntries)) {
        const firstEntry = entries[0];
        const bookingData = {
          diaryNo: firstEntry.diaryNo,
          companyNo: companyNo,
          bookingYearsNo: bookingYears?.id,
          date: convertExcelDate(firstEntry.Date),
          bookingHeaderId: bookingHeaderId,
          description: firstEntry.Description,
          projectNumber: 0,
          bookings: entries.map(entry => ({
            accountNo: entry.accountNo,
            amountDebet: entry.DebitAmount,
            amountCredit: entry.CreditAmount,
          })),
        };

        try {
          await addNewMultiEntryBooking(bookingData).unwrap();
        } catch (error) {
          console.error("Error adding booking:", error);
          toast.error(intl.formatMessage({ id: "IMPORT.ERROR_ADDING_BOOKING" }));
        }
      }

      setIsImporting(false);
      toast.success(intl.formatMessage({ id: "IMPORT.SUCCESS" }));
    };
    reader.readAsText(file);
  };

  // Add this new function to generate and download the example file
  const downloadExampleFile = () => {
    const exampleData = [
      [
        'Note: This line is for explanation only, do not include in the actual file. '+
        'Entries with the same bookingHeaderId form a multi-entry journal. ' +
        'bookingHeaderId: positive = A/P Invoice, negative = general multi-entry journal'
      ],
      [...requiredHeaders],
      ['1', '2023-05-01', 211, 'Sample Transaction', '1000', 100, 0],
      ['1', '2023-05-01', 211, 'Sample Transaction', '2000', 0, 100],
      ['1', '2023-05-01', -201, 'Sample Transaction', '1000', 100, 0],
      ['1', '2023-05-01', -201, 'Sample Transaction', '2000', 0, 100],
      ['1', '2023-05-02', 2721, 'Another Transaction', '3000', 200, 0,],
      ['', '', 2721, '', '4000', 0, 150],
      ['', '', 2721, '', '5000', 0, 50]
    ];
    const ws = XLSX.utils.aoa_to_sheet(exampleData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "example_journal_entries.xlsx");
  };

  return (
    <Formik
      initialValues={{
        file: null as File | null,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (values.file) {
          handleXLSXFileUpload(values.file);
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, setFieldValue, errors, touched }) => (
        <Form className="card">
          <div className="card-header">
            <h3>{intl.formatMessage({ id: "IMPORT.TITLE_CSV_OR_XLSX" })}</h3>
          </div>
          <div className="card-body">
            <div className="mb-3">
              {errors.file && touched.file && <div className="text-danger">{errors.file}</div>}
            </div>
            <div className="mb-3">
              <button
                type="submit"
                disabled={isSubmitting || isImporting}
                className="btn btn-primary"
              >
                {isImporting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  intl.formatMessage({ id: "IMPORT.SUBMIT" })
                )}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EOYImportJournalEntries;
