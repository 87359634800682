import React from "react";
import DiariesHeader from "./DiariesHeader/DiariesHeader";
import DiariesTable from "./DiariesTable/DiariesTable";
import { Outlet } from "react-router-dom";

const DiariesCard = () => {
  return (
    <>
      <DiariesHeader />
      <DiariesTable />
      <Outlet />
    </>
  );
};

export default DiariesCard;
