import GenerateYearReport from "../features/GenerateReport/GenerateYearReport";
const OverviewsPage = () => {
  return (
    <>
      <div className="page-header d-print-none"></div>
      <div className="row page-body">
        <div className="col-md-6">
          <GenerateYearReport />
        </div>
      </div>
    </>
  );
};

export default OverviewsPage;
