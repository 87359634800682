import React from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router";
import { CoaInterface } from "../CoaModel";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import CoaForm from "../CoaForm/CoaForm";
import { toast } from "react-toastify";
import {
  useGetAccountsByIdQuery,
  useUpdateAccountsMutation,
} from "../../../app/apiService";

const CoaEdit = () => {
  const { id } = useParams();
  const [updateAccount, { isLoading: isLoadingUpdateAccount }] =
    useUpdateAccountsMutation();
  const { data: account, isError, isLoading } = useGetAccountsByIdQuery(id);
  const navigate = useNavigate();
  const intl = useIntl();
  const data: CoaInterface = {};
  const onCancel = () => {
    navigate(-1);
  };
  if (isError) {
    navigate(-1);
  }
  const onFormSubmit = (
    data: CoaInterface,
    setSubmitting: (status: boolean) => void
  ) => {
    updateAccount({ ...data, id })
      .unwrap()
      .then(() => {
        setSubmitting(true);
        toast.success(intl.formatMessage({ id: "COA.EDITED" }));
        onCancel();
      })
      .catch((err) => {
        setSubmitting(false);
        if (err.data?.error === "Account number is in use") {
          toast.error(intl.formatMessage({ id: "COA.NUMBER_IN_USE" }));
        } else {
          toast.error(intl.formatMessage({ id: "COA.EDITED_FAILED" }));
        }
      });
  };
  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">
                {intl.formatMessage({ id: "ASIDE.GL" })}
              </div>
              <h2 className="page-title">
                {intl.formatMessage({ id: "COA.EDIT" })}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 page-body">
        <div className="card">
          {(isLoading || isLoadingUpdateAccount) && <ProgressBar />}
          <CoaForm
            isEditMode={true}
            data={
              account ?? {
                number: "",
                description: "",
                parentAccount: "",
                isBalance: false,
                isSubAdm: false,
              }
            }
            onCancel={onCancel}
            formSubmitted={onFormSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default CoaEdit;
