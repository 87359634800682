import React from "react";

const ProgressBar = () => {
  return (
    <div className="progress progress-sm">
      <div className="progress-bar progress-bar-indeterminate" />
    </div>
  );
};

export default ProgressBar;
