import { Route, Routes } from "react-router";
import MasterTablesCard from "../features/masterTables/MasterTablesCard";

const MasterTablesPage = () => {
  return (
    <Routes>
      <Route element={<MasterTablesCard />} path="*" />
    </Routes>
  );
};

export default MasterTablesPage;
