import React from "react";
import GenerateBalanceUltimoHeader from "../features/GenerateReport/GenerateBalanceUltimo/GenerateBalanceUltimoHeader";
import GenerateBalanceUltimoCard from "../features/GenerateReport/GenerateBalanceUltimo/GenerateBalanceUltimoCard";

const GenerateBalanceUltimoPage = () => {
  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <GenerateBalanceUltimoHeader />
        </div>
      </div>
      <div className="row page-body">
        <div className="col-md-12">
          <GenerateBalanceUltimoCard />
        </div>
      </div>
    </>
  );
};

export default GenerateBalanceUltimoPage;
