import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useDynamicValidationSchema } from "../../../_panel/helpers/useDynamicValidationSchema";
import * as yup from "yup";
import {
  intlMaxMessage,
  intlRequiredMessage,
} from "../../../_panel/helpers/validation";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import FormikSelectComponent from "../../../_panel/partials/formik/FormikSelectComponent";
import {
  useGetAccountsQuery,
  useGetUserProfileQuery,
  useUpdateMasterTableMutation,
} from "../../../app/apiService";
import { CoaInterface } from "../../chartOfAccounts/CoaModel";
import { toast } from "react-toastify";
import FormikInputComponent from "../../../_panel/partials/formik/FormikInputComponent";

const OffsetAccountSchema = (intl: IntlShape) =>
  yup.object().shape({
    bankCOAAccountNo: yup
      .string()
      .label(intl.formatMessage({ id: "BOOKING.ACCOUNT_NUMBER" }))
      .required(intlRequiredMessage(intl))
      .max(7, intlMaxMessage(intl)),
    rightClickAutoPercentageOffset: yup
      .number()
      .label(intl.formatMessage({ id: "MASTERTABLES.AUTOMATIC_PERCENTAGE" }))
      .required(intlRequiredMessage(intl))
      .min(0)
      .max(100),
    earningsAccountNumber: yup
      .string()
      .label(intl.formatMessage({ id: "BOOKING.ACCOUNT_NUMBER" }))
      .required(intlRequiredMessage(intl))
      .max(7, intlMaxMessage(intl)),
  });
const AutomaticOffsetAccount = () => {
  const [updateMasterTable, { isLoading: isUpdateLoading }] =
    useUpdateMasterTableMutation();
  const intl = useIntl();
  const formRef: React.RefObject<any> = useRef();

  const validationSchema = useDynamicValidationSchema(OffsetAccountSchema);
  const { data } = useGetUserProfileQuery({});
  useEffect(() => {
    formRef?.current?.validateForm();
  }, [validationSchema]);
  const { data: { records: accountTypes = [] } = {}, isLoading } =
    useGetAccountsQuery({
      page: 0,
      perPage: 1000,
    }) || {};
  const accountOptions =
    accountTypes
      .map((item: CoaInterface) => {
        return {
          value: item.number,
          label:
            item.number + (item.description ? " | " + item.description : ""),
        };
      })
      .filter((item: any) => item.value !== "0") ?? [];
  return (
    <Formik
      initialValues={{
        bankCOAAccountNo: data?.bankCOAAccountNo,
        rightClickAutoPercentageOffset: data?.rightClickAutoPercentageOffset,
        earningsAccountNumber: data?.earningsAccountNumber,
      }}
      validationSchema={validationSchema}
      innerRef={formRef}
      validateOnBlur={true}
      onSubmit={(values, { setSubmitting }) => {
        updateMasterTable(values)
          .unwrap()
          .then(() => {
            toast.success(
              intl.formatMessage({
                id: "MASTERTABLES.AUTOMATIC_OFFSET_ACCOUNT_SUCCESSFULY_UPDATED",
              })
            );
          })
          .catch(() => {
            toast.success(
              intl.formatMessage({
                id: "MASTERTABLES.AUTOMATIC_OFFSET_ACCOUNT_FAILED",
              })
            );
          })
          .finally(() => setSubmitting(false));
      }}
    >
      {({ isSubmitting }) => (
        <Form className="card">
          {(isLoading || isUpdateLoading) && <ProgressBar />}
          <div className="card-header">
            <h3 className="card-title">
              {intl.formatMessage({
                id: "MASTERTABLES.AUTOMATIC_OFFSET_ACCOUNT",
              })}
            </h3>
          </div>
          <div className="card-body">
            <Field
              name="bankCOAAccountNo"
              className="col-12 mb-3"
              labelClassName="form-label"
              component={FormikSelectComponent}
              row
              placeholder={intl.formatMessage({
                id: "BOOKING.ACCOUNT_NUMBER",
              })}
              label={intl.formatMessage({
                id: "BOOKING.ACCOUNT_NUMBER",
              })}
              required
              options={accountOptions}
            />
            <Field
              name="rightClickAutoPercentageOffset"
              className="col-12 mb-3"
              labelClassName="form-label"
              component={FormikInputComponent}
              placeholder={intl.formatMessage({
                id: "MASTERTABLES.AUTOMATIC_PERCENTAGE",
              })}
              label={intl.formatMessage({
                id: "MASTERTABLES.AUTOMATIC_PERCENTAGE",
              })}
              required
            />
          </div>
          <div className="card-header">
            <h3 className="card-title">
              {intl.formatMessage({
                id: "MASTERTABLES.EARNINGS_ACCOUNT_NUMBER",
              })}
            </h3>
          </div>
          <div className="card-body">
            <Field
              name="earningsAccountNumber"
              className="col-12 mb-3"
              labelClassName="form-label"
              component={FormikSelectComponent}
              row
              placeholder={intl.formatMessage({
                id: "BOOKING.ACCOUNT_NUMBER",
              })}
              label={intl.formatMessage({
                id: "BOOKING.ACCOUNT_NUMBER",
              })}
              required
              options={accountOptions}
            />
          </div>
          <div className="card-footer text-end">
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary"
            >
              {intl.formatMessage({ id: "SUBMIT" })}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AutomaticOffsetAccount;
