
import { useIntl } from "react-intl";


const HomePageHeader = () => {
  const intl = useIntl();

  const formatDate = (date: string | Date | undefined) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString();
  };

  return (
    <header className="page-header">
      <h2 className="page-title">
        {intl.formatMessage({ id: "ASIDE.HOME" })}
      </h2>
    </header>
  );
};

export default HomePageHeader;
