import { isEqual, isFunction } from 'lodash'

import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useState
} from 'react'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY ?? 'language'

type Props = 'en' | 'nl'

export const initialStateLanguage: Props = 'nl'

export interface LanguageContextInterface {
  language?: Props
  setLanguage?: (nextLanguage: string) => void
}

export interface LanguageUiContextInterface {
  LanguagesUIEvents: LanguageContextInterface
  children: React.ReactNode
}
export const getLanguage = (): Props => {
  const ls = localStorage.getItem(I18N_CONFIG_KEY) ?? initialStateLanguage
  if (ls) {
    try {
      return ls as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialStateLanguage
}

const InitialState = {
  language: getLanguage(),
  setLanguage: (nextLanguage: string) => {},
}



// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, lang)
}

const PanelI18nContext = createContext<LanguageContextInterface>(InitialState)

const useLang = () => {
  return useContext(PanelI18nContext)
}

export const ProjectsUIConsumer = PanelI18nContext.Consumer

const PanelI18nProvider: FC<LanguageUiContextInterface> = ({ LanguagesUIEvents, children }) => {
  const [language, setLanguageBase] = useState<Props>(getLanguage())

  const setLanguage = useCallback((nextLanguage: any) => {
    setLanguageBase((prevLanguage) => {
      if (isFunction(nextLanguage)) {
        nextLanguage = nextLanguage(prevLanguage)
      }

      if (isEqual(prevLanguage, nextLanguage)) {
        return prevLanguage
      }

      return nextLanguage
    })
  }, [])

  const value: LanguageContextInterface = {
    language: language,
    setLanguage: setLanguage,
  }

  return <PanelI18nContext.Provider value={value}>{children}</PanelI18nContext.Provider>
}

export { PanelI18nProvider, useLang }

