import React from "react";

import { ThemeModeProvider } from "./theme-mode/ThemeModeProvider";
import { WithChildren } from "../helpers";
import Header from "./components/Header";
import Aside from "./components/Aside";
import { useGetUserProfileQuery } from "../../app/apiService";
import SplashScreen from "./core/SplashScreen";
import ServerError from "./core/ServerError";

const MasterLayout: React.FC<WithChildren> = ({ children }) => {
  const { isLoading, isSuccess } = useGetUserProfileQuery({});
  return (
    <ThemeModeProvider>
      <div className="page">
        <Aside />
        <Header />
        <div className="page-wrapper">
          {isLoading ? (
            <SplashScreen />
          ) : (
            <>
              {isSuccess ? (
                <div className="container-xl">{children}</div>
              ) : (
                <ServerError />
              )}
            </>
          )}
        </div>
      </div>
    </ThemeModeProvider>
  );
};

export { MasterLayout };
