import { Field, Form, Formik } from "formik";
import React, { FC, useEffect, useRef } from "react";
import { IntlShape, useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useDynamicValidationSchema } from "../../../_panel/helpers/useDynamicValidationSchema";
import {
  intlMaxMessage,
  intlMaxNumberMessage,
  intlRequiredMessage,
} from "../../../_panel/helpers/validation";
import FormikDateComponent from "../../../_panel/partials/formik/FormikDateComponent";
import FormikNumberComponent from "../../../_panel/partials/formik/FormikNumberComponent";
import FormikSelectComponent from "../../../_panel/partials/formik/FormikSelectComponent";
import FormikTextAreaComponent from "../../../_panel/partials/formik/FormikTextAreaComponent";
import { RootState } from "../../../app/RootReducer";
import { BookingYear } from "../../../app/administrationSlice/administrationSlice";
import { useGetAccountsQuery } from "../../../app/apiService";
import { parseNumber } from "../../../common/Utils";
import { CoaInterface } from "../../chartOfAccounts/CoaModel";
import { BookingInterface } from "../BookingModel";

const bookingValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    accountNo: yup
      .number()
      .required(intlRequiredMessage(intl))
      .label(intl.formatMessage({ id: "BOOKING.ACCOUNT_NO" })),

    description: yup
      .string()
      .label(intl.formatMessage({ id: "BOOKING.DESCRIPTION" }))
      .nullable()
      .max(255, intlMaxMessage(intl)),

    bookingHeaderId: yup
      .number()
      .label(intl.formatMessage({ id: "BOOKING.INVOICE" }))
      .max(2147483647, intlMaxNumberMessage(intl))
      .nullable(),

    date: yup
      .date()
      .label(intl.formatMessage({ id: "BOOKING.DATE" }))
      .required(intlRequiredMessage(intl)),

    amountCredit: yup
      .number()
      .label(intl.formatMessage({ id: "BOOKING.AMOUNT_CREDIT" }))
      .test(
        "creditOrDebet",
        intl.formatMessage({ id: "VALIDATION.REQUIRED" }),
        function (value) {
          const amountDebet = this.parent.amountDebet;
          const hasCreditOrDebit =
            value !== undefined || amountDebet !== undefined;

          if (!hasCreditOrDebit) {
            return this.createError({
              path: "amountCredit",
              message: intlRequiredMessage(intl),
            });
          }

          return true;
        }
      ),

    amountDebet: yup
      .number()
      .label(intl.formatMessage({ id: "BOOKING.AMOUNT_DEBT" }))
      .test(
        "creditOrDebet",
        intl.formatMessage({ id: "VALIDATION.REQUIRED" }),
        function (value) {
          const amountCredit = this.parent.amountCredit;
          const hasCreditOrDebit =
            value !== undefined || amountCredit !== undefined;

          if (!hasCreditOrDebit) {
            return this.createError({
              path: "amountDebet",
              message: intlRequiredMessage(intl),
            });
          }

          return true;
        }
      ),
  });

type Props = {
  data: BookingInterface;
  isEditMode?: boolean;
  onCancel: () => void;
  formSubmitted: (
    values: BookingInterface,
    setSubmitting: (status: boolean) => void
  ) => void;
};
const BookingForm: FC<Props> = (props) => {
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration.bookingYears,
    shallowEqual
  ) as BookingYear;
  const navigate = useNavigate();
  const { diaryNo } = useParams();
  const { data, onCancel, formSubmitted, isEditMode } = props;
  const { data: { records: accounts = [] } = {} } =
    useGetAccountsQuery({
      page: 0,
      perPage: 1000,
    }) || {};

  const options =
    accounts
      .map((item: CoaInterface) => {
        return {
          value: item.number,
          number: item.number,
          label:
            item.number + (item.description ? " | " + item.description : ""),
        };
      })
      .filter((item: any) => item.number !== "0") ?? [];

  const formRef: React.RefObject<any> = useRef();
  const intl = useIntl();
  const validationSchema = useDynamicValidationSchema(bookingValidationSchema);
  useEffect(() => {
    formRef?.current?.validateForm();
  }, [validationSchema]);
  useEffect(() => {
    if (isEditMode && !formRef?.current?.isSubmitting)
      formRef?.current?.setValues(data);
  }, [data]);
  useEffect(() => {
    formRef?.current?.setFieldValue("date", data.date);
  }, [data.date]);
  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      innerRef={formRef}
      onSubmit={(values, { setSubmitting, setValues }) => {
        formSubmitted(
          {
            accountNo: values.accountNo,
            diaryNo: diaryNo ?? "",
            amountCredit: parseNumber(values.amountCredit),
            amountDebet: parseNumber(values.amountDebet),
            bookingHeaderId: values.bookingHeaderId,
            bookingYearsNo: selectedBookingYear.id,
            companyNo: selectedBookingYear.companyId,
            date: values.date,
            description: values.description,
            projectNumber: "1",
            reconciledWithIdsId: values.reconciledWithIdsId,
          },
          (status: boolean) => {
            setSubmitting(false);
          }
        );
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (!isSubmitting) {
                submitForm();
                e.preventDefault();
              }
            }
          }}
        >
          <div className="card-body mt-2">
            <Field
              name="date"
              component={FormikDateComponent}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "BOOKING.DATE",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              label={intl.formatMessage({ id: "BOOKING.DATE" })}
              required
            />
            <Field
              name="accountNo"
              component={FormikSelectComponent}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "BOOKING.ACCOUNT_NO",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              label={intl.formatMessage({ id: "BOOKING.ACCOUNT_NO" })}
              required
              options={options}
              innerChild={
                <div className="col-2">
                  <button
                    onClick={() =>
                      navigate("/mastertables/chart-of-accounts/new-coa")
                    }
                    disabled={isSubmitting}
                    className="btn w-100 btn-primary ms-auto"
                  >
                    {intl.formatMessage({ id: "COA.CREATE" })}
                  </button>
                </div>
              }
            />

            <Field
              name="bookingHeaderId"
              component={FormikNumberComponent}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "BOOKING.INVOICE",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              label={intl.formatMessage({ id: "BOOKING.INVOICE" })}
            />

            <div className="row mb-3">
              <label className="col-3 col-form-label required">
                {intl.formatMessage({ id: "BOOKING.AMOUNT" })}
              </label>
              <div className="col row">
                <Field
                  name="amountDebet"
                  component={FormikNumberComponent}
                  className="mb-3 col-6"
                  labelClassName="form-label"
                  required
                  placeholder={intl.formatMessage(
                    { id: "PLACEHOLDER" },
                    {
                      label: intl
                        .formatMessage({
                          id: "BOOKING.AMOUNT_DEBT",
                        })
                        .toLocaleLowerCase(),
                    }
                  )}
                  label={intl.formatMessage({ id: "BOOKING.AMOUNT_DEBT" })}
                />
                <Field
                  name="amountCredit"
                  component={FormikNumberComponent}
                  className="mb-3 col-6"
                  labelClassName="form-label"
                  required
                  placeholder={intl.formatMessage(
                    { id: "PLACEHOLDER" },
                    {
                      label: intl
                        .formatMessage({
                          id: "BOOKING.AMOUNT_CREDIT",
                        })
                        .toLocaleLowerCase(),
                    }
                  )}
                  label={intl.formatMessage({ id: "BOOKING.AMOUNT_CREDIT" })}
                />
              </div>
            </div>
            <Field
              name="description"
              rows={3}
              component={FormikTextAreaComponent}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "BOOKING.DESCRIPTION",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              label={intl.formatMessage({ id: "BOOKING.DESCRIPTION" })}
            />
          </div>

          <div className="card-footer text-end">
            <div className="d-flex">
              <button className="btn btn-link" onClick={onCancel}>
                {intl.formatMessage({ id: "CANCEL" })}
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary ms-auto"
              >
                {isEditMode
                  ? intl.formatMessage({ id: "EDIT" })
                  : intl.formatMessage({ id: "CREATE" })}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BookingForm;
