import { FieldProps, useField } from "formik";
import React from "react";
import Select from "react-select";

interface CustomInputProps extends FieldProps {
  placeholder: string;
  label: string;
  required: boolean;
  className: string;
  labelClassName: string;
  innerChild: React.ReactNode;
  row: boolean;
  options: any;
  sm: boolean;
  defaultValue: any;
  hint: string;
  noError: boolean;
  dataChanged?: (data: any) => void;
}
const getClassName = (touched: any, error: any): string => {
  if (touched && error) {
    return "select-control is-invalid";
  } else if (touched) {
    return "select-control is-valid";
  } else {
    return "select-control";
  }
};
const getBorderColor = (touched: any, error: any) => {
  if (touched) {
    return error ? "#d63939" : "#2fb344";
  } else {
    return "#dadfe5";
  }
};
const FormikSelectComponent: React.FC<CustomInputProps> = ({
  field,
  form,
  placeholder,
  label,
  required,
  className,
  labelClassName,
  innerChild,
  row,
  options,
  noError,
  defaultValue,
  hint,
  sm,
  dataChanged,
  ...props
}) => {
  const { touched, errors, setTouched, setFieldValue, values } = form;

  return (
    <div className={className ?? "row mb-3"}>
      {label && (
        <label
          className={`${row ? "col-12" : "col-3"} ${
            labelClassName ?? "col-form-label "
          } ${required ? " required" : ""}`}
        >
          {label}
        </label>
      )}
      <div className={`${innerChild ? "col-7" : "col"}`}>
        <Select
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor: getBorderColor(
                  touched[field.name],
                  errors[field.name]
                ),
                "&:hover": {
                  borderColor: getBorderColor(
                    touched[field.name],
                    errors[field.name]
                  ),
                },
              };
            },
          }}
          className={`${sm ? "select-control-sm " : ""}${getClassName(
            touched[field.name],
            errors[field.name]
          )}`}
          onBlur={() => setTouched({ ...touched, [field.name]: true })}
          name={field.name}
          options={options}
          defaultValue={defaultValue}
          placeholder={placeholder}
          value={options.find(
            (option: any) => option.value === values[field.name]
          )}
          onChange={(selectedOption) => {
            setTouched({ ...touched, [field.name]: true });
            setFieldValue(field.name, selectedOption?.value);
            if (dataChanged) {
              dataChanged(selectedOption?.value);
            }
          }}
        />
        {hint && <small className="h-6 hint">{hint}</small>}
        {!noError && touched[field.name] && errors[field.name] && (
          <div className="invalid-feedback">
            {errors[field.name]?.toString()}
          </div>
        )}
      </div>
      {innerChild}
    </div>
  );
};

export default FormikSelectComponent;
