import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useListReportsQuery, useGetCompanyByIdQuery } from '../../app/apiService';
import { BookingYear } from '../../app/administrationSlice/administrationSlice'; // Adjusted import path

interface Report {
  name: string;
  url: string;
  createdAt: string;
}

const ReportsList: React.FC = () => {
  const intl = useIntl();
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration.bookingYears,
    shallowEqual
  ) as BookingYear;
  const companyId = selectedBookingYear?.companyId;

  const { data: company, isLoading: isLoadingCompany, error: companyError } = useGetCompanyByIdQuery(companyId ?? 0, {
    skip: !companyId,
  });

  const companyName = company?.name || 'all_report';

  const { data: reports, isLoading: isLoadingReports, error: reportsError } = useListReportsQuery(companyName, {
    skip: !company,
  });

  if (isLoadingCompany || isLoadingReports) return <div>{intl.formatMessage({ id: 'LOADING' })}</div>;

  if (companyError) {
    console.error('Company error:', companyError);
    return <div>{intl.formatMessage({ id: 'ERROR_LOADING_COMPANY' }, { companyId })}</div>;
  }

  if (!company) return <div>{intl.formatMessage({ id: 'NO_COMPANY_SELECTED' })}</div>;

  if (reportsError) {
    console.error('Reports error:', reportsError);
    return <div>{intl.formatMessage({ id: 'ERROR_LOADING_REPORTS' }, { companyName: company.name })}</div>;
  }

  const handleDownload = async (fileName: string) => {
    try {
      const response = await fetch(`/api/reports/download/${companyName}/${fileName}`);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <div className="card mt-4">
      <div className="card-header">
        {intl.formatMessage({ id: 'GENERATED_REPORTS' })} for {company.name}
      </div>
      <div className="card-body">
        {reports && reports.length > 0 ? (
          <ul className="list-group">
            {[...reports].sort((a, b) => b.name.localeCompare(a.name)).map((file) => (
              <li key={file.name} className="list-group-item d-flex justify-content-between align-items-center">
                {file.name}
                <button
                  onClick={() => handleDownload(file.name)}
                  className="btn btn-sm btn-primary"
                >
                  {intl.formatMessage({ id: 'DOWNLOAD' })}
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p>{intl.formatMessage({ id: 'NO_REPORTS_AVAILABLE' })}</p>
        )}
      </div>
    </div>
  );
};

export default ReportsList;
