import React, { FC, useState } from "react";
import { useIntl } from "react-intl";

interface Props {
  currentPage: number;
  perPage: number;
  onPageChange: (pageNumber: number) => void;
  onPerPageChange: (perPage: number) => void;
  totalCount: number;
}

const Pagination: FC<Props> = ({
  currentPage,
  perPage,
  onPageChange,
  onPerPageChange,
  totalCount,
}) => {
  const intl = useIntl();

  const prevPageClicked = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const nextPageClicked = () => {
    const totalPages = Math.ceil(totalCount / perPage);
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="d-flex w-100 my-2 align-items-center justify-content-around col-5">
      <div className="text-secondary">
        {intl.formatMessage({ id: "SHOW" })}
        <div className="mx-2 d-inline-block">
          <input
            type="text"
            className="form-control form-control-sm"
            value={perPage}
            onChange={(event) => onPerPageChange(parseInt(event.target?.value))}
            size={3}
            aria-label="booking count"
          />
        </div>
        {intl.formatMessage({ id: "ENTRIES" })} {totalCount}
      </div>
      <ul className="pagination m-0 align-items-center">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            tabIndex={-1}
            disabled={currentPage === 1}
            onClick={prevPageClicked}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
            {intl.formatMessage({ id: "PREV" })}
          </button>
        </li>
        <div className="mx-2 d-inline-block">
          <input
            type="text"
            className="form-control form-control-sm"
            value={currentPage}
            onChange={(event) => onPageChange(parseInt(event.target?.value))}
            size={3}
            aria-label="booking count"
          />
        </div>
        <li
          className={`page-item ${
            currentPage >= Math.ceil(totalCount / perPage) ? "disabled" : ""
          }`}
        >
          <button className="page-link" onClick={nextPageClicked}>
            {intl.formatMessage({ id: "NEXT" })}{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M9 6l6 6l-6 6" />
            </svg>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
