import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import { CompnayDataInterface, ImportResults } from "./BankImportHelper";
import MissingDaysTable from "./MissingDaysTable";
import NewBookingYearsTable from "./NewBookingYearsTable";
import ReportClosingReport from "./ReportClosingReport";
type Props = {
  setStep: (step: number) => void;
  importResults: ImportResults;
  importCompanyData: CompnayDataInterface;
  setIsLoading: (loading: boolean) => void;
  isLoading: boolean;
};
const Step3: FC<Props> = ({
  setStep,
  importResults,
  importCompanyData,
  setIsLoading,
  isLoading,
}) => {
  const intl = useIntl();
  return (
    <>
      <div className="card-body text-center">
        {importResults.warning ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon mb-2 mx-auto text-warning icon-lg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 9v2m0 4v.01" />
            <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
          </svg>
        ) : (
          <svg
            height="800px"
            width="800px"
            version="1.1"
            fill="#2fb344"
            className="icon mb-2 mx-auto text-success icon-lg"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 52 52"
            xmlSpace="preserve"
          >
            <g>
              <path
                d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26
                  S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z"
              />
              <path
                d="M38.252,15.336l-15.369,17.29l-9.259-7.407c-0.43-0.345-1.061-0.274-1.405,0.156c-0.345,0.432-0.275,1.061,0.156,1.406
                  l10,8C22.559,34.928,22.78,35,23,35c0.276,0,0.551-0.114,0.748-0.336l16-18c0.367-0.412,0.33-1.045-0.083-1.411
                  C39.251,14.885,38.62,14.922,38.252,15.336z"
              />
            </g>
          </svg>
        )}
        <p
          className={`${
            importResults.warning ? "text-warning" : "text-success"
          }`}
        >
          {importResults.newRecords > 0
            ? intl.formatMessage(
                { id: "BANKIMPORT.SUCCESSFULLY_IMPORTED" },
                { records: importResults.newRecords }
              )
            : intl.formatMessage({ id: "BANKIMPORT.NO_RECORD_IMPORTED" })}
        </p>
        {importResults.missingDays.length > 0 && (
          <>
            <p className="text-warning mb-2 text-center h4">
              {intl.formatMessage({
                id: "BANKIMPORT.MISSING_DAYS_WARNING",
              })}
            </p>
            <MissingDaysTable missingDays={importResults.missingDays} />
          </>
        )}
        {importResults.newBookingYears.length > 0 && (
          <>
            <p className="text-warning mb-2 text-start h4">
              {intl.formatMessage({
                id: "BANKIMPORT.NEW_BOOKING_YEARS_CREATED",
              })}
            </p>
            <NewBookingYearsTable
              newBookingYears={importResults.newBookingYears}
            />
          </>
        )}
        {importResults.usedBookingYearClosing.length > 0 && (
          <>
            <p className="text-body my-2 text-center h4">
              {intl.formatMessage({
                id: "BANKIMPORT.IMPORT_RESULTS",
              })}
            </p>
            <ReportClosingReport
              usedBookingYearClosing={importResults.usedBookingYearClosing}
              importCompanyData={importCompanyData}
              setIsLoading={setIsLoading}
            />
          </>
        )}
      </div>
      <div className="card-footer text-end">
        <div className="d-flex">
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-primary ms-auto"
            onClick={() => setStep(1)}
          >
            {intl.formatMessage({ id: "CONTINUE" })}
          </button>
        </div>
      </div>
    </>
  );
};

export default Step3;
