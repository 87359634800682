import moment from "moment";
import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import Pagination from "../../../_panel/partials/Pagination";
interface Props {
  setStep: (step: number) => void;
  rejectedFiles: any;
}
const ImportResult: FC<Props> = ({ setStep, rejectedFiles }) => {
  const intl = useIntl();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(8);
  const startIndex = (page - 1) * perPage;
  const endIndex = startIndex + perPage;
  const slicedRejectedFiles = rejectedFiles.slice(startIndex, endIndex);
  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };
  return (
    <div className="card mt-3">
      <div className="card-header">
        <h3 className="card-title">
          {intl.formatMessage({ id: "HOMEPAGE.EXPORT_TITLE" })}
        </h3>
      </div>
      <div className="card-body">
        <h4 className="text-center text-danger">
          {intl.formatMessage({
            id: "DATAINTEROPERABILITY.REJECTED_FILES_WARN",
          })}
        </h4>
        <table className="table table-vcenter card-table table-striped">
          <thead>
            <tr>
              {/* <th className="col-md-1"></th> */}
              <th className="">{intl.formatMessage({ id: "BOOKING.DATE" })}</th>
              <th className="">
                {intl.formatMessage({ id: "BOOKING.BOOK_NUMBER" })}
              </th>
              <th className="">
                {intl.formatMessage({ id: "BOOKING.ACCOUNT_NUMBER" })}
              </th>

              <th className="">
                {intl.formatMessage({ id: "BOOKING.DESCRIPTION" })}
              </th>
              <th className="">
                {intl.formatMessage({ id: "BOOKING.AMOUNT" })}
              </th>
            </tr>
          </thead>
          <tbody>
            {slicedRejectedFiles.map((rejectedFile: any) => (
              <tr key={rejectedFile.Key}>
                <td className="py-1">
                  {rejectedFile.DATUM
                    ? moment(rejectedFile.DATUM).format("yyyy-MM-DD")
                    : "-"}
                </td>
                <td className=" py-1">{rejectedFile.BOEKNR}</td>
                <td className="py-1">{rejectedFile.NUMMER}</td>
                <td className="text-break text-wrap py-1">
                  {rejectedFile.OMSCHRIJVING}
                </td>
                <td className="py-1">{rejectedFile.BEDRAG}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={page}
          perPage={perPage}
          onPageChange={handlePageChange}
          onPerPageChange={(perPage) => setPerPage(perPage)}
          totalCount={rejectedFiles.length}
        />
      </div>

      <div className="card-footer text-end">
        <button
          type="button"
          onClick={() => setStep(1)}
          className="btn btn-primary"
        >
          {intl.formatMessage({ id: "OKAY" })}
        </button>
      </div>
    </div>
  );
};

export default ImportResult;
