import { useIntl } from "react-intl";
import ForeignCurrencies from "./ForeignCurrencies/ForeignCurrencies";

const EOYMasterTablesCard = () => {
  const intl = useIntl();
  return (
    <>
      <div className="row page-body">
        <div className="col-md-6">
          <ForeignCurrencies />
        </div>
      </div>
    </>
  );
};

export default EOYMasterTablesCard;
