import { useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { RootState } from "../../app/RootReducer";
import { BookingYear } from "../../app/administrationSlice/administrationSlice";
import {
  useGetAccountsQuery,
  useGetBookingsQuery,
  useGetDiariesQuery,
} from "../../app/apiService";
import { DiariesInterface } from "../diaries/DiariesModel";
import BookingHeader from "./BookingHeader/BookingHeader";
import BookingGrid from "./BookingTable/BookingGrid";
import { useBookingUIContext } from "./BookingUiContext";

const BookingCard = () => {
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration.bookingYears,
    shallowEqual
  ) as BookingYear;
  const { diaryNo } = useParams();
  const bookingUiParams = useBookingUIContext();
  const [isMemorial, setIsMemorial] = useState<boolean>(false);
  const bookingUIProps = useMemo(() => {
    return {
      canLeaveChanged: bookingUiParams?.canLeaveChanged,
      bookingDeleted: bookingUiParams?.bookingDeleted,
      editBookingClicked: bookingUiParams?.editBookingClicked,
      queryParams: bookingUiParams?.queryParams,
      setQueryParams: bookingUiParams?.setQueryParams,
    };
  }, [bookingUiParams]);
  const { data: { records: diaries = [] } = {} } =
    useGetDiariesQuery(
      {
        page: 0,
        perPage: 1000,
      },
      {
        skip: !selectedBookingYear?.id,
      }
    ) || {};
  const { data: { totalAmount = 0, records: bookings = [] } = {} } =
    useGetBookingsQuery(
      {
        page: bookingUIProps.queryParams?.pageNumber,
        perPage: 0,
        sort: bookingUIProps.queryParams?.sort,
        bookingYearsNo: selectedBookingYear?.id,
        filter: bookingUIProps.queryParams?.filter,
      },
      {
        skip:
          !bookingUIProps.queryParams?.filter?.diaryNo ||
          !selectedBookingYear?.id,
      }
    ) || {};
  const { data: { records: accounts = [] } = {} } =
    useGetAccountsQuery(
      {
        page: 0,
        perPage: 1000,
      },
      {
        skip: !selectedBookingYear?.id,
      }
    ) || {};
  useEffect(() => {
    const diaryItem = diaries?.find(
      (item: DiariesInterface) =>
        bookingUIProps?.queryParams?.filter?.diaryNo &&
        item.number === bookingUIProps?.queryParams?.filter?.diaryNo
    ) as DiariesInterface;
    setIsMemorial(diaryItem?.isMemorial ?? false);
    bookingUIProps?.canLeaveChanged &&
      bookingUIProps?.canLeaveChanged(
        !(parseInt(totalAmount ?? 0) !== 0 && (diaryItem?.isMemorial ?? false))
      );
  }, [totalAmount, bookingUIProps?.queryParams?.filter?.diaryNo]);
  useEffect(() => {
    const diaryItem = diaries?.find(
      (item: DiariesInterface) => diaryNo && item.number === diaryNo
    ) as DiariesInterface;
    setIsMemorial(diaryItem?.isMemorial ?? false);
  }, [diaryNo]);
  return (
    <>
      <BookingHeader />
      <BookingGrid isMemorial={isMemorial} />
      <Outlet />
    </>
  );
};

export default BookingCard;
