import { Field, Form, Formik } from "formik";
import React, { FC, useEffect, useRef } from "react";
import { IntlShape, useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useDynamicValidationSchema } from "../../../_panel/helpers/useDynamicValidationSchema";
import {
  intlMaxMessage,
  intlMaxNumberMessage,
  intlRequiredMessage,
} from "../../../_panel/helpers/validation";
import FormikDateComponent from "../../../_panel/partials/formik/FormikDateComponent";
import FormikInputComponent from "../../../_panel/partials/formik/FormikInputComponent";
import FormikNumberComponent from "../../../_panel/partials/formik/FormikNumberComponent";
import FormikSelectComponent from "../../../_panel/partials/formik/FormikSelectComponent";
import { RootState } from "../../../app/RootReducer";
import { BookingYear } from "../../../app/administrationSlice/administrationSlice";
import { useGetAccountsQuery } from "../../../app/apiService";
import { parseNumber } from "../../../common/Utils";
import { CoaInterface } from "../../chartOfAccounts/CoaModel";
import { BookingInterface } from "../BookingModel";
const bookingValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    accountNo: yup
      .string()
      .required(intlRequiredMessage(intl))
      .label(intl.formatMessage({ id: "BOOKING.ACCOUNT_NO" })),

    description: yup
      .string()
      .label(intl.formatMessage({ id: "BOOKING.DESCRIPTION" }))
      .nullable()
      .max(255, intlMaxMessage(intl)),

    bookingHeaderId: yup
      .number()
      .label(intl.formatMessage({ id: "BOOKING.INVOICE" }))
      .max(2147483647, intlMaxNumberMessage(intl))
      .nullable(),

    date: yup
      .date()
      .label(intl.formatMessage({ id: "BOOKING.DATE" }))
      .required(intlRequiredMessage(intl)),

    amountCredit: yup
      .number()
      .label(intl.formatMessage({ id: "BOOKING.AMOUNT_CREDIT" }))
      .test(
        "creditOrDebet",
        intl.formatMessage({ id: "VALIDATION.REQUIRED" }),
        function (value) {
          const amountDebet = this.parent.amountDebet;
          const hasCreditOrDebit =
            value !== undefined || amountDebet !== undefined;

          if (!hasCreditOrDebit) {
            return this.createError({
              path: "amountCredit",
              message: intlRequiredMessage(intl),
            });
          }

          return true;
        }
      ),

    amountDebet: yup
      .number()
      .label(intl.formatMessage({ id: "BOOKING.AMOUNT_DEBT" }))
      .test(
        "creditOrDebet",
        intl.formatMessage({ id: "VALIDATION.REQUIRED" }),
        function (value) {
          const amountCredit = this.parent.amountCredit;
          const hasCreditOrDebit =
            value !== undefined || amountCredit !== undefined;

          if (!hasCreditOrDebit) {
            return this.createError({
              path: "amountDebet",
              message: intlRequiredMessage(intl),
            });
          }

          return true;
        }
      ),
  });
type Props = {
  data: BookingInterface;
  isEditMode?: boolean;
  onCancel: () => void;
  formSubmitted: (
    values: BookingInterface,
    setSubmitting: (status: boolean) => void
  ) => void;
};
const BookingFormRow: FC<Props> = (props) => {
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration.bookingYears,
    shallowEqual
  ) as BookingYear;
  const { diaryNo } = useParams();
  const { data, formSubmitted, isEditMode } = props;
  const { data: { records: accounts = [] } = {} } =
    useGetAccountsQuery(
      {
        page: 0,
        perPage: 1000,
      },
      {
        skip: !selectedBookingYear?.id,
      }
    ) || {};
  const options =
    accounts
      .map((item: CoaInterface) => {
        return {
          value: item.number,
          number: item.number,
          label:
            item.number + (item.description ? " | " + item.description : ""),
        };
      })
      .filter((item: any) => item.number !== "0") ?? [];
  const formRef: React.RefObject<any> = useRef();
  const buttonRef: React.RefObject<any> = useRef();
  const intl = useIntl();
  const validationSchema = useDynamicValidationSchema(bookingValidationSchema);
  useEffect(() => {
    formRef?.current?.validateForm();
  }, [validationSchema]);
  useEffect(() => {
    if (isEditMode && !formRef?.current?.isSubmitting)
      formRef?.current?.setValues(data);
  }, [data]);
  useEffect(() => {
    formRef?.current?.setFieldValue("date", data.date);
  }, [data.date]);
  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      innerRef={formRef}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        formSubmitted(
          {
            accountNo: values.accountNo,
            diaryNo: diaryNo ?? "",
            bookingYearsNo: selectedBookingYear?.id,
            companyNo: selectedBookingYear?.companyId,
            amountCredit: parseNumber(values.amountCredit),
            amountDebet: parseNumber(values.amountDebet),
            bookingHeaderId: values.bookingHeaderId,
            date: values.date,
            description: values.description,
            projectNumber: "1",
            reconciledWithIdsId: values.reconciledWithIdsId,
          },
          (status: boolean) => {
            if (status) {
              resetForm({
                values: {
                  date: values.date,
                  description: "",
                  projectNumber: "1",
                  bookingYearsNo: "",
                  companyNo: "",
                  accountNo: values.accountNo,
                  diaryNo: diaryNo ?? "",
                  amountCredit: "",
                  amountDebet: "",
                  bookingHeaderId: "",
                },
              });
            }
            setSubmitting(false);
          }
        );
      }}
    >
      {({ isSubmitting, submitForm, isValid }) => (
        <Form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (!isSubmitting) {
                buttonRef?.current?.focus();
                submitForm();
              }
            }
          }}
          className="row my-2 px-0"
        >
          <div className="row px-0 col-md-6 align-items-center">
            <div className="row col-md-4 justify-content-between">
              <div className="col-md-1 text-end p-0"></div>
              <Field
                name="date"
                component={FormikDateComponent}
                className="col-md-10"
                placeholder={intl.formatMessage(
                  { id: "PLACEHOLDER" },
                  {
                    label: intl
                      .formatMessage({
                        id: "BOOKING.DATE",
                      })
                      .toLocaleLowerCase(),
                  }
                )}
                noError
              />
            </div>
            <Field
              name="accountNo"
              component={FormikSelectComponent}
              className="col-md-5"
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "BOOKING.ACCOUNT_NO",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              options={options}
              sm
              noError
            />
            <Field
              name="bookingHeaderId"
              className="col-md-3"
              component={FormikNumberComponent}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "BOOKING.INVOICE",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              noError
            />
          </div>
          <div className="row col-md-6 p-0 align-items-center">
            <Field
              name="description"
              className="col-md-4"
              component={FormikInputComponent}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "BOOKING.DESCRIPTION",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              noError
            />
            <Field
              name="amountDebet"
              className="col-md-3"
              component={FormikNumberComponent}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "BOOKING.AMOUNT_DEBT",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              noError
            />
            <Field
              name="amountCredit"
              className="col-md-3"
              component={FormikNumberComponent}
              placeholder={intl.formatMessage(
                { id: "PLACEHOLDER" },
                {
                  label: intl
                    .formatMessage({
                      id: "BOOKING.AMOUNT_CREDIT",
                    })
                    .toLocaleLowerCase(),
                }
              )}
              noError
            />

            <div className="col-md-2 ">
              <div className=" d-flex justify-content-between">
                <div className="mx-auto d-flex justify-content-between">
                  <button
                    ref={buttonRef}
                    type="submit"
                    className="btn btn-sm btn-outline-primary ms-2"
                    disabled={isSubmitting || !isValid}
                  >
                    {intl.formatMessage({ id: "CREATE" })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BookingFormRow;
