import { useState } from "react";
import { useIntl } from "react-intl";
import ProgressBar from "../../_panel/partials/ProgressBar";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { CompnayDataInterface, ImportResults } from "./BankImportHelper";
import Step3 from "./Step3";

const BankImportPage = () => {
  const [importCompanyData, setImportCompanyData] =
    useState<CompnayDataInterface>({
      companyId: "",
      bankCOAAccountNo: "",
      bankCOAAccountNoPositive: "",
      diaryNo: "",
    });
  const [importResults, setImportResults] = useState<ImportResults>({
    newRecords: 1,
    warning: false,
    missingDays: [],
    newBookingYears: [],
    usedBookingYearClosing: [],
  });
  const [step, setStep] = useState(1);
  const [lastImportDate, setLastImportDate] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="card">
            {isLoading && <ProgressBar />}
            <div className="card-header">
              <h3 className="card-title">
                {intl.formatMessage({ id: "BANKIMPORT.TITLE" })}
              </h3>
            </div>
            {step === 1 && (
              <Step1
                importCompanyData={importCompanyData}
                setImportCompanyData={setImportCompanyData}
                setStep={setStep}
                setIsLoading={setIsLoading}
                setLastImportDate={setLastImportDate}
              />
            )}
            {step === 2 && (
              <Step2
                importCompanyData={importCompanyData}
                setStep={setStep}
                setIsLoading={setIsLoading}
                lastImportDate={lastImportDate}
                setImportResults={setImportResults}
                importResults={importResults}
              />
            )}
            {step === 3 && (
              <Step3
                setStep={setStep}
                importResults={importResults}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                importCompanyData={importCompanyData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BankImportPage;
