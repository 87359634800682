import {
  Route,
  Routes,
  useBlocker,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ParamsHOC } from "../_panel/helpers/components/ParamsHOC";
import BookingCard from "../features/booking/BookingCard";
import BookingCreate from "../features/booking/BookingCreate/BookingCreate";
import BookingDelete from "../features/booking/BookingDelete/BookingDelete";
import BookingEdit from "../features/booking/BookingEdit/BookingEdit";
import {
  BookingContextInterface,
  BookingUIProvider,
  useBookingUIContext,
} from "../features/booking/BookingUiContext";
import { ProtectedRoute } from "../routing/ProtectedRoute";
import BookingWarningModal from "../features/booking/BookingWarningModal/BookingWarningModal";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

const BookingPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [canLeave, setCanLeave] = useState(true);
  const [diaryNo, setDiaryNo] = useState<string | number>();
  const bookingUiEvents: BookingContextInterface = {
    canLeaveChanged(canLeave) {
      setCanLeave(canLeave);
    },
    bookingDeleted: (id, diaryNo): void => {
      setDiaryNo(diaryNo);
      navigate(`/general-ledger/booking/${diaryNo}/${id}/delete`);
    },
    editBookingClicked(id, diaryNo) {
      setDiaryNo(diaryNo);
      navigate(`/general-ledger/booking/${diaryNo}/${id}/edit`);
    },
    newBookingClicked: (diaryNo): void => {
      setDiaryNo(diaryNo);
      navigate(`/general-ledger/booking/${diaryNo}/new-booking`);
    },
    fetchBooking: false,
  };
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (
      !canLeave &&
      !(
        nextLocation.pathname.endsWith("/new-booking") ||
        nextLocation.pathname.endsWith("/edit") ||
        nextLocation.pathname.endsWith("/delete") ||
        nextLocation.pathname.endsWith(`/booking/${diaryNo}`)
      )
    ) {
      return true;
    }
    return false;
  });
  const onHide = () => {
    blocker?.reset && blocker.reset();
  };
  const onProgress = () => {
    setCanLeave(true);
    blocker.proceed && blocker.proceed();
  };
  useEffect(() => {
    return () => {
      blocker?.reset && blocker.reset();
      setCanLeave(true);
    };
  }, []);

  return (
    <BookingUIProvider BookingUIEvents={bookingUiEvents}>
      <Routes>
        <Route
          path=":diaryNo/new-booking/*"
          element={
            <ProtectedRoute>
              <BookingCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path=":diaryNo/:id/edit"
          element={
            <ProtectedRoute>
              <BookingEdit />
            </ProtectedRoute>
          }
        />

        <Route element={<BookingCard />} path=":diaryNo">
          <Route
            path=":id/delete"
            element={
              <ParamsHOC>
                {(match: { [key: string]: string }) => (
                  <BookingDelete
                    show={match?.id != null}
                    id={match?.id}
                    onHide={() => {
                      navigate(`/general-ledger/booking/${match?.diaryNo}`);
                    }}
                  />
                )}
              </ParamsHOC>
            }
          />
        </Route>
        <Route element={<BookingCard />} path="*" />
      </Routes>
      <BookingWarningModal
        show={blocker?.state === "blocked"}
        onHide={onHide}
        onProgress={onProgress}
      />
    </BookingUIProvider>
  );
};

export default BookingPage;
