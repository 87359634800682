import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiConfig from "../common/Config";
import UserService from "./oauth/UserService";
import { RootState } from "./RootReducer";
import { BookingYear } from './administrationSlice/administrationSlice'; // Make sure to import the BookingYear interface

interface Report {
  name: string;
  url: string;
  createdAt: string;
}

interface ReconciliationOptions {
  vatRates?: { high: number; low: number };
  dateWindow?: number;
  amountTolerance?: number;
  defaultCurrency?: string;
}

interface ReconciliationResult {
  id: string;
  status: 'pending' | 'completed' | 'failed';
  results?: any[];
  summary?: any;
  error?: string;
  createdAt: Date;
  completedAt?: Date;
}

interface ReconciliationListParams {
  page?: number;
  perPage?: number;
  sort?: string;
  status?: 'pending' | 'completed' | 'failed';
  dateFrom?: string;
  dateTo?: string;
}

export const apiService = createApi({
  reducerPath: "apiService",
  baseQuery: fetchBaseQuery({
    validateStatus: (response) => {
      if (response.status === 401 || response.status === 403) {
        window.location.reload();
      }
      return response.status < 300;
    },
    baseUrl: `${apiConfig.BASE_API}/`,
    prepareHeaders: (headers, { getState }) => {
      const state: RootState = getState() as RootState;
      const { administration } = state.root;

      const token = UserService.getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      if (administration?.bookingYears?.id.toString()) {
        headers.set(
          "bookingYearsNo",
          administration?.bookingYears?.id.toString()
        );
      }
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: [
    "Diaries",
    "Accounts",
    "Bookings",
    "Companies",
    "AccountTypes",
    "MasterTables",
    "UserProfile",
    "Banks",
    "Currencies",
    "Reconciliation"
  ],
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: () => {
        return `api/profile`;
      },
      providesTags: ["UserProfile"],
    }),
    updateUserProfile: builder.mutation({
      query: ({ ...patch }) => {
        return {
          url: `api/profile`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: ["UserProfile"],
    }),
    getBookings: builder.query({
      query: ({
        page = 1,
        perPage = 10,
        sort = "?sort=id",
        filter = null,
        bookingYearsNo = null,
      }) => {
        return `api/booking${sort || "?sort=id"
          }&page=${page}&perPage=${perPage}${filter?.term ? `&term=${filter?.term}` : ""
          }${filter?.operator && filter?.field
            ? `&field=${filter.field}&operator=${filter.operator}`
            : ""
          }${filter?.diaryNo ? `&diaryNo=${filter.diaryNo}` : ""}${bookingYearsNo ? `&bookingYearsNo=${bookingYearsNo}` : ""
          }`;
      },
      providesTags: ["Bookings"],
    }),
    getBookingById: builder.query({
      query: (id) => `api/booking/${id}`,
      providesTags: (result, error, id) => [{ type: "Bookings", id }],
    }),
    deleteBooking: builder.mutation({
      query: (id) => ({
        url: `api/booking/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Bookings"],
    }),
    addNewBooking: builder.mutation({
      query: (payload) => ({
        url: "api/booking",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Bookings", "Accounts"],
    }),
    addNewMultiEntryBooking: builder.mutation({
      query: (payload) => ({
        url: "api/booking/multi-entry",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Bookings", "Accounts"],
    }),
    updateBooking: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/booking/${id}`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: ["Bookings"],
    }),
    updateBookingOptimistic: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/booking/${id}`,
        method: "PUT",
        body: patch,
      }),
    }),
    splitBooking: builder.mutation({
      query: ({ id }) => ({
        url: `api/booking/${id}/split-booking`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["Bookings"],
    }),
    getAccounts: builder.query({
      query: ({ page = 1, perPage = 10, sort = "?sort=id", filter = null }) =>
        `api/accounts${sort || "?sort=id"}&page=${page}&perPage=${perPage}
          ${filter?.term ? `&term=${filter?.term}` : ""}
          ${filter?.operator && filter?.field
          ? `&field=${filter?.field}
          &operator=${filter?.operator}`
          : ""
        }`,
      providesTags: ["Accounts", "Diaries"],
    }),
    getAccountsById: builder.query({
      query: (id) => `api/accounts/${id}`,
      providesTags: (result, error, id) => [{ type: "Accounts", id }],
      //   skip: (id) => !id,
    }),
    getChildAccountsById: builder.query({
      query: (id) => `api/childAccounts/${id}`,
      providesTags: (result, error, id) => [{ type: "Accounts", id }],
      //   skip: (id) => !id,
    }),
    deleteAccounts: builder.mutation({
      query: (id) => ({
        url: `api/accounts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Accounts"],
    }),
    addNewAccounts: builder.mutation({
      query: (payload) => ({
        url: "api/accounts",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Accounts"],
    }),
    updateAccounts: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/accounts/${id}`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: ["Accounts"],
    }),
    getDiaries: builder.query({
      query: ({ page = 1, perPage = 10, sort = "?sort=id", filter = null }) =>
        `api/diaries${sort || "?sort=id"}&page=${page}&perPage=${perPage}
        ${filter?.term ? `&term=${filter?.term}` : ""}
        ${filter?.operator && filter?.field
          ? `&field=${filter?.field}
        &operator=${filter?.operator}`
          : ""
        }`,
      providesTags: ["Diaries"],
    }),
    getDiariesById: builder.query({
      query: (id) => `api/diaries/${id}`,
      providesTags: (result, error, id) => [{ type: "Diaries", id }],
      //   skip: (id) => !id,
    }),
    deleteDiaries: builder.mutation({
      query: (id) => ({
        url: `api/diaries/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Diaries"],
    }),
    addNewDiaries: builder.mutation({
      query: (payload) => ({
        url: "api/diaries",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Diaries", "Accounts"],
    }),
    updateDiaries: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/diaries/${id}`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: ["Diaries", "Accounts"],
    }),
    getCompanies: builder.query({
      query: ({ page = 1, perPage = 10, sort = "?sort=id" }) => {
        return `api/companies${sort || "?sort=id"
          }&page=${page}&perPage=${perPage}`;
      },
      providesTags: ["Companies"],
    }),
    getCurrencies: builder.query({
      query: ({ page = 1, perPage = 999999, sort = "?sort=name" }) => {
        return `api/currencies${sort || "?sort=name"
          }&page=${page}&perPage=${perPage}`;
      },
      providesTags: ["Currencies"],
    }),
    importMsAccess: builder.mutation({
      query(payload) {
        return {
          url: `/api/data-interoperability/import`,
          method: "POST",
          body: payload,
          formData: true,
        };
      },
    }),
    getAccountTypes: builder.query({
      query: ({ page = 1, perPage = 10, sort = "?sort=id", filter = null }) =>
        `api/accountTypes${sort || "?sort=id"}&page=${page}&perPage=${perPage}
        ${filter?.term ? `&term=${filter?.term}` : ""}
        ${filter?.operator && filter?.field
          ? `&field=${filter?.field}
        &operator=${filter?.operator}`
          : ""
        }`,
      providesTags: ["AccountTypes"],
    }),
    getAccountTypeById: builder.query({
      query: (id) => `api/accountTypes/${id}`,
      providesTags: (result, error, id) => [{ type: "AccountTypes", id }],
      //   skip: (id) => !id,
    }),
    deleteAccountType: builder.mutation({
      query: (id) => ({
        url: `api/accountTypes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AccountTypes"],
    }),
    addNewAccountType: builder.mutation({
      query: (payload) => ({
        url: "api/accountTypes",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["AccountTypes"],
    }),
    updateAccountType: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/accountTypes/${id}`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: ["AccountTypes"],
    }),
    getMasterTables: builder.query({
      query: () => `api/master-tables/`,
      providesTags: ["MasterTables"],
    }),
    updateMasterTable: builder.mutation({
      query: ({ ...patch }) => ({
        url: `api/master-tables/`,
        method: "PUT",
        body: patch,
      }),

      invalidatesTags: ["MasterTables", "UserProfile"],
    }),
    listReports: builder.query<Report[], string>({
      query: (companyName) => `api/reports/list/${companyName}`,
    }),
    downloadExcelReport: builder.query<{ fileName: string }, string>({
      query: (fileName) => `api/reports/download/${fileName}`,
    }),
    getCompanyById: builder.query<{ name: string }, number>({
      query: (id) => `api/companies/${id}`,
    }),
    getCurrencyById: builder.query<{ name: string }, number>({
      query: (id) => `api/currencies/${id}`,
    }),
    selectAdministration: builder.mutation({
      query: (payload) => ({
        url: "api/administration",
        method: "POST",
        body: payload,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          setTimeout(() => {
            dispatch(
              apiService.util.invalidateTags([
                "Diaries",
                "Accounts",
                "Bookings",
              ])
            );
          }, 500);
        } catch (_) { }
      },
    }),
    getlastImportDate: builder.mutation({
      query: (payload) => ({
        url: "api/data-interoperability/last-bank-import",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Bookings"],
    }),
    importBankData: builder.mutation({
      query: (payload) => ({
        url: "api/data-interoperability/import-bank",
        method: "POST",
        body: payload,
        formData: true,
      }),
      invalidatesTags: ["AccountTypes", "Bookings"],
    }),
    getBanks: builder.query({
      query: () => `api/banks`,
      providesTags: ["Banks"],
    }),
    dataUltimoReport: builder.mutation({
      queryFn: async ({ dateUltimo, allowDatesOutsideCurrentBookingYear, reportType, companyId }, api, extraOptions, baseQuery) => {
        const response = (await baseQuery({
          url: `api/reports/data-ultimo-report`,
          method: "POST",
          body: { dateUltimo, allowDatesOutsideCurrentBookingYear, reportType, companyId },
        })) as any;
        return response;
      },
    }),
    balanceUltimoReport: builder.mutation({
      queryFn: async (data, api, extraOptions, baseQuery) => {
        const response = (await baseQuery({
          url: `api/reports/balance-ultimo-report`,
          method: "POST",
          body: data,
          responseHandler: (response) => response.blob(),
        })) as any;
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        return { data: pdfUrl };
      },
    }),
    earningsUltimoReport: builder.mutation({
      queryFn: async (data, api, extraOptions, baseQuery) => {
        const response = (await baseQuery({
          url: `api/reports/earnings-ultimo-report`,
          method: "POST",
          body: data,
          responseHandler: (response) => response.blob(),
        })) as any;
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        return { data: pdfUrl };
      },
    }),
    excelUltimoReport: builder.mutation({
      queryFn: async (data, api, extraOptions, baseQuery) => {
        const response = (await baseQuery({
          url: `api/reports/generate-excel`,
          method: "POST",
          body: data,
          responseHandler: (response) => response.blob(),
        })) as any;
        // const excelBlob = new Blob([response.data], { type: "application/excel" });
        // const excelUrl = URL.createObjectURL(excelBlob);
        // return { data: excelUrl };
        return { data: response.data };
      },
    }),
    fetchAllBookingYears: builder.query<BookingYear[], number>({
      query: (companyId) => `api/companies/${companyId}/booking-years`,
    }),
    getMasterEOYTable: builder.query({
      query: () => `api/master-eoy-tables`,
    }),
    updateMasterEOYTable: builder.mutation<void, { currencyId: string; accountNo: string }>({
      query: (data) => ({
        url: '/api/master-eoy-tables',
        method: 'PUT',
        body: data,
      }),
    }),


    // Get list of reconciliations
    getReconciliation: builder.query({
      query: ({ options }) => ({
        url: 'reconciliation',
        method: 'GET',
        params: options
      })
    }),

    // Get single reconciliation status and results
    getReconciliationID: builder.query<ReconciliationResult, string>({
      query: (id) => `reconciliations/${id}`,
      providesTags: ["Reconciliation"]
    }),

    // Download reconciliation results
    downloadReconciliation: builder.query<Blob, string>({
      query: (id) => ({
        url: `reconciliation/${id}/download`,
        responseHandler: (response) => response.blob()
      })
    })
  })
});
  
export const {
  useGetBookingsQuery,
  useGetBookingByIdQuery,
  useDeleteBookingMutation,
  useAddNewBookingMutation,
  useAddNewMultiEntryBookingMutation,
  useUpdateBookingMutation,
  useUpdateBookingOptimisticMutation,
  useSplitBookingMutation,
  useGetAccountsQuery,
  useGetChildAccountsByIdQuery,
  useGetAccountsByIdQuery,
  useDeleteAccountsMutation,
  useAddNewAccountsMutation,
  useUpdateAccountsMutation,
  useGetDiariesQuery,
  useGetDiariesByIdQuery,
  useDeleteDiariesMutation,
  useAddNewDiariesMutation,
  useUpdateDiariesMutation,
  useGetCompaniesQuery,
  useImportMsAccessMutation,
  useGetAccountTypesQuery,
  useGetAccountTypeByIdQuery,
  useUpdateAccountTypeMutation,
  useAddNewAccountTypeMutation,
  useDeleteAccountTypeMutation,
  useGetMasterTablesQuery,
  useUpdateMasterTableMutation,
  useSelectAdministrationMutation,
  useGetlastImportDateMutation,
  useImportBankDataMutation,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useGetBanksQuery,
  useBalanceUltimoReportMutation,
  useExcelUltimoReportMutation,
  useEarningsUltimoReportMutation,
  useDataUltimoReportMutation,
  useFetchAllBookingYearsQuery,
  useListReportsQuery,
  useDownloadExcelReportQuery,
  useGetCompanyByIdQuery,
  useGetMasterEOYTableQuery,
  useUpdateMasterEOYTableMutation,
  useGetCurrencyByIdQuery,
  useGetCurrenciesQuery,

  useGetReconciliationQuery,
  useGetReconciliationIDQuery,
  useLazyDownloadReconciliationQuery,
} = apiService;

