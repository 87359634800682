import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BookingYear {
  id: number;
  companyId: number;
  fromDate: Date;
  toDate: Date;
}

interface AdministrationState {
  bookingYears: BookingYear | null;
  allBookingYears: BookingYear[];
}

const initialState: AdministrationState = {
  bookingYears: null,
  allBookingYears: [],
};

const administrationSlice = createSlice({
  name: "administration",
  initialState,
  reducers: {
    bookingYearsSelected: (state, action: PayloadAction<BookingYear>) => {
      state.bookingYears = action.payload;
    },
    allBookingYearsLoaded: (state, action: PayloadAction<BookingYear[]>) => {
      state.allBookingYears = action.payload;
    },
  },
});

export const { bookingYearsSelected, allBookingYearsLoaded } = administrationSlice.actions;
export { administrationSlice };
