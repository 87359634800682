import React from "react";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import BookingForm from "../BookingForm/BookingForm";
import { BookingInterface } from "../BookingModel";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import { toast } from "react-toastify";
import moment from "moment";
import {
  useGetBookingByIdQuery,
  useUpdateBookingMutation,
} from "../../../app/apiService";
import { BookingYear } from "../../../app/administrationSlice/administrationSlice";
import { RootState } from "../../../app/RootReducer";
import { shallowEqual, useSelector } from "react-redux";

const BookingEdit = () => {
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration.bookingYears,
    shallowEqual
  ) as BookingYear;
  const [updateBooking, { isLoading: isLoadingUpdateBooking }] =
    useUpdateBookingMutation();
  const { id } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const { data: booking, isError, isLoading } = useGetBookingByIdQuery(id);
  const onCancel = () => {
    navigate(-1);
  };
  if (isError) {
    navigate(-1);
  }
  const onFormSubmit = (
    values: BookingInterface,
    setSubmitting: (status: boolean) => void
  ) => {
    if (!values.diaryNo) {
      toast.warn(intl.formatMessage({ id: "BOOKING.SELECT_DIARY_WARNING" }));
      setSubmitting(false);
      return;
    }
    if (!values.bookingYearsNo) {
      toast.warn(intl.formatMessage({ id: "BOOKING.SELECT_ADMINSTRATION" }));
      setSubmitting(false);
      return;
    }
    if (
      (values?.date && values?.date >= selectedBookingYear.toDate) ||
      (values?.date && selectedBookingYear.fromDate < values?.date)
    ) {
      toast.warn(intl.formatMessage({ id: "BOOKING.DATE_WARNING" }));
      setSubmitting(false);
      return;
    }
    updateBooking({ ...values, id })
      .unwrap()
      .then(() => {
        setSubmitting(true);
        toast.success(intl.formatMessage({ id: "BOOKING.EDITED" }));
        onCancel();
      })
      .catch(() => {
        setSubmitting(false);
        toast.error(intl.formatMessage({ id: "BOOKING.EDITED_FAILED" }));
      });
  };

  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">
                {intl.formatMessage({ id: "ASIDE.GL" })}
              </div>
              <h2 className="page-title">
                {intl.formatMessage({ id: "BOOKING.EDIT" })}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 page-body">
        <div className="card">
          {(isLoading || isLoadingUpdateBooking) && <ProgressBar />}
          <BookingForm
            isEditMode={true}
            data={{
              ...booking,
              date: moment(booking?.date).format("yyyy-MM-DD"),
              accountNo: booking?.account?.id,
            }}
            onCancel={onCancel}
            formSubmitted={onFormSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default BookingEdit;
