import { FieldProps, useField } from "formik";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";

interface CustomInputProps extends FieldProps {
  placeholder: string;
  label: string;
  required: boolean;
  className: string;
  labelClassName: string;
  delta: number;
  innerChild: React.ReactNode;
  options: any;
  onChange: (value: any) => void;
  showMonthYearPicker: boolean;
  minDate: Date;
  maxDate: Date;
}
const getClassName = (touched: any, error: any): string => {
  if (touched && error) {
    return " is-invalid";
  } else if (touched) {
    return " is-valid";
  } else {
    return "";
  }
};
const getBorderColor = (touched: any, error: any) => {
  if (touched) {
    return error ? "#d63939" : "#2fb344";
  } else {
    return "#dadfe5";
  }
};
const FormikDatepickerComponent: React.FC<CustomInputProps> = ({
  field,
  form,
  placeholder,
  label,
  required,
  className,
  labelClassName,
  onChange,
  delta,
  innerChild,
  showMonthYearPicker = true,
  minDate,
  maxDate,
  ...props
}) => {
  const { touched, errors, setTouched, setFieldValue, values } = form;
  const getDate = () => {
    if (delta && values[field.name]) {
      const date = new Date(values[field.name]);
      date.setDate(date.getDate() + delta);
      return date;
    }
    return values[field.name] ? new Date(values[field.name]) : null;
  };
  return (
    <div className={className ?? "row mb-3"}>
      <label
        className={` ${labelClassName ?? "col-3 col-form-label "} ${
          required ? " required" : ""
        }`}
      >
        {label}
      </label>
      <div
        className={`${getClassName(touched[field.name], errors[field.name])}`}
      >
        <DatePicker
          maxDate={maxDate}
          minDate={minDate}
          selected={getDate()}
          onBlur={() => setTouched({ ...touched, [field.name]: true })}
          className={`form-control ${getClassName(
            touched[field.name],
            errors[field.name]
          )}`}
          onChange={(date: any) => {
            setFieldValue(field.name, moment(date).format("yyyy-MM-DD"));
            if (onChange) {
              onChange(date);
            }
          }}
          showMonthYearPicker={showMonthYearPicker}
          dateFormat="dd-MM-yyyy"
        />
      </div>
      {touched[field.name] && errors[field.name] && (
        <div className="invalid-feedback">{errors[field.name]?.toString()}</div>
      )}
    </div>
  );
};

export default FormikDatepickerComponent;
