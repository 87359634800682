import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { toast } from "react-toastify";
import * as yup from "yup";
import { intlRequiredMessage } from "../../../_panel/helpers/validation";
import {
  useGetCompaniesQuery,
  useSelectAdministrationMutation,
} from "../../../app/apiService";

import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useDynamicValidationSchema } from "../../../_panel/helpers/useDynamicValidationSchema";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import FormikDatepickerComponent from "../../../_panel/partials/formik/FormikDatepickerComponent";
import FormikSelectComponent from "../../../_panel/partials/formik/FormikSelectComponent";
import { RootState } from "../../../app/RootReducer";
import {
  BookingYear,
  administrationSlice,
} from "../../../app/administrationSlice/administrationSlice";
import CompnayChooser from "./CompnayChooser";
import { AvailableBookingYears } from "./type";
const { actions } = administrationSlice;

const reportingYearSchema = (intl: IntlShape) =>
  yup.object().shape({
    companyId: yup
      .number()
      .label(intl.formatMessage({ id: "HOMEPAGE.COMPANY" }))
      .required(intlRequiredMessage(intl)),
    fromDate: yup
      .date()
      .label(intl.formatMessage({ id: "HOMEPAGE.FROM_DATE" }))
      .required(),
    toDate: yup
      .date()
      .label(intl.formatMessage({ id: "HOMEPAGE.TO_DATE" }))
      .required()
      .test(
        "is-greater-than-fromDate",
        intl.formatMessage({ id: "TO_DATE_WARNING" }),
        function (toDate) {
          const { fromDate } = this.parent;
          return toDate >= fromDate;
        }
      ),
  });
const getNextYear = (date: Date) => {
  const nextYear = new Date(date);
  nextYear.setFullYear(date.getFullYear() + 1);
  return nextYear;
};
const ReportingYear = () => {
  const [showComapnySelector, setShowComapnySelector] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<number>(0);
  const [availableBookingYears, setAvailableBookingYears] = useState<
    AvailableBookingYears[]
  >([]);
  const [selectAdministration, { isLoading }] =
    useSelectAdministrationMutation();
  type AppDispatch = ThunkDispatch<string, any, AnyAction>;
  const dispatch: AppDispatch = useDispatch();
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration.bookingYears,
    shallowEqual
  ) as BookingYear;
  const formRef: React.RefObject<any> = useRef();
  const { data: { records: companies = [], totalCount = 0 } = {} } =
    useGetCompaniesQuery({}) || {};
  const intl = useIntl();
  const companiesOptions = companies.map((company: any) => {
    return {
      value: company.id,
      label: company.name,
    };
  });
  const companySelected = (id: number) => {
    selectAdministration({
      companyId: formRef.current.values.companyId,
      fromDate: moment(formRef.current.values.fromDate).format("yyyy-MM-DD"),
      toDate: moment(formRef.current.values.toDate).format("yyyy-MM-DD"),
      copyFromId: id,
      force: true,
    })
      .unwrap()
      .then((data) => {
        if (data?.data) {
          dispatch(actions.bookingYearsSelected(data.data));
          toast.success(
            intl.formatMessage({
              id: "HOMEPAGE.BOOKING_YEARS_SUCCESSFULLY_SELECTED",
            })
          );
        } else {
          toast.error(
            intl.formatMessage({
              id: "HOMEPAGE.FAILED_TO_SELECT_BOOKING_YEARS",
            })
          );
        }
      })
      .catch((err: any) => {
        toast.error(
          JSON.stringify(err.data?.message) +
            intl.formatMessage({
              id: "HOMEPAGE.FAILED_TO_SELECT_BOOKING_YEARS",
            })
        );
      });
  };
  const validationSchema = useDynamicValidationSchema(reportingYearSchema);
  useEffect(() => {
    formRef?.current?.validateForm();
  }, [validationSchema]);
  return (
    <>
      <Formik
        initialValues={{
          companyId: selectedBookingYear?.companyId,
          fromDate: selectedBookingYear?.fromDate,
          toDate: selectedBookingYear?.toDate,
        }}
        validationSchema={validationSchema}
        innerRef={formRef}
        validateOnBlur={true}
        onSubmit={(values, { setSubmitting }) => {
          selectAdministration({
            companyId: values.companyId,
            fromDate: moment(values.fromDate).format("yyyy-MM-DD"),
            toDate: moment(values.toDate).format("yyyy-MM-DD"),
          })
            .unwrap()
            .then((data) => {
              if (data?.data) {
                dispatch(actions.bookingYearsSelected(data.data));
                toast.success(
                  intl.formatMessage({
                    id: "HOMEPAGE.BOOKING_YEARS_SUCCESSFULLY_SELECTED",
                  })
                );
              } else {
                toast.error(
                  intl.formatMessage({
                    id: "HOMEPAGE.FAILED_TO_SELECT_BOOKING_YEARS",
                  })
                );
              }
            })
            .catch((err: any) => {
              if (err.data?.code === 10001) {
                setAvailableBookingYears(err.data?.data);
                setSelectedCompany(values.companyId);
                setShowComapnySelector(true);
              } else {
                toast.error(
                  JSON.stringify(err.data?.message) +
                    intl.formatMessage({
                      id: "HOMEPAGE.FAILED_TO_SELECT_BOOKING_YEARS",
                    })
                );
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          isSubmitting,
          errors,
          touched,
          values,
          setTouched,
          setFieldValue,
        }) => (
          <Form className="card">
            {isLoading && <ProgressBar />}
            <div className="card-header">
              <h3 className="card-title">
                {intl.formatMessage({ id: "HOMEPAGE.REPORTING_YEAR" })}
              </h3>
            </div>
            <div className="card-body">
              <Field
                name="companyId"
                className="col-12 mb-3"
                labelClassName="form-label"
                component={FormikSelectComponent}
                row
                placeholder={intl.formatMessage({ id: "HOMEPAGE.COMPANY" })}
                label={intl.formatMessage({ id: "HOMEPAGE.COMPANY" })}
                required
                options={companiesOptions}
              />

              <div className="row">
                <Field
                  name="fromDate"
                  className="col-6 mb-3"
                  labelClassName="form-label"
                  onChange={(date: any) => {
                    setFieldValue(
                      "toDate",
                      moment(getNextYear(date)).format("yyyy-MM-DD")
                    );
                    setTimeout(() => {
                      setTouched({ ...touched, fromDate: true });
                      setTouched({ ...touched, toDate: true });
                    }, 150);
                  }}
                  component={FormikDatepickerComponent}
                  placeholder={intl.formatMessage(
                    { id: "PLACEHOLDER" },
                    {
                      label: intl
                        .formatMessage({
                          id: "HOMEPAGE.FROM_DATE",
                        })
                        .toLocaleLowerCase(),
                    }
                  )}
                  label={intl.formatMessage({ id: "HOMEPAGE.FROM_DATE" })}
                  required
                />
                <Field
                  name="toDate"
                  className="col-6 mb-3"
                  labelClassName="form-label"
                  component={FormikDatepickerComponent}
                  delta={-1}
                  placeholder={intl.formatMessage(
                    { id: "PLACEHOLDER" },
                    {
                      label: intl
                        .formatMessage({
                          id: "HOMEPAGE.TO_DATE",
                        })
                        .toLocaleLowerCase(),
                    }
                  )}
                  label={intl.formatMessage({ id: "HOMEPAGE.TO_DATE" })}
                  required
                />
              </div>
            </div>
            <div className="card-footer text-end">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                {intl.formatMessage({ id: "SUBMIT" })}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <CompnayChooser
        availableBookingYears={availableBookingYears}
        show={showComapnySelector}
        onHide={() => setShowComapnySelector(false)}
        onComapnySelected={companySelected}
        selectedCompany={selectedCompany}
        companiesOptions={companiesOptions}
      />
    </>
  );
};

export default ReportingYear;
