import React from 'react';
import {
  useGetReconciliationQuery,
  useLazyDownloadReconciliationQuery
} from "../../app/apiService";

interface Reconciliation {
  id: string;
  status: string;
  createdAt: string;
}

const ReconciliationCard: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [sort, setSort] = React.useState('createdAt:desc');

  const { data: reconciliationData, refetch: startReconciliation } = useGetReconciliationQuery({
    page,
    perPage,
    sort
  });

  const [downloadReconciliation] = useLazyDownloadReconciliationQuery();

  const handleStartReconciliation = async () => {
    try {
      await startReconciliation();
      console.log('Started reconciliation');
    } catch (error) {
      console.error('Failed to start reconciliation:', error);
    }
  };

  const handleDownload = async (id: string) => {
    try {
      const blob = await downloadReconciliation(id).unwrap();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `reconciliation-${id}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Failed to download:', error);
    }
  };

  return (
    <div>
      <button onClick={handleStartReconciliation}>
        Start New Reconciliation
      </button>

      {reconciliationData?.data?.length === 0 ? (
        <div>No reconciliations found</div>
      ) : reconciliationData?.data?.length === undefined ? (
        <div>Loading...</div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {reconciliationData?.data.map((reconciliation: Reconciliation) => (
              <tr key={reconciliation.id}>
                <td>{reconciliation.id}</td>
                <td>{reconciliation.status}</td>
                <td>{new Date(reconciliation.createdAt).toLocaleString()}</td>
                <td>
                  {reconciliation.status === 'completed' && (
                    <button onClick={() => handleDownload(reconciliation.id)}>
                      Download
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div>
        <button 
          disabled={page === 1} 
          onClick={() => setPage(p => p - 1)}
        >
          Previous
        </button>
        <span>Page {page}</span>
        <button 
          disabled={(reconciliationData?.data?.length ?? 0) < perPage} 
          onClick={() => setPage(p => p + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};


export default ReconciliationCard;