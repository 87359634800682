import React, { useState, useEffect } from "react";
import LanguageSelector from "./LanguageSelector";
import { useIntl } from "react-intl";
import UserService from "../../../app/oauth/UserService";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { useGetCompanyByIdQuery } from "../../../app/apiService";

const Header = ({}) => {
  const intl = useIntl();
  const logoutClicked = () => {
    UserService.doLogout();
  };
  const [companyName, setCompanyName] = useState("");
  const { bookingYears } = useSelector((state: RootState) => state.root.administration);
  const { data: companyData } = useGetCompanyByIdQuery(bookingYears?.companyId ?? 0, { skip: !bookingYears?.companyId });
  const currentBookingYear = bookingYears;

  useEffect(() => {
    if (companyData) {
      setCompanyName(companyData.name);
    }
  }, [companyData]);


  const formatDate = (date: Date | string | undefined): string => {
    if (!date) return '';
    return new Date(date).toLocaleDateString();
  };

  const subtractOneDay = (date: Date | string | undefined): string | undefined => {
    if (!date) return undefined;
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 1);
    return newDate.toISOString().split('T')[0];
  };

  return (
    <header className="navbar navbar-expand-md d-none d-lg-flex d-print-none">
      <div className="container-xl">
        <div className="page-pretitle fs-3 fw-bold">
          {companyName} - {formatDate(currentBookingYear?.fromDate)} to {formatDate(subtractOneDay(currentBookingYear?.toDate))}
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-menu"
          aria-controls="navbar-menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="navbar-nav flex-row order-md-last">
          <LanguageSelector />
          <div className="nav-item dropdown">
            <a
              href="#"
              className="nav-link d-flex lh-1 text-reset p-0 show"
              data-bs-toggle="dropdown"
              aria-label="Open user menu"
              aria-expanded="true"
            >
              <span
                className="avatar avatar-sm"
                style={{
                  backgroundImage: `url(${require("../../assets/static/no_image.png")})`,
                }}
              />
              <div className="d-none d-xl-block ps-2">
                <div>
                  {UserService.getGivenName()} {UserService.getFamilyName()}
                </div>
                <div className="mt-1 small text-secondary">
                  {UserService.getUsername()}
                </div>
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-menu-end dropdown-menu-arrow"
              data-bs-popper="static"
            >
              <button onClick={logoutClicked} className="dropdown-item">
                {intl.formatMessage({ id: "MENU.LOGOUT" })}
              </button>
            </div>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="navbar-menu">
          <div className="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center"></div>
        </div>
      </div>
    </header>
  );
};

export default Header;
