import React, { useRef } from "react";
import { FieldProps } from "formik";

interface CustomInputProps extends FieldProps {
  placeholder: string;
  label: string;
  required: boolean;
  type: string;
  className: string;
  labelClassName: string;
  noError: boolean;
}
const getClassName = (touched: any, error: any): string => {
  if (touched && error) {
    return "form-control is-invalid";
  } else if (touched) {
    return "form-control is-valid";
  } else {
    return "form-control";
  }
};
const FormikDateComponent: React.FC<CustomInputProps> = ({
  field,
  form: { touched, errors },
  placeholder,
  label,
  required,
  className,
  labelClassName,
  type,
  noError,
  ...props
}) => {
  const dateRef: React.RefObject<any> = useRef();
  return (
    <div className={className ?? "row mb-3"}>
      {label && (
        <label
          className={` ${labelClassName ?? "col-3 col-form-label "} ${
            required ? " required" : ""
          }`}
        >
          {label}
        </label>
      )}
      <div className="col">
        <input
          ref={dateRef}
          onClick={() => dateRef?.current?.showPicker()}
          type="date"
          {...field}
          {...props}
          value={field.value}
          className={`${getClassName(touched[field.name], errors[field.name])}`}
          placeholder={placeholder}
        />
        {!noError && touched[field.name] && errors[field.name] && (
          <div className="invalid-feedback">
            {errors[field.name]?.toString()}
          </div>
        )}
      </div>
    </div>
  );
};

export default FormikDateComponent;
